import { Circle } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { memo } from 'react';

import {
  AUTOSCALE_DEFAULT,
  AUTOSCALE_MAX,
  AUTOSCALE_MIN,
  AUTOSCALE_NAME,
} from '@/modules/workspace/helpers/konva';
import { useAutoScale } from '@/modules/workspace/hooks/useAutoScale';
import { theme } from '@modules/common/components/theme';
import { crossingState } from '@modules/connections/crossings/store';

const customAttr = {
  [AUTOSCALE_MAX]: 8,
  [AUTOSCALE_MIN]: 0.5,
  [AUTOSCALE_DEFAULT]: 1,
};

const CrossingBubbleComponent = ({ crossingId }) => {
  const crossing = useRecoilValue(crossingState(crossingId));
  useAutoScale();

  return (
    <Circle
      name={AUTOSCALE_NAME}
      {...customAttr}
      x={crossing.position.x / 10}
      y={crossing.position.y / 10}
      radius={6}
      fill={theme.palette.primary.main}
    />
  );
};

const CrossingBubble = memo(CrossingBubbleComponent);

export default CrossingBubble;
