import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleLengthSelector } from '../vehicleLengthSelector';

export const supportedVehiclesLengthSelector = selectorFamily<number, string[]>({
  key: 'vehicles/supported/length',
  get:
    (supportedVehicleIds: string[]) =>
    ({ get }) => {
      const lengths: number[] = supportedVehicleIds
        .map((id) => get(vehicleLengthSelector(id)))
        .filter((item): item is number => typeof item === 'number');

      if (lengths.length === 0) {
        return 0;
      }

      return Math.max(...lengths);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
