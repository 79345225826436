import { Connection } from '@modules/common/types/connections';
import {
  Connection as ConnectionPersisted,
  Connection as FloorPlanConnection,
} from '@modules/common/types/floorPlan';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { sortConnections } from '../../common/helpers';
import { findConnectionPositionsBetween2Shapes } from './connections';

/**
 * Prepares connections state to be loaded into the app.
 */
export const prepareToLoad = (
  connections: ConnectionPersisted[],
  shapes: DTShape[],
): Connection[] => {
  const shapeMap = new Map<string, DTShape>();
  shapes.forEach((item) => shapeMap.set(item.id, item));

  const newConnections: Connection[] = [];

  connections.forEach((connection) => {
    const { from, to } = connection;

    const [fromShapeId, fromSegment] = from.split('.');
    const [toShapeId, toSegment] = to.split('.');

    const fromShapeDT = shapeMap.get(fromShapeId);
    const toShapeDT = shapeMap.get(toShapeId);

    if (!fromShapeDT?.properties || !toShapeDT?.properties) return;

    const positions = findConnectionPositionsBetween2Shapes(
      fromShapeDT,
      toShapeDT,
      fromShapeDT.type === ShapeType.MANUAL ||
        fromShapeDT.type === ShapeType.MANUAL_EXIT ||
        fromShapeDT.type === ShapeType.MANUAL_ENTRY,
      fromSegment ? +fromSegment : undefined,
      toSegment ? +toSegment : undefined,
    );

    if (positions?.length) {
      positions.forEach((position) => {
        newConnections.push({
          ...connection,
          ...position,
        });
      });
    }
  });

  return newConnections;
};

/**
 * Prepares connections state to be saved
 */
export const prepareToSave = (connections: Connection[]): FloorPlanConnection[] => {
  const connectionsToSave = connections.map((item) => ({
    from: item.from,
    to: item.to,
    inChecked: item.inChecked,
    outChecked: item.outChecked,
    usePivots: item.usePivots,
    id: item.id,
  }));

  return sortConnections(connectionsToSave);
};
