import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { enabledVehicleIdsState } from './atom';
import { vehicleWidthSelector } from '../vehicleWidthSelector';

export const enabledVehiclesWidthSelector = selector<number>({
  key: 'vehicles/enabled/width',
  get: ({ get }) => {
    const widths: number[] = get(enabledVehicleIdsState)
      .map((id) => get(vehicleWidthSelector(id)))
      .filter((item): item is number => typeof item === 'number');

    if (widths.length === 0) {
      return 0;
    }

    return Math.max(...widths);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
