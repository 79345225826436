import { theme } from '@common/components/theme';
import { Tooltip, tooltipClasses } from '@mui/material';
import Fade from '@mui/material/Fade';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

type Props = Omit<TooltipProps, 'title'> & {
  disabled?: boolean;
  offset?: number;
  titleKey?: string;
  title?: ReactNode;
  forceState?: 'open' | 'close' | null;
};

export const StyledTooltip = styled(
  ({
    className,
    offset = 0,
    children,
    disabled,
    titleKey,
    title,
    forceState = null,
    ...props
  }: Props) => {
    const { t } = useTranslation();

    return disabled ? (
      children
    ) : (
      <Tooltip
        {...props}
        title={titleKey ? t(titleKey) : title}
        classes={{ popper: className }}
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: { appear: 50, enter: 300, exit: 0 } }}
        {...(forceState ? { open: forceState === 'open' } : {})}
        enterDelay={props.enterDelay !== undefined ? props.enterDelay : 1000}
      >
        {children}
      </Tooltip>
    );
  },
)((props) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.others.black,
    left: 'auto !important',
    right: '90% !important',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.others.black,
    borderColor: theme.palette.primary.light,
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.others.black,
    borderRadius: 0,
    fontWeight: 400,
    color: theme.palette.others.white,
    fontSize: 10,
    padding: '4px 8px',
  },
  [`& .${tooltipClasses.tooltip} span`]: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    top: props.offset,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '2px !important',
  },
}));
