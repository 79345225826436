import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { DTShapePersisted } from '@/modules/common/types/floorPlan';
import { isProcessAreaTwoEpPersisted } from '@/modules/common/types/guards';
import { ShapeGroup, SingleTypeGroup } from '@/modules/common/types/shapeGroup';
import { areaTypeToFPAreaType } from './utils';
import { TemplateType } from '@/modules/common/types/templating';

export const createAllLoadPositionGroupGenerationSettings = (
  shapeGroups: ShapeGroup[],
  shapes: DTShapePersisted[],
  loadPositionGroupGenerationSettings,
) => {
  const usedIds: string[] = [];
  
  shapeGroups
    .filter(
      (shapeGroup) =>
        shapeGroup.type !== TemplateType.LASSIE && shapeGroup.type !== TemplateType.SHUTTLE_RACK,
    )
    .filter((shapeGroup) => supportsLoadCarriers((shapeGroup as SingleTypeGroup).type))
    .forEach((shapeGroup) => {
      const children = shapes.filter((shape) => shapeGroup.children.includes(shape.id));
      AddGroupLoadPositionGroupGenerationSettings(
        loadPositionGroupGenerationSettings,
        shapeGroup,
        children,
      );
      usedIds.push(...children.map((x) => x.id));
    });

  shapes.forEach((shape) => {
    if (supportsLoadCarriers(shape.type) && !usedIds.includes(shape.id)) {
      if (isProcessAreaTwoEpPersisted(shape)) {
        AddProcessLoadPositionGroupGenerationSettings(loadPositionGroupGenerationSettings, shape);
        usedIds.push(shape.id);
      } else {
        AddAreaLoadPositionGroupGenerationSettings(loadPositionGroupGenerationSettings, shape);
        usedIds.push(shape.id);
      }
    }
  });
};

const AddGroupLoadPositionGroupGenerationSettings = (
  loadPositionGroupGenerationSettings,
  shapeGroup,
  children,
) => {
  /* Backend needs manual type to be stationary instead... */
  const areaType = shapeGroup.type === 'manual' ? 'stationary' : shapeGroup.type;

  loadPositionGroupGenerationSettings.push({
    lpGenSettingsId: null,
    areaType: areaType.toUpperCase(),
    discType: 'LOAD_POSITION_GROUP_BY_ADD',
    name: shapeGroup.name,
    lpgGenerationByRegex: [],
    lpgGenerationFromArea: children.map((shape) => ({
      areaName: shape.id,
    })),
  });
};

const AddAreaLoadPositionGroupGenerationSettings = (loadPositionGroupGenerationSettings, shape) => {
  loadPositionGroupGenerationSettings.push({
    lpGenSettingsId: null,
    areaType: areaTypeToFPAreaType(shape.type),
    discType: 'LOAD_POSITION_GROUP_BY_ADD',
    name: shape.name,
    lpgGenerationByRegex: [],
    lpgGenerationFromArea: [
      {
        areaName: shape.id,
      },
    ],
  });
};

const AddProcessLoadPositionGroupGenerationSettings = (loadPositionGroupGenerationSettings, shape) => {
  loadPositionGroupGenerationSettings.push({
    lpGenSettingsId: null,
    areaType: areaTypeToFPAreaType(shape.type),
    groupType: "TWO_STATION_PROCESSING",
    discType: 'LOAD_POSITION_GROUP_BY_ADD',
    name: `${shape.name}.TwoStationProcessing.`,
    lpgGenerationByRegex: [
      {
        regex: `${shape.name}-intake_[0-9]*-[0-9]*`,
      },
      {
        regex: `${shape.name}-delivery_[0-9]*-[0-9]*`,
      },
    ],
    lpgGenerationFromArea: [],
  });
};
