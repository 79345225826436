import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { useFileTypeValidator } from '@/modules/common/hooks/useFileValidator';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { FLOORPLAN_ASSET_FILE_CONTAINER } from '@/modules/vehicles';
import { useFileApi } from '@/modules/api/hooks';
import { buildTransportOrdersDownloadPath } from '../../helpers/mapping';
import { useSimulationDraftCallbacks } from '../../hooks';

export function RunSimulationWithTransportOrders() {
  const { t } = useTranslation('interface');
  const { addFile } = useFileApi();
  const { prepareAndRun } = useSimulationDraftCallbacks();
  const { validateFileType } = useFileTypeValidator();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbarStore();

  const uploadTransportOrdersFileToFileStorage = useCallback(
    async (file: Blob, fileName: string, extension: 'csv') => {
      const uploadToFileApiResponse = await addFile(
        fileName,
        file,
        extension,
        FLOORPLAN_ASSET_FILE_CONTAINER,
      );

      if (uploadToFileApiResponse.status !== 200) {
        console.log('Failed to upload transport orders to file api');
        return { success: false };
      }

      return { success: true };
    },
    [addFile],
  );

  const handleFileSelection = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const transportOrdersFile = event.target.files?.[0];

      if (!transportOrdersFile || !validateFileType(transportOrdersFile, ['csv'])) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const content = await transportOrdersFile.text();

        try {
          const fileReferenceId = `transportOrders_${uuid()}`;
          const transportOrdersDownloadPath = buildTransportOrdersDownloadPath(
            FLOORPLAN_ASSET_FILE_CONTAINER,
            fileReferenceId,
          );

          const fileUploadResult = await uploadTransportOrdersFileToFileStorage(
            new Blob([content], { type: 'text/csv' }),
            fileReferenceId,
            'csv',
          );

          if (!fileUploadResult.success) {
            showSnackbar(t('errors:files.upload_failed'));
            return;
          }

          await prepareAndRun({ transportOrdersDownloadPath });
        } catch (error) {
          console.error('Error running simulation with uploaded transport orders', error);
        }
      };

      reader.readAsText(transportOrdersFile);
    },
    [validateFileType, showSnackbar, uploadTransportOrdersFileToFileStorage, prepareAndRun, t],
  );

  const runSimulationWithTransportOrders = () => {
    fileInputRef.current?.click();
  };

  return (
    <Stack spacing={1} mb={2}>
      <PropertiesTitle
        fontSize={14}
        fontWeight={400}
        value={t('simulation.experimental_settings.upload_transport_orders.title')}
      />
      <Typography variant='body2' sx={{ fontSize: '10px' }}>
        {t('simulation.experimental_settings.upload_transport_orders.helper_text')}
      </Typography>
      <Button onClick={runSimulationWithTransportOrders}>
        {t('simulation.experimental_settings.upload_transport_orders.button')}
      </Button>
      <input
        type='file'
        accept='.csv'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileSelection}
        hidden
      />
    </Stack>
  );
}
