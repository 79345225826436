import { TemplateType } from '@/modules/common/types/templating';
import { ShapeType } from '@modules/common/types/shapes';
import { Position } from '@/helpers/types';

type ShapeIds = string[];

export enum GroupComposition {
  SINGLE_TYPE = 'single-type',
  MIXED = 'mixed',
}

export type SingleTypeGroup = {
  id: string;
  name: string;
  composition: GroupComposition.SINGLE_TYPE;
  type: ShapeType;
  children: ShapeIds;
  interactivityMode: ShapeGroupInteractivityMode;
};

type MixedGroupBase<T extends TemplateType> = {
  id: string;
  name: string;
  composition: GroupComposition.MIXED;
  type: T;
  children: ShapeIds;
  interactivityMode: ShapeGroupInteractivityMode;
};

export type LassieGroup = MixedGroupBase<TemplateType.LASSIE> & {
  generationParams: LassieGenerationParams;
};

export type ShuttleRackGroup = MixedGroupBase<TemplateType.SHUTTLE_RACK> & {
  generationParams: ShuttleRackGenerationParams;
};

export type LassieGenerationParams = {
  name: string;
  x: number;
  y: number;
  angle: number;
  numAisles: number;
  numBays: number;
  numShelves: number;
};

export type ShuttleRackGenerationParams = {
  name: string;
  loadTypeRef: string;
  vehicleTypeRef: string;
  centerXinMm: number;
  centerYinMm: number;
  angleInDeg: number;
  numColumns: number;
  numLevels: number;
  numDeep: number;
  entrySide: ShuttleRackEntrySide;
};

export enum ShuttleRackEntrySide {
  SINGLE = 'SINGLE',
  BOTH = 'BOTH',
}

export type TemplateGenParams = {
  [TemplateType.LASSIE]: LassieGenerationParams;
  [TemplateType.SHUTTLE_RACK]: ShuttleRackGenerationParams;
};

export type TemplateGenParamsConstructor = {
  [TemplateType.LASSIE]: {
    mousePos: Position;
  };
  [TemplateType.SHUTTLE_RACK]: {
    mousePos: Position;
    loadCarrierId: string;
    vehicleTypeId: string;
  };
};

export type TemplateGroup = LassieGroup | ShuttleRackGroup;

export type ShapeGroup = SingleTypeGroup | TemplateGroup;

export type legacyFlowGroup = {
  id: string;
  name: string;
  type: ShapeType;
  children: ShapeIds;
};

export enum ShapeGroupInteractivityMode {
  LOCKED_SELECTABLE = 'lockedSelectable',
  UNRESTRICTED = 'unrestricted',
}

export const groupIsSingleTypeGroup = (group: ShapeGroup): group is SingleTypeGroup =>
  group.composition === 'single-type';
