import { FlowStopType, LayoutFlow } from '@/modules/flows/types';
import { capitalizeFirstLetter } from '@helpers/string';
import { isPosition } from '@modules/common/helpers/shapes';
import { ShapeType } from '@modules/common/types/shapes';

/**
 * Migrate from flows without ShapeGroup to flows with ShapeGroup
 * FROM flow.intakeFlowStop: string
 * TO   flow.intakeFlowStop: { id: string, type: FlowStopType }
 * @param oldFlows
 * @returns
 */
export function reconcileFlowIncompatibilities(oldFlows: any): LayoutFlow[] {
  if (!oldFlows || oldFlows.length === 0) return [];
  const newFlows: LayoutFlow[] = [];

  for (let i = 0; i < oldFlows.length; ++i) {
    const newFlow: LayoutFlow = { ...oldFlows[i] };
    if (!oldFlows[i].intakeFlowStop.id) {
      const intakeId = oldFlows[i].intakeFlowStop as unknown as string;
      newFlow.intakeFlowStop = {
        id: intakeId,
        type: FlowStopType.AREA,
      };
    }
    if (!oldFlows[i].deliveryFlowStop.id) {
      const deliveryId = oldFlows[i].deliveryFlowStop as unknown as string;
      newFlow.deliveryFlowStop = {
        id: deliveryId,
        type: FlowStopType.AREA,
      };
    }
    newFlows.push(newFlow);
  }
  return newFlows;
}

export function setShapesDefaultNaming(shapes) {
  const indexes: Record<string, number> = {};

  Object.values(ShapeType).forEach((type) => {
    indexes[type] = 0;
  });

  shapes.forEach((shape) => {
    const { name, type } = shape;
    const formattedType = formatShapeType(type);

    const splitName = name.split(' ');
    const value = parseInt(splitName.at(-1)); /* value of index */

    if (value) {
      if (indexes.hasOwnProperty(formattedType) && value > indexes[formattedType]) {
        indexes[formattedType] = value;
      }
    }
  });

  Object.entries(indexes).forEach(([type, value]) => {
    sessionStorage.setItem(type, (value + 1).toString());
  });
}

export function addCopy(shape) {
  const type = formatShapeType(shape.type);
  const index = parseInt(sessionStorage.getItem(type) ?? '1');
  sessionStorage.setItem(type, (index + 1).toString());
  return `${capitalizeFirstLetter(type)} ${index}`;
}

export function formatShapeType(type) {
  if (isPosition(type) || type === ShapeType.HIGHWAY_ANGLED || type === ShapeType.PROCESS_TWO_EP)
    return type.split('_')[0];

  return type;
}
