import { SvgIcon } from '@mui/material';

export default function ContactIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 16 14'
      sx={{
        transform: 'scale(0.6)',
      }}
    >
      <path
        d='M6.51919 9.83663H11.4808V9.7125C11.4808 9.31638 11.2467 8.988 10.7784 8.72738C10.3101 8.46688 9.71725 8.33663 9 8.33663C8.28275 8.33663 7.68994 8.46688 7.22156 8.72738C6.75331 8.988 6.51919 9.31638 6.51919 9.7125V9.83663ZM9 7.125C9.31638 7.125 9.58294 7.01656 9.79969 6.79969C10.0166 6.58294 10.125 6.31638 10.125 6C10.125 5.68363 10.0166 5.41706 9.79969 5.20031C9.58294 4.98344 9.31638 4.875 9 4.875C8.68363 4.875 8.41706 4.98344 8.20031 5.20031C7.98344 5.41706 7.875 5.68363 7.875 6C7.875 6.31638 7.98344 6.58294 8.20031 6.79969C8.41706 7.01656 8.68363 7.125 9 7.125ZM2.25 15.0578V3.46162C2.25 3.11637 2.36563 2.82812 2.59688 2.59688C2.82812 2.36563 3.11637 2.25 3.46162 2.25H14.5384C14.8836 2.25 15.1719 2.36563 15.4031 2.59688C15.6344 2.82812 15.75 3.11637 15.75 3.46162V11.5384C15.75 11.8836 15.6344 12.1719 15.4031 12.4031C15.1719 12.6344 14.8836 12.75 14.5384 12.75H4.55775L2.25 15.0578ZM4.2375 12H14.5384C14.6539 12 14.7597 11.9519 14.8558 11.8558C14.9519 11.7597 15 11.6539 15 11.5384V3.46162C15 3.34613 14.9519 3.24031 14.8558 3.14419C14.7597 3.04806 14.6539 3 14.5384 3H3.46162C3.34613 3 3.24031 3.04806 3.14419 3.14419C3.04806 3.24031 3 3.34613 3 3.46162V13.2332L4.2375 12Z'
        fill='#222222'
      />
    </SvgIcon>
  );
}
