export const TEMPLATE_GENERATION_PREREQUISITES = {
  VEHICLES: {
    LASSIE: ['LPV_none', 'LTV_none'],
  },
  LOAD_CARRIERS: {
    LASSIE: ['TOTE_ID'],
  },
} as const;

export const SHUTTLE_RACK = {
  MIN: {
    NUM_COLUMNS: 1,
    NUM_LEVELS: 1,
    NUM_DEEP: 2,
  },
};
