import { selectorFamily } from 'recoil';

import { supportsLoadCarriers, supportsVehicleTypes } from '@modules/common/helpers/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { allAreasSelector } from '@/store/recoil/shapes/area';
import { vehicleDetailsState } from './vehicleDetailsState';
import { ShapeType } from '@/modules/common/types/shapes';

export const vehicleCapacitySelector = selectorFamily<number, string>({
  key: 'vehicle/byId/capacity',
  get:
    (vehicleId: string) =>
    ({ get }) => {
      const vs = get(vehicleDetailsState(vehicleId));
      if (!vs) return 0;

      const areas = get(allAreasSelector);
      const vehicleAreas = areas.filter(
        (area) =>
          supportsVehicleTypes(area.type) &&
          !supportsLoadCarriers(area.type) &&
          area.parameters.supportedVehicleIds.includes(vs.id) &&
          (area.type === ShapeType.CHARGING || area.type === ShapeType.PARKING),
      );

      if (vehicleAreas.length === 0) return 0;

      return vehicleAreas
        .map((area) => (area.gates ? area.gates.length : 0))
        .map((a) => a)
        .reduce((a, b) => a + b);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
