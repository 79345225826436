import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Vector3 } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';

import { useArtefacts } from '@/modules/artefacts';
import {
  RACK_DISTRIBUTION_OVERRIDE,
  RACK_MIN_GAP_OVERRIDE,
} from '@/modules/common/constants/storage';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { SelectInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { StorageType as StorageTypeType } from '@modules/common/types/storage';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { areaStorageType, areasAlignment, areasDistribution, areasGap } from '../store/area';
import { useOnShapeAngleChange } from '@/components/PropertiesPanel/hooks/useOnShapeAngleChange';
import { AreaDirection } from '@/modules/common/types/shapes';
import { useArea } from '../hooks/useArea';
import {
  shapesAngleState,
  shapesHeightState,
  shapesPositionState,
  shapesWidthState,
} from '@/components/PropertiesPanel/store/dimension';
import { useConnections } from '@/modules/connections';

const StorageTypeComponent = () => {
  const { saveFloorPlan } = useFloorPlanState();
  const { t } = useTranslation('interface');
  const mode = useRecoilValue(modeSelector);
  const [storageType, setStorageType] = useRecoilState(areaStorageType);
  const setAreasGap = useSetRecoilState(areasGap);
  const setAreasDistributionStrategy = useSetRecoilState(areasDistribution);
  const { updateDebounced: updateArtefacts, showLoader: showArtefactsLoader } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);
  const { onShapeAngleChange } = useOnShapeAngleChange();
  const { direction, setDirection } = useArea();
  const angle = useRecoilValue(shapesAngleState);
  const { updateConnections } = useConnections();

  const optionLabels = useMemo(
    () =>
      t('properties.area.storage_types', {
        returnObjects: true,
      }),
    [t],
  );

  const onChange = useRecoilCallback(
    ({ snapshot, set }) =>
      async (value: StorageTypeType) => {
        showArtefactsLoader(ids);

        if (value === StorageTypeType.RACK || value === StorageTypeType.TWOSIDEDRACK) {
          setAreasGap(RACK_MIN_GAP_OVERRIDE);
          setAreasDistributionStrategy(RACK_DISTRIBUTION_OVERRIDE);

          // Adjust the size so that the side along the shapes direction is smaller
          const currentWidth = await snapshot.getPromise(shapesWidthState);
          const currentHeight = await snapshot.getPromise(shapesHeightState);
          const currentPosition = await snapshot.getPromise(shapesPositionState);

          if (currentWidth < currentHeight && direction === AreaDirection.UP) {
            set(shapesHeightState, currentWidth - 1);
          } else if (currentWidth < currentHeight && direction === AreaDirection.DOWN) {
            set(shapesHeightState, currentWidth - 1);

            const distance = currentHeight - currentWidth;
            const deltaX = Math.cos((-angle + 90) * DEG2RAD) * distance;
            const deltaY = Math.sin((-angle + 90) * DEG2RAD) * distance;
            // @ts-expect-error strictNullChecks. Pls fix me
            set(shapesPositionState, currentPosition.clone().add(new Vector3(deltaX, deltaY)));
          } else if (currentWidth > currentHeight && direction === AreaDirection.LEFT) {
            set(shapesWidthState, currentHeight - 1);
          } else if (currentWidth > currentHeight && direction === AreaDirection.RIGHT) {
            set(shapesWidthState, currentHeight - 1);

            const distance = currentHeight - currentWidth;
            const deltaX = Math.cos((-angle + 180) * DEG2RAD) * distance;
            const deltaY = Math.sin((-angle + 180) * DEG2RAD) * distance;
            // @ts-expect-error strictNullChecks. Pls fix me
            set(shapesPositionState, currentPosition.clone().add(new Vector3(deltaX, deltaY)));
          }

          // Change orientation to match direction of shape
          if (direction === AreaDirection.UP) {
            setDirection(AreaDirection.DOWN, ids);
            await onShapeAngleChange(angle + 180);
          } else if (direction === AreaDirection.LEFT) {
            setDirection(AreaDirection.RIGHT, ids);
            await onShapeAngleChange(angle + 180);
          }

          await updateConnections(ids);
        }

        setStorageType(value);
        await saveFloorPlan();
        updateArtefacts(ids);
      },
    [
      showArtefactsLoader,
      updateConnections,
      ids,
      setStorageType,
      saveFloorPlan,
      updateArtefacts,
      setAreasGap,
      setAreasDistributionStrategy,
      direction,
      angle,
      setDirection,
      onShapeAngleChange,
    ],
  );

  const useGetStorageTypeOptions = useMemo(
    () => [
      {
        label: optionLabels[StorageTypeType.SINGLE],
        value: StorageTypeType.SINGLE,
      },
      {
        label: optionLabels[StorageTypeType.BLOCKSTACKING],
        value: StorageTypeType.BLOCKSTACKING,
      },
      {
        label: optionLabels[StorageTypeType.RACK],
        value: StorageTypeType.RACK,
      },
      {
        label: optionLabels[StorageTypeType.TWOSIDEDRACK],
        value: StorageTypeType.TWOSIDEDRACK,
      },
      {
        label: optionLabels[StorageTypeType.SIDELOADING],
        value: StorageTypeType.SIDELOADING,
      },
    ],
    [optionLabels],
  );

  return (
    <SelectInput
      options={useGetStorageTypeOptions}
      onChange={onChange}
      value={storageType}
      disabled={mode !== WorkspaceMode.EDITABLE}
    />
  );
};

const StorageType = memo(StorageTypeComponent);
export default StorageType;
