import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';

import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import {
  doPolygonsIntersect,
  lineToPolygon,
  makeOffsetPoly,
} from '@/modules/common/helpers/polygon';
import { Polygon } from '@/modules/connections/common/types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { Position } from '@helpers/types';
import { WideLineSegment } from '@/modules/common/types/general';
import { isArea, isPosition } from '@modules/common/helpers/shapes';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { isAngledHighwayShape, isHighwayShape, isPointsShape, isProcessAreaTwoEp, isWallShape } from '@modules/common/types/guards';
import {
  connectionPointBetweenPolygons,
  findClosestSegmentToPoint
} from '@modules/connections/common/helpers';
import {
  angleBetweenVectors,
  areLinesParallel,
  pointAlongVector,
} from '@modules/workspace/helpers/shape';
import { getSegments } from '@/modules/angledHighways/helpers';
import { ConnectionDir } from '@/modules/common/types/connections';

const POLYGON_OUTSET_SIZE = -50; // minus to make object bigger

export type ConnectionPosition = {
  position: Position;
  rot: number;
  from: string;
  to: string;
};

export type ConnectionPoint = {
  position: Position;
  rot: number;
};

export const findConnectionsPositions = (
  shape: DTShape,
  shapes: DTShape[],
): ConnectionPosition[] => {
  const connections: ConnectionPosition[] = [];

  let filteredShapes: DTShape[] = [];

  if (shape.type === ShapeType.MANUAL) {
    filteredShapes = shapes.filter(
      (otherShape) =>
        otherShape.type === ShapeType.MANUAL_EXIT || otherShape.type === ShapeType.MANUAL_ENTRY,
    );
  } else if (shape.type === ShapeType.MANUAL_EXIT || shape.type === ShapeType.MANUAL_ENTRY) {
    filteredShapes = shapes.filter(
      (otherShape) =>
        otherShape.type === ShapeType.MANUAL ||
        otherShape.type === ShapeType.HIGHWAY ||
        otherShape.type === ShapeType.HIGHWAY_ANGLED,
    );
  } else {
    filteredShapes = shapes.filter((otherShape) => shape.id !== otherShape.id);
  }

  filteredShapes.forEach((otherShape) => {
    const connectionPositions = findConnectionPositionsBetween2Shapes(
      shape,
      otherShape,
      shape.type === ShapeType.MANUAL ||
        shape.type === ShapeType.MANUAL_EXIT ||
        shape.type === ShapeType.MANUAL_ENTRY,
    );

    if (connectionPositions?.length) {
      connections.push(...connectionPositions);
    }
  });

  return connections;
};

export const findConnectionPositionsBetween2Shapes = (
  shape1: DTShape,
  shape2: DTShape,
  connectAreas?: boolean,
  segment1?: number,
  segment2?: number,
): ConnectionPosition[] | null => {
  if (shape1.id === shape2.id) return null;

  // area doesn't connect to area or position
  if (
    !connectAreas &&
    (isArea(shape1.type) || isPosition(shape1.type)) &&
    (isArea(shape2.type) || isPosition(shape2.type))
  )
    return null;

  if (isWallShape(shape1) || isWallShape(shape2)) return null;

  let shapeA = isAngledHighwayShape(shape1)
    ? getSegments(shape1)
    : isProcessAreaTwoEp(shape1)
    ? getProcess2EPEnds(shape1)
    : [boundingBoxToLine(shape1.properties)];

  if (Number.isFinite(segment1) && segment1 !== undefined && segment1 < shapeA.length) {
    shapeA = [shapeA[segment1]];
  }

  let shapeB = isAngledHighwayShape(shape2)
    ? getSegments(shape2)
    : isProcessAreaTwoEp(shape2)
    ? getProcess2EPEnds(shape2)
    : [boundingBoxToLine(shape2.properties)];

  if (Number.isFinite(segment2) && segment2 !== undefined && segment2 < shapeB.length) {
    shapeB = [shapeB[segment2]];
  }

  const connectionPositions: ConnectionPosition[] = [];

  for (let indexA = 0; indexA < shapeA.length; indexA++) {
    const subShapeA = shapeA[indexA];

    for (let indexB = 0; indexB < shapeB.length; indexB++) {
      const subShapeB = shapeB[indexB];

      // highways in different directions doesn't connect to each other, see crossings
      if (
        (isHighwayShape(shape1) || isAngledHighwayShape(shape1)) &&
        (isHighwayShape(shape2) || isAngledHighwayShape(shape2)) &&
        !areLinesParallel(subShapeA.points, subShapeB.points)
      )
        continue;

      const connection = getConnectionPoint(subShapeA, subShapeB);
      if (connection) {
        const con: ConnectionPosition = {
          ...connection,
          from:
            shape1.id +
            (shape1.type === ShapeType.HIGHWAY_ANGLED || shape1.type === ShapeType.PROCESS_TWO_EP
              ? `.${Number.isFinite(segment1) ? segment1 : indexA}`
              : ''),
          to:
            shape2.id +
            (shape2.type === ShapeType.HIGHWAY_ANGLED || shape2.type === ShapeType.PROCESS_TWO_EP
              ? `.${Number.isFinite(segment2) ? segment2 : indexB}`
              : ''),
        };
        connectionPositions.push(con);
      }
    }
  }

  if (connectionPositions.length > 0) return connectionPositions;

  return null;
};

const getConnectionPoint = (
  lineA: WideLineSegment,
  lineB: WideLineSegment,
): ConnectionPoint | null => {
  const polygonA = lineToPolygon(lineA);
  const polygonB = lineToPolygon(lineB);
  const polygonAOffset = makeOffsetPoly(polygonA, POLYGON_OUTSET_SIZE);
  const polygonBOffset = makeOffsetPoly(polygonB, POLYGON_OUTSET_SIZE);

  if (!doPolygonsIntersect(polygonAOffset, polygonBOffset)) return null;
  const position = connectionPointBetweenPolygons(polygonAOffset, polygonBOffset);
  if (!position) {
    return null;
  }

  return {
    position,
    rot: connectionRotation(polygonAOffset, position),
  };
};

const connectionRotation = (objectA: Polygon, point: Vector2): number => {
  const segmentA = findClosestSegmentToPoint(objectA, point);
  const vectorA = segmentA.end.clone().sub(segmentA.start).normalize().multiplyScalar(-1);
  return Math.round(angleBetweenVectors(vectorA, new Vector2(1, 0)) * RAD2DEG);
};

// might be interesting for distant connections as well
export const getProcess2EPEnds = (shape: ProcessTwoEPShape): [WideLineSegment, WideLineSegment] => {
  const { controlPoints } = shape.properties;
  const endPointDepth = 3000; // TODO: get dynamic

  return [
    {
      points: {
        start: controlPoints[0].position,
        end: pointAlongVector(controlPoints[0].position, controlPoints[1].position, endPointDepth),
      },
      width: shape.parameters.width,
    },
    {
      points: {
        start: controlPoints[controlPoints.length - 1].position,
        end: pointAlongVector(
          controlPoints[controlPoints.length - 1].position,
          controlPoints[controlPoints.length - 2].position,
          endPointDepth,
        ),
      },
      width: shape.parameters.width,
    },
  ];
};

export function getDefaultInOutChecked(fromShape: ShapeType, toShape: ShapeType): ConnectionDir {
  if (fromShape === ShapeType.MANUAL && toShape === ShapeType.MANUAL_EXIT) {
    return { inChecked: true, outChecked: false };
  }
  if (fromShape === ShapeType.MANUAL && toShape === ShapeType.MANUAL_ENTRY) {
    return { inChecked: false, outChecked: true };
  }

  if (fromShape === ShapeType.MANUAL_ENTRY && toShape === ShapeType.MANUAL) {
    return { inChecked: true, outChecked: false };
  }

  if (fromShape === ShapeType.MANUAL_EXIT && toShape === ShapeType.MANUAL) {
    return { inChecked: false, outChecked: true };
  }

  if (
    (fromShape === ShapeType.HIGHWAY || fromShape === ShapeType.HIGHWAY_ANGLED) &&
    toShape === ShapeType.MANUAL_EXIT
  ) {
    return { inChecked: false, outChecked: true };
  }

  if (
    (fromShape === ShapeType.HIGHWAY || fromShape === ShapeType.HIGHWAY_ANGLED) &&
    toShape === ShapeType.MANUAL_ENTRY
  ) {
    return { inChecked: true, outChecked: false };
  }

  if (fromShape === ShapeType.MANUAL_EXIT || toShape === ShapeType.MANUAL_EXIT) {
    return { inChecked: true, outChecked: false };
  }

  if (fromShape === ShapeType.MANUAL_ENTRY || toShape === ShapeType.MANUAL_ENTRY) {
    return { inChecked: false, outChecked: true };
  }

  return { inChecked: true, outChecked: true };
}
