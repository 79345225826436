import { theme } from '@/modules/common/components/theme';

export const getSeverityStyles = (severity: string) => {
  const normalize = severity.toLowerCase();
  switch (normalize) {
    case 'error':
      return {
        backgroundColor: theme.palette.notifications.errorBackground,
        mb: 1,
        borderLeft: `6px solid ${theme.palette.notifications.errorBorder}`,
        '&:hover': {
          backgroundColor: theme.palette.notifications.errorHover,
        },
      };
    case 'fatal':
      return {
        backgroundColor: theme.palette.notifications.fatalBackground,
        mb: 1,
        borderLeft: `6px solid ${theme.palette.notifications.fatalBorder}`,
        '&:hover': {
          backgroundColor: theme.palette.notifications.fatalHover,
        },
      };
    case 'warning':
      return {
        backgroundColor: theme.palette.notifications.warningBackground,
        borderLeft: `6px solid ${theme.palette.notifications.warningBorder}`,
        mb: 1,
        '&:hover': {
          borderLeft: `8px solid ${theme.palette.notifications.warningBorder}`,
        },
      };
    case 'info':
      return {
        backgroundColor: theme.palette.notifications.infoBackground,
        borderLeft: `6px solid ${theme.palette.notifications.infoBorder}`,
        mb: 1,
        '&:hover': {
          borderLeft: `8px solid ${theme.palette.notifications.infoBorder}`,
        },
      };
    case 'success':
      return {
        backgroundColor: theme.palette.notifications.successBackground,
        borderLeft: `6px solid ${theme.palette.notifications.successBorder}`,
        mb: 1,
        '&:hover': {
          borderLeft: `8px solid ${theme.palette.notifications.successBorder}`,
        },
      };
    default:
      return {};
  }
};
