import { DefaultValue, selector } from 'recoil';

import { unique } from '@modules/common/helpers/array';
import { Crossing } from '@modules/common/types/connections';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { crossingIdsState, crossingState } from './atom';

export const allCrossingSelector = selector({
  key: 'connections/crossings/all',
  get: ({ get }) => get(crossingIdsState).map((crossingId) => get(crossingState(crossingId))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const removeCrossingsSelector = selector({
  key: 'connections/crossings/remove',
  get: () => null,
  set: ({ set, reset }, ids: string[]) => {
    ids.forEach((id) => {
      reset(crossingState(id));
    });

    set(crossingIdsState, (state) => state.filter((item) => !ids.includes(item)));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

type UpdateCrossingsSelectorValue = {
  crossings: Crossing[];
  crossingIdsToRemove?: Set<string>;
};

export const updateCrossingsSelector = selector<UpdateCrossingsSelectorValue | null>({
  key: 'connections/crossings/update',
  get: () => null,
  set: ({ set, reset }, value: UpdateCrossingsSelectorValue) => {
    value.crossings.forEach((crossing) => set(crossingState(crossing.id), crossing));

    if (value.crossingIdsToRemove) {
      value.crossingIdsToRemove.forEach((item) => reset(crossingState(item)));
    }

    set(crossingIdsState, (currentIds) => {
      const newState =
        currentIds instanceof DefaultValue
          ? unique(value.crossings.map((item) => item.id))
          : unique([...currentIds, ...value.crossings.map((item) => item.id)]);

      return newState.filter((item) => !value.crossingIdsToRemove?.has(item))
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const saveLoadCrossingSelector = selector({
  key: 'saveLoadCrossing',
  get: ({ get }) => get(crossingIdsState).map((item) => get(crossingState(item))),
  set: ({ set }, crossings: Crossing[]) => {
    crossings.forEach((item) => set(crossingState(item.id), item));
    set(
      crossingIdsState,
      crossings.map((item) => item.id),
    );
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
