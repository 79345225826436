import { AreaError } from '@/modules/common/types/shapes';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import { areaDirectionToAngle } from '@/store/recoil/workspace';
import shapeState, { AreaShape } from '@recoil/shape';
import { Group, Image } from 'react-konva';
import { useRecoilValue } from 'recoil';
import useImage from 'use-image';
import { areaIconAutoScaleAttr } from './consts';
import { useError } from './useError';
import { enabledVehiclesDetailsSelector } from '@/modules/vehicles';

export type Props = {
  id: string;
};

export const Icons = ({ id }: Props) => {
  const [forkliftImage] = useImage('/forklift.svg');
  const [tuggerImage] = useImage('/tugger.svg');
  const shape = useRecoilValue(shapeState(id)) as AreaShape;
  const enabledVehicles = useRecoilValue(enabledVehiclesDetailsSelector);
  const currentError = useError(id);

  let image = forkliftImage;
  const selectedVehicle = enabledVehicles.find((vehicle) =>
    shape.parameters.supportedVehicleIds.includes(vehicle.id),
  );

  if (selectedVehicle && selectedVehicle.loadingMethod === 'Tugging') {
    image = tuggerImage;
  }

  if (!shape || !image || (currentError && currentError !== AreaError.PartialGenerationFailed)) {
    return null;
  }

  return (
    <Group
      x={shape.properties.x * SHAPE_TO_CANVAS_SCALE}
      y={shape.properties.y * SHAPE_TO_CANVAS_SCALE}
      rotation={-shape.properties.r}
    >
      <Image
        image={image}
        x={(shape.properties.width * SHAPE_TO_CANVAS_SCALE) / 2}
        y={(shape.properties.height * SHAPE_TO_CANVAS_SCALE) / 2}
        rotation={areaDirectionToAngle(shape.parameters.direction)}
        offsetX={image.width / 2}
        offsetY={image.height / 2}
        listening={false}
        strokeEnabled={false}
        {...areaIconAutoScaleAttr}
      />
    </Group>
  );
};
