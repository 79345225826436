import { ShapeGroupInteractivityMode } from "@/modules/common/types/shapeGroup";
import { TemplateType } from "@/modules/common/types/templating";

export const getTemplateInteractivityMode = (type: TemplateType): ShapeGroupInteractivityMode => {
  switch (type) {
    case TemplateType.LASSIE:
      return ShapeGroupInteractivityMode.LOCKED_SELECTABLE;
    case TemplateType.SHUTTLE_RACK:
      return ShapeGroupInteractivityMode.UNRESTRICTED;
    default:
      // Exhaustive switch. Error if not all options are covered
      return type;
  }
};
