import { TemplateGenParams, TemplateGenParamsConstructor } from '@/modules/common/types/shapeGroup';
import { TemplateType } from '@/modules/common/types/templating';
import { getUserPreference, UserPreferenceName } from '@/modules/userPreferences';
import { CANVAS_TO_SHAPE_SCALE } from '@/modules/workspace/helpers/konva';

const TEMPLATE_BASE_NAME_MAP: Record<TemplateType, string> = {
  [TemplateType.LASSIE]: 'Lassie',
  [TemplateType.SHUTTLE_RACK]: 'Shuttle rack',
} as const;

export const generateTemplateGroupName = (
  templateType: TemplateType,
  nameType: 'upperCaseAbbreviation' | 'full',
): string => {
  const storageKey = templateType.replace(' ', '_');

  const index = parseInt(sessionStorage.getItem(storageKey) ?? '1');
  sessionStorage.setItem(storageKey, (index + 1).toString());

  if (nameType === 'upperCaseAbbreviation') {
    return `${storageKey.substring(0, 2).toUpperCase()}${String(index).padStart(2, '0')}`;
  }

  return `${TEMPLATE_BASE_NAME_MAP[templateType]} ${index}`;
};

export function buildTemplateGenerationParams<T extends TemplateType>(
  type: T,
  params: TemplateGenParamsConstructor[T],
): TemplateGenParams[T] {
  if (type === TemplateType.SHUTTLE_RACK) {
    const shuttleParams = params as TemplateGenParamsConstructor[TemplateType.SHUTTLE_RACK];
    return {
      name: generateTemplateGroupName(type, 'upperCaseAbbreviation'),
      loadTypeRef: shuttleParams.loadCarrierId,
      vehicleTypeRef: shuttleParams.vehicleTypeId,
      centerXinMm: Math.round(shuttleParams.mousePos.x * CANVAS_TO_SHAPE_SCALE),
      centerYinMm: Math.round(shuttleParams.mousePos.y * CANVAS_TO_SHAPE_SCALE),
      angleInDeg: 0,
      numColumns: getUserPreference(UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_COLUMNS),
      numLevels: getUserPreference(UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_LEVELS),
      numDeep: getUserPreference(UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_DEEP),
      entrySide: getUserPreference(UserPreferenceName.TEMPLATE_SHUTTLE_ENTRY_SIDE),
    } as TemplateGenParams[T];
  }

  const lassieParams = params as TemplateGenParamsConstructor[TemplateType.LASSIE];
  return {
    name: generateTemplateGroupName(type, 'upperCaseAbbreviation'),
    x: Math.round(lassieParams.mousePos.x * CANVAS_TO_SHAPE_SCALE),
    y: Math.round(lassieParams.mousePos.y * CANVAS_TO_SHAPE_SCALE),
    angle: 0,
    numAisles: getUserPreference(UserPreferenceName.TEMPLATE_LASSIE_NUM_AISLES),
    numBays: getUserPreference(UserPreferenceName.TEMPLATE_LASSIE_NUM_BAYS),
    numShelves: getUserPreference(UserPreferenceName.TEMPLATE_LASSIE_NUM_SHELVES),
  } as TemplateGenParams[T];
}
