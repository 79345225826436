import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import { memo } from 'react';

import { Accordion } from '@common/components/Accordion';
import { TemplateType } from '@/modules/common/types/templating';
import { LassieProperties } from './LassieProperties';
import { ShuttleRackProperties } from './ShuttleRackProperties';

const TemplatePropertiesComponent = ({ type }: { type: TemplateType }) => (
  <Accordion
    title={type}
    titleSx={{ textTransform: 'capitalize' }}
    defaultExpanded
    unmountOnExit
    sx={{ px: 2 }}
  >
    <Stack direction='column' spacing={1} divider={<Divider sx={{ padding: '4px 0px' }} />}>
      {type === TemplateType.LASSIE && <LassieProperties />}
      {type === TemplateType.SHUTTLE_RACK && <ShuttleRackProperties />}
    </Stack>
  </Accordion>
);

export const TemplateProperties = memo(TemplatePropertiesComponent);
