export type BaseNotification = {
  id: string;
  title: string;
  description: string;
  read: boolean;
  type: NotificationType;
  timestamp: Date;
  severity: string;
  code?: string;
  readMoreUrl?: string;
  solution?: string;
};

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  FATAL = 'fatal',
}

export enum SupportedLanguages {
  ENGLISH = 'en',
}
