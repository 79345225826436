import FolderZipIcon from '@mui/icons-material/FolderZip';
import ImageIcon from '@mui/icons-material/Image';
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  Link,
  IconButton,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { download } from '@modules/common/helpers/browser';
import { groupNameSelector, useFloorPlanFile } from '@modules/floorplan';
import { OutputFiles } from '@modules/floorplanService';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { FormattedWarning, Warning } from '@/modules/floorplanService/types';
import { Box } from '@mui/system';
import getIcon from '../Toolbar3/getIcons';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import NotificationIcon from '@/modules/Notifications/components/NotificationIcon';
import { NotificationType } from '@/modules/Notifications/helpers/types';
import { theme } from '@/modules/common/components/theme';
import { getSeverityStyles } from '@/components/NavigationBar/helpers/SeverityStyles';
import { DownloadIcon } from '@/assets/icons';

type Props = {
  output: OutputFiles;
  warnings: Warning[];
  formattedWarnings: FormattedWarning[];
};

export function ExportOutput({ output, warnings, formattedWarnings }: Props) {
  const { t } = useTranslation(['interface']);
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const name = useRecoilValue(groupNameSelector);
  const { downloadFloorplan } = useFloorPlanFile();
  const handleWarningClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  const handleDownloadZip = useCallback(() => {
    download(output.zipFile, `${name}.zip`);
  }, [name, output.zipFile]);

  const openWindow = useCallback((file: File) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    window.open(URL.createObjectURL(file), '_blank').focus();
  }, []);

  return (
    <List sx={{ maxWidth: '500px', marginTop: '0.4em' }}>
      {output.zipFile && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleDownloadZip}>
            <ListItemIcon>
              <FolderZipIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('interface:export.download_prompt', 'Download the output zip file')}
            />
          </ListItemButton>
        </ListItem>
      )}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.files_label', 'Files')}
      </ListSubheader>
      {output.files &&
        output.files.map((file: File) => (
          <ListItem disablePadding key={file.name}>
            <ListItemButton onClick={() => openWindow(file)}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:notifications.title', 'notifications')}
      </ListSubheader>
      {warnings.length > 0 && formattedWarnings.length === 0 ? (
        <Stack alignItems='center'>
          <CircularProgress />
        </Stack>
      ) : (
        formattedWarnings.map((warning) => (
          <div key={warning.sequence}>
            <ListItem
              sx={{
                ...getSeverityStyles(warning.severity),
                p: '16px',
                cursor: 'pointer',
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 18,
                  left: 14,
                  color: theme.palette.others.black,
                  padding: 0,
                }}
              >
                <NotificationIcon type={NotificationType[warning.severity]} />
              </div>
              <ListItemText sx={{ marginLeft: '50px', width: '100%' }}>
                <Typography
                  style={{ wordWrap: 'break-word' }}
                  variant='body1'
                  sx={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    width: '100%',
                    lineHeight: '16px',
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    mb: 1,
                  }}
                >
                  {warning.errorCode}: {warning.title}
                </Typography>
                <Typography
                  style={{ wordWrap: 'break-word' }}
                  variant='body2'
                  color='textSecondary'
                  sx={{ mt: 1, mb: 1, fontSize: '10px' }}
                >
                  {warning.description}
                </Typography>
                <Typography
                  variant='body2'
                  style={{ wordWrap: 'break-word' }}
                  color='textSecondary'
                  sx={{ mt: 1, mb: '24px', fontSize: '10px' }}
                >
                  {warning.solution}
                </Typography>
                <Box display='flex' alignItems='center' justifyContent='flex-end'>
                  {warning.severity == 'ERROR' && (
                    <>
                      <StyledTooltip
                        title={t('interface:notifications.zoomToArea', 'Zoom to area')}
                        placement='top'
                        sx={{ pointerEvents: 'none' }}
                        PopperProps={{
                          modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
                        }}
                      >
                        <IconButton
                          size='small'
                          disableRipple
                          disableFocusRipple
                          sx={{
                            width: '18px',
                            height: '18px',
                            padding: 0,
                            mr: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'background-color 0.3s ease, border-radius 0.3s ease',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              borderRadius: '2px',
                            },
                            '&:focus': {
                              outline: 'none',
                              backgroundColor: 'transparent',
                            },
                            '&:active': {
                              backgroundColor: '#DFE5FC',
                              borderRadius: '2px',
                            },
                          }}
                          onClick={() =>
                            warning.shapes.forEach((shape) => handleWarningClick(shape.id))
                          }
                        >
                          {getIcon('detail')}
                        </IconButton>
                      </StyledTooltip>
                      <StyledTooltip
                        title={t('interface:notifications.readMore', 'Read more')}
                        placement='top-end'
                        sx={{ pointerEvents: 'none' }}
                        PopperProps={{
                          modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
                        }}
                      >
                        <IconButton
                          size='small'
                          disableRipple
                          disableFocusRipple
                          sx={{
                            transition: 'background-color 0.3s ease, border-radius 0.3s ease',
                            width: '18px',
                            height: '18px',
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              borderRadius: '2px',
                            },
                            '&:focus': {
                              outline: 'none',
                              backgroundColor: 'transparent',
                            },
                            '&:active': {
                              backgroundColor: '#DFE5FC',
                              borderRadius: '2px',
                            },
                          }}
                          onClick={() => window.open(warning.readMore, '_blank')}
                        >
                          {getIcon('readMore')}
                        </IconButton>
                      </StyledTooltip>
                    </>
                  )}
                  {warning.severity === 'FATAL' && (
                    <>
                      <StyledTooltip
                        title={t('interface:notifications.readMore', 'Read more')}
                        placement='top-end'
                        sx={{ pointerEvents: 'none' }}
                        PopperProps={{
                          modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
                        }}
                      >
                        <IconButton
                          size='small'
                          disableRipple
                          disableFocusRipple
                          sx={{
                            transition: 'background-color 0.3s ease, border-radius 0.3s ease',
                            transform: 'scale(0.7)',
                            width: '18px',
                            height: '18px',
                            mr: '8px',
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              borderRadius: '2px',
                            },
                            '&:focus': {
                              outline: 'none',
                              backgroundColor: 'transparent',
                            },
                            '&:active': {
                              backgroundColor: '#DFE5FC',
                              borderRadius: '2px',
                            },
                          }}
                          onClick={() => downloadFloorplan()}
                        >
                          <DownloadIcon sx={{ color: theme.palette.others.black }} />
                        </IconButton>
                      </StyledTooltip>
                      <StyledTooltip
                        title={t('interface:notifications.contactSupport', 'Contact Support')}
                        placement='top-end'
                        sx={{ pointerEvents: 'none' }}
                        PopperProps={{
                          modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
                        }}
                      >
                        <IconButton
                          size='small'
                          disableRipple
                          disableFocusRipple
                          sx={{
                            transition: 'background-color 0.3s ease, border-radius 0.3s ease',
                            width: '18px',
                            height: '18px',
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              borderRadius: '2px',
                            },
                            '&:focus': {
                              outline: 'none',
                              backgroundColor: 'transparent',
                            },
                            '&:active': {
                              backgroundColor: '#DFE5FC',
                              borderRadius: '2px',
                            },
                          }}
                          onClick={() =>
                            window.open(
                              'https://t-hive.freshdesk.com/support/tickets/new',
                              '_blank',
                            )
                          }
                        >
                          {getIcon('contact')}
                        </IconButton>
                      </StyledTooltip>
                    </>
                  )}
                </Box>
              </ListItemText>
            </ListItem>
          </div>
        ))
      )}
    </List>
  );
}
