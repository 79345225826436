import { ErrorIcon } from '@/assets/icons';
import FatalIcon from '@/assets/icons/FatalIcon';
import InfoIcon from '@/assets/icons/InfoIcon';
import SuccessIcon from '@/assets/icons/SuccessIcon';
import WarningIcon from '@/assets/icons/Warning';
import { NotificationType } from '@/modules/Notifications/helpers/types';

interface NotificationIconProps {
  type: NotificationType;
}

const iconMap = {
  [NotificationType.SUCCESS]: <SuccessIcon />,
  [NotificationType.ERROR]: <ErrorIcon />,
  [NotificationType.WARNING]: <WarningIcon />,
  [NotificationType.INFO]: <InfoIcon />,
  [NotificationType.FATAL]: <FatalIcon />,
};

export default function NotificationIcon({ type }: NotificationIconProps) {
  return <div>{iconMap[type]}</div>;
}
