import { ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { modeSelector } from '@/modules/common/store/workspace';
import { LoadingType, WorkspaceMode } from '@/modules/common/types/general';
import {
  ShuttleRackGroup,
  ShuttleRackGenerationParams,
  ShuttleRackEntrySide,
} from '@/modules/common/types/shapeGroup';
import { TemplateType } from '@/modules/common/types/templating';
import { useLoading } from '@/modules/common/hooks';
import { useFloorPlanState } from '@/modules/floorplan';
import { selectedGroupsSelector, selectedGroupIdsState } from '@/modules/shapeGroups';
import { useTemplateGenerationCallbacks } from '@/modules/templating';
import { SHUTTLE_RACK } from '@/modules/templating/constants';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useZoomButton } from '@/modules/workspace/components/ZoomButton/useZoomButton';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { TemplateNumberInput } from './TemplateNumberInput';

/**
 * @description
 * FPS has an issue with dimensions of a shuttle rack (rectangle issue).
 * For now, this should guarantee that it doesn't make the generation fail
 */
const generationWouldFail = (numColumns: number, numDeep: number) => numColumns / numDeep < 2 / 3;

const ShuttleRackPropertiesComponent = () => {
  const { t } = useTranslation();
  const mode = useRecoilValue(modeSelector);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { saveFloorPlan } = useFloorPlanState();
  const shuttleRackGroup = useRecoilValue(selectedGroupsSelector)[0] as ShuttleRackGroup;
  const { showLoader, hideLoader } = useLoading();
  const { zoomFit } = useZoomButton();
  const { createTemplate } = useTemplateGenerationCallbacks();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const setSelectedGroupIds = useSetRecoilState(selectedGroupIdsState);

  const [newGenerationParams, setNewGenerationParams] = useState<ShuttleRackGenerationParams>({
    ...shuttleRackGroup.generationParams,
  });

  const updatePreferences = useCallback(() => {
    if (shuttleRackGroup.generationParams.numColumns !== newGenerationParams.numColumns)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_COLUMNS,
        newGenerationParams.numColumns,
        false,
      );

    if (shuttleRackGroup.generationParams.numLevels !== newGenerationParams.numLevels)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_LEVELS,
        newGenerationParams.numLevels,
        false,
      );

    if (shuttleRackGroup.generationParams.numDeep !== newGenerationParams.numDeep)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_DEEP,
        newGenerationParams.numDeep,
        false,
      );
  }, [
    updateUserPreference,
    shuttleRackGroup.generationParams,
    newGenerationParams.numColumns,
    newGenerationParams.numDeep,
    newGenerationParams.numLevels,
  ]);

  const regenerate = useCallback(async () => {
    showLoader(LoadingType.TRANSPARENT);
    const group = await createTemplate(
      TemplateType.SHUTTLE_RACK,
      newGenerationParams,
      shuttleRackGroup.id,
    );

    if (!group) {
      hideLoader();
      return;
    }

    setSelectedShapesIds(group.children);
    setSelectedGroupIds([group.id]);
    zoomFit();
    hideLoader();
    saveFloorPlan();
    updatePreferences();
  }, [
    showLoader,
    createTemplate,
    newGenerationParams,
    shuttleRackGroup.id,
    setSelectedShapesIds,
    setSelectedGroupIds,
    zoomFit,
    hideLoader,
    updatePreferences,
    saveFloorPlan,
  ]);

  const onNumLevelsChange = useCallback(
    (newValue: number) => {
      if (newValue < SHUTTLE_RACK.MIN.NUM_LEVELS) {
        return {
          error: t('common:minimum_value', `Minimum ${SHUTTLE_RACK.MIN.NUM_LEVELS}`, {
            minValue: SHUTTLE_RACK.MIN.NUM_LEVELS,
          }),
        };
      }

      setNewGenerationParams(
        (current: ShuttleRackGenerationParams): ShuttleRackGenerationParams => ({
          ...current,
          numLevels: newValue,
        }),
      );
    },
    [t],
  );
  const onColumnsChange = useCallback(
    (newValue: number) => {
      if (newValue < SHUTTLE_RACK.MIN.NUM_COLUMNS) {
        return {
          error: t('common:minimum_value', `Minimum ${SHUTTLE_RACK.MIN.NUM_COLUMNS}`, {
            minValue: SHUTTLE_RACK.MIN.NUM_COLUMNS,
          }),
        };
      }

      console.log(generationWouldFail(newValue, newGenerationParams.numDeep));

      if (generationWouldFail(newValue, newGenerationParams.numDeep)) {
        const minNumColumns = Math.ceil(newGenerationParams.numDeep * (2 / 3));
        return {
          error: t(
            'errors:template.shuttle.minimum_columns',
            `Minimum ${minNumColumns} (2/3 of uh).`,
            {
              minValue: minNumColumns,
            },
          ),
        };
      }

      setNewGenerationParams(
        (current: ShuttleRackGenerationParams): ShuttleRackGenerationParams => ({
          ...current,
          numColumns: newValue,
        }),
      );
    },
    [t, newGenerationParams.numDeep],
  );
  const onNumDeepChange = useCallback(
    (newValue: number) => {
      if (newValue < SHUTTLE_RACK.MIN.NUM_DEEP) {
        return {
          error: t('common:minimum_value', `Minimum ${SHUTTLE_RACK.MIN.NUM_DEEP}`, {
            minValue: SHUTTLE_RACK.MIN.NUM_DEEP,
          }),
        };
      }

      console.log(generationWouldFail(newGenerationParams.numColumns, newValue));

      if (generationWouldFail(newGenerationParams.numColumns, newValue)) {
        const maxNumDeep = Math.floor(newGenerationParams.numColumns * (3 / 2));
        return {
          error: t(
            'errors:template.shuttle.maximum_unit_loads_by_depth',
            `Maximum ${maxNumDeep} (3/2 of columns).`,
            {
              maxValue: maxNumDeep,
            },
          ),
        };
      }

      setNewGenerationParams(
        (current: ShuttleRackGenerationParams): ShuttleRackGenerationParams => ({
          ...current,
          numDeep: newValue,
        }),
      );
    },
    [t, newGenerationParams.numColumns],
  );
  const onEntrySideChange = useCallback((_, value: ShuttleRackEntrySide) => {
    if (value != null) {
      setNewGenerationParams(
        (current: ShuttleRackGenerationParams): ShuttleRackGenerationParams => ({
          ...current,
          entrySide: value,
        }),
      );
    }
  }, []);

  const regenerationDisabled =
    newGenerationParams.numColumns === shuttleRackGroup.generationParams.numColumns &&
    newGenerationParams.numDeep === shuttleRackGroup.generationParams.numDeep &&
    newGenerationParams.numLevels === shuttleRackGroup.generationParams.numLevels &&
    newGenerationParams.entrySide === shuttleRackGroup.generationParams.entrySide;

  return (
    <>
      <PropertiesLabel i18nkey='interface:properties.template.levels'>
        <TemplateNumberInput
          value={newGenerationParams.numLevels}
          onChange={onNumLevelsChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.columns'>
        <TemplateNumberInput
          value={newGenerationParams.numColumns}
          onChange={onColumnsChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.units_deep'>
        <TemplateNumberInput
          value={newGenerationParams.numDeep}
          onChange={onNumDeepChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.entry_side.label'>
        <ToggleButtonGroup
          value={newGenerationParams.entrySide}
          exclusive
          onChange={onEntrySideChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        >
          <ToggleButton
            value={ShuttleRackEntrySide.SINGLE}
            aria-label='Enable shuttle rack entry from a single side'
            sx={{ p: 0, fontSize: '13px' }}
          >
            {t('interface:properties.template.entry_side.single')}
          </ToggleButton>
          <ToggleButton
            value={ShuttleRackEntrySide.BOTH}
            aria-label='Enable shuttle rack entry from both sides'
            sx={{ p: 0, fontSize: '13px' }}
          >
            {t('interface:properties.template.entry_side.both')}
          </ToggleButton>
        </ToggleButtonGroup>
      </PropertiesLabel>
      <Button disabled={regenerationDisabled} onClick={regenerate}>
        {t('common:apply')}
      </Button>
    </>
  );
};

export const ShuttleRackProperties = memo(ShuttleRackPropertiesComponent);
