import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const margin = (shapeType: ShapeType): number => {
  switch (shapeType) {
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return getUserPreference(UserPreferenceName.STORAGE_MARGIN);
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return getUserPreference(UserPreferenceName.INTAKE_MARGIN);
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return getUserPreference(UserPreferenceName.DELIVERY_MARGIN);
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return getUserPreference(UserPreferenceName.PROCESS_MARGIN);
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
      return getUserPreference(UserPreferenceName.CHARGING_MARGIN);
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return getUserPreference(UserPreferenceName.PARKING_MARGIN);
    case ShapeType.MANUAL_EXIT:
    case ShapeType.MANUAL_EXIT_POSITION:
      return getUserPreference(UserPreferenceName.MANUAL_EXIT_MARGIN);
    case ShapeType.MANUAL_ENTRY:
    case ShapeType.MANUAL_ENTRY_POSITION:
      return getUserPreference(UserPreferenceName.MANUAL_ENTRY_MARGIN);
    case ShapeType.HIGHWAY:
    case ShapeType.HIGHWAY_ANGLED:
      return getUserPreference(UserPreferenceName.HIGHWAY_MARGIN);
    default:
      return 1000;
  }
};
