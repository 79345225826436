import { ShapeType } from "@/modules/common/types/shapes";
import { encodeIdWithVehicleId } from "@/modules/floorplanService/helpers/mapping/idEncoder";
import { AreaMapperTypeEnum } from "@/modules/floorplanService/helpers/mapping/types";

 export const getConnectedShapesWithConnections = (manualArea, connections, shapeAreas) =>
    connections
      .filter((con) => con.from === manualArea.id || con.to === manualArea.id)
      .map((connection) => {
        const connectedShapeId =
          connection.from === manualArea.id ? connection.to : connection.from;
        const connectedShape = shapeAreas.find((shape) => shape.id === connectedShapeId);
        return connectedShape ? { connectedShape, connection } : null;
      });

  export const createManualMapping = (linkedShape, manualArea, vehicleSpec) => ({
    sequence: vehicleSpec.gateMappingSettings.areaMappings.length + 1,
    areaReference: {
      name: encodeIdWithVehicleId(linkedShape.connectedShape.id, vehicleSpec.databaseId),
    },
    mapEndPointsToLoadPositions: {
      areaReferencesToMap: [
        {
          name: encodeIdWithVehicleId(manualArea.id, vehicleSpec.databaseId),
        },
      ],
      discType: AreaMapperTypeEnum.MAP_END_POINTS_TO_LOAD_POSITIONS,
      usePivots: linkedShape.connection.usePivots,
    },
  });

  export const processManualArea = (manualArea, vehicleSpecs, connections, shapeAreas) => {
    const validConnectionTypes = [ShapeType.MANUAL_EXIT, ShapeType.MANUAL_ENTRY];

    vehicleSpecs
      .filter((vehicleSpec) =>
        manualArea.parameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId),
      )
      .forEach((vehicleSpec) => {
        const connectedShapesWithConnections = getConnectedShapesWithConnections(
          manualArea,
          connections,
          shapeAreas,
        );

        connectedShapesWithConnections.forEach((linkedShape) => {
          if (!linkedShape || !validConnectionTypes.includes(linkedShape.connectedShape.type)) {
            return;
          }

          const manualMappings = createManualMapping(linkedShape, manualArea, vehicleSpec);
          vehicleSpec.gateMappingSettings.areaMappings.push(manualMappings);
        });
      });
  };