import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { prevalidationState } from '../state';
import { AreaShape } from '@/store/recoil/shape';

export const invalidManualAreasSelector = selector({
  key: 'prevalidation/invalidManualAreas',
  get: ({ get }) => get(prevalidationState).invalidManualAreas,
  set: ({ set }, newItems: AreaShape[]) => {
    set(prevalidationState, (current) => ({ ...current, invalidManualAreas: newItems }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
