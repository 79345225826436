import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { areaUnloadDuration } from '@/components/PropertiesPanel/store/area';
import { NumberInput } from '@/modules/common/components/inputs';
import { ShapeType } from '@/modules/common/types/shapes';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';

function UnloadDuration() {
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();
  const [unloadDuration, setUnloadDuration] = useRecoilState(areaUnloadDuration);
  const { updateUserPreference } = useUpdateUserPreferences();

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const onChangeUnloadDurationType = useRecoilCallback(
    ({ snapshot }) =>
      async (newVal: number) => {
        setUnloadDuration(newVal);
        save();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        switch (selectedShapes[0].type) {
          case ShapeType.DELIVERY:
          case ShapeType.DELIVERY_POSITION:
            updateUserPreference(UserPreferenceName.DELIVERY_UNLOAD_DURATION, newVal);
            break;
          case ShapeType.STORAGE:
          case ShapeType.STORAGE_POSITION:
            updateUserPreference(UserPreferenceName.STORAGE_UNLOAD_DURATION, newVal);
            break;
          case ShapeType.MANUAL:
          case ShapeType.MANUAL_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_UNLOAD_DURATION, newVal);
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_UNLOAD_DURATION, newVal);
            break;
          default:
        }
      },
    [setUnloadDuration, save],
  );

  return (
    <NumberInput
      value={unloadDuration}
      unit='sec'
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChangeUnloadDurationType}
      onFocus={onFocus}
      onBlur={onBlur}
      errorType='floating'
      dataType='integer'
      minValue={0}
    />
  );
}

export default UnloadDuration;
