import { format } from 'date-fns';
import { useCallback } from 'react';

import { useArtefacts } from '@/modules/artefacts';
import { FloorPlan } from '@/modules/common/types/floorPlan';
import {
  isReferencePresentSelector,
  referenceDimensions,
  referenceOriginalSize,
} from '@/modules/referenceImage/store';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { groupNameSelector } from '..';
import { useAutoSave } from './useAutoSave';
import { useBackwardCompatibility } from './useBackwardCompatibility';
import { useFloorPlanState } from './useFloorPlanState';
import {
  allVehicleAssetsSelector,
  enabledVehiclesSelector,
  useVehicleDependencyManager,
} from '@/modules/vehicles';
import { useFloorplanObjectValidation } from '@/modules/floorplan/validation/useFloorplanTypeGuard';
import { useZipFileProcessor } from '@/modules/vehicles/hooks/useZipFileProcessor';
import { downloadFile } from '@/modules/common/helpers/browser';
import { useNotificationMessageApi } from '@/modules/Notifications/hooks/useNotificationMessageApi';

export const useFloorPlanFile = () => {
  const { getFloorPlan, loadFloorPlanState } = useFloorPlanState();
  const { save } = useAutoSave();
  const vehicleTypes = useRecoilState(allVehicleAssetsSelector);
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const { convert } = useBackwardCompatibility();
  const { initialize: initializeArtefacts } = useArtefacts();
  const { isValidFloorplan } = useFloorplanObjectValidation();
  const {
    ensureVehicleDetailsAvailability,
    ensureVehicleAssetAvailability,
    cleanUpRedundantAssets,
  } = useVehicleDependencyManager();
  const { getVehicleAssetFiles } = useVehicleDependencyManager();
  const { createAssetZip } = useZipFileProcessor();
  const { getNotificationDetails } = useNotificationMessageApi();

  /**
   *
   * Returns a floor plan json file
   */
  const getFile = useCallback((floorPlan: FloorPlan, groupName: string) => {
    // @ts-expect-error strictNullChecks. Pls fix me
    floorPlan.additionalData.urn = undefined;
    // @ts-expect-error strictNullChecks. Pls fix me
    floorPlan.additionalData.thumbnail = undefined;

    const version = floorPlan.hasUnsavedChanges ? floorPlan.version : floorPlan.version - 1;
    const fileContents = JSON.stringify(floorPlan);
    const fileName = `Floorplan_${groupName}-Version_${version}-${format(
      new Date(),
      'yyyyMMdd',
    )}.json`;
    return new File([fileContents], fileName, {
      type: 'application/json',
    });
  }, []);

  /**
   * Get the current floor plan json
   */
  const getCurrentFloorplanFile = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const floorPlan = await getFloorPlan();
        const referencePresent = await snapshot.getPromise(isReferencePresentSelector);
        if (referencePresent) {
          const originalSize = await snapshot.getPromise(referenceOriginalSize);
          const currentSize = await snapshot.getPromise(referenceDimensions);
          floorPlan.additionalData.scaleFactor = currentSize.width / originalSize.width;
        }
        return getFile(floorPlan, await snapshot.getPromise(groupNameSelector));
      },
    [getFloorPlan, getFile],
  );

  /**
   * Loads a floor plan from a json file
   */
  const loadFile = useCallback(
    async (file: File) => {
      const parseFile = new Promise<any>((resolve): any => {
        const fileReader = new FileReader();
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = async (e) => {
          // @ts-expect-error strictNullChecks. Pls fix me
          const fp = JSON.parse(e.target.result as string);
          if (fp.additionalData) {
            fp.additionalData.urn = undefined;
          }
          resolve(fp);
        };
      });

      const floorplan = await Promise.resolve(parseFile);

      if (isValidFloorplan(floorplan)) {
        const { vehicleTypes, vehicleAssets } = floorplan.additionalData;
        const assets = await ensureVehicleAssetAvailability(vehicleTypes, vehicleAssets);

        await ensureVehicleDetailsAvailability(vehicleTypes, assets);
        await loadFloorPlanState(convert(floorplan.additionalData));
        await initializeArtefacts();
        await save();
      }
    },
    [
      convert,
      initializeArtefacts,
      loadFloorPlanState,
      save,
      isValidFloorplan,
      ensureVehicleDetailsAvailability,
      ensureVehicleAssetAvailability,
    ],
  );

  const downloadFloorplan = useCallback(async () => {
    if (!vehicleTypes[0] || vehicleTypes[0].length === 0) {
      console.error('No vehicles found');
      return;
    }

    const enabledVehicleIds = new Set(enabledVehicles.map((ev) => ev.id));
    const vehicles = vehicleTypes[0].filter((vt) => enabledVehicleIds.has(vt.vehicleVariantId));
    try {
      cleanUpRedundantAssets();
      const { uvtFiles, uvtFileNames, kmMdbFiles, kmMdbFileNames } = await getVehicleAssetFiles(
        vehicles,
      );

      const floorplan = await getCurrentFloorplanFile();
      if (!kmMdbFiles.length && !uvtFiles.length && !floorplan) return;

      const zipBlob = await createAssetZip(
        uvtFiles,
        uvtFileNames,
        kmMdbFiles,
        kmMdbFileNames,
        floorplan,
      );

      downloadFile(zipBlob, '.zip', `${floorplan.name.slice(0, -5)}.zip`);
    } catch (error) {
      console.log(error);
      getNotificationDetails('500');
    }
  }, [
    vehicleTypes,
    enabledVehicles,
    getVehicleAssetFiles,
    createAssetZip,
    getCurrentFloorplanFile,
    cleanUpRedundantAssets,
    getNotificationDetails,
  ]);

  return {
    downloadFloorplan,
    getCurrentFloorplanFile,
    loadFile,
  };
};
