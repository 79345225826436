import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { availableLoadCarrierTypesState } from '@/store/recoil/loadCarrierTypes';
import { useDebugStore } from '@/modules/debug/store';
import { SetupPropertiesSelectDialog } from '../../common/SetupPropertiesSelectDialog';
import { LoadCarrierTypeCard } from './LoadCarrierTypeCard';

type Props = {
  open: boolean;
  enabledLoadCarriersIds: string[];
  onSubmit: (id: string[]) => void;
  onClose: () => void;
};

export const LoadCarrierTypeSelectDialog = ({
  open,
  enabledLoadCarriersIds,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['interface']);
  const availableLoadCarrierTypes = useRecoilValue(availableLoadCarrierTypesState);
  const [innerSelectedLoadCarriersIds, setInnerSelectedLoadCarriersIds] =
    useState<string[]>(enabledLoadCarriersIds);
  const multiLoadCarrierSelectionEnabled = useDebugStore(
    (state) => state.multiLoadCarrierSelectionEnabled,
  );

  useEffect(
    () => setInnerSelectedLoadCarriersIds(enabledLoadCarriersIds),
    [open, enabledLoadCarriersIds],
  );

  const onClick = useCallback(
    (id: string) => {
      if (multiLoadCarrierSelectionEnabled) {
        // Multi select
        setInnerSelectedLoadCarriersIds((innerSelectedIds) => {
          if (innerSelectedIds.includes(id)) {
            return innerSelectedIds.filter((idA) => idA !== id);
          }
          return [...innerSelectedIds, id];
        });
      } else {
        // Single select
        setInnerSelectedLoadCarriersIds([id]);
      }
    },
    [multiLoadCarrierSelectionEnabled],
  );

  return (
    <SetupPropertiesSelectDialog
      title={t('setup.floorPlan.load_carrier_types_select_dialog.title')}
      innerSelectedIds={innerSelectedLoadCarriersIds}
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Stack flexGrow={1} overflow='auto'>
        <Stack gap={4} padding='32px 40px 40px 40px' direction='row' flexWrap='wrap'>
          {availableLoadCarrierTypes.map((item) => (
            <LoadCarrierTypeCard
              key={item.id}
              item={item}
              selected={innerSelectedLoadCarriersIds.includes(item.id)}
              onClick={onClick}
            />
          ))}
        </Stack>
      </Stack>
    </SetupPropertiesSelectDialog>
  );
};
