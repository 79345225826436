/* eslint-disable prefer-template */
import { theme } from '@modules/common/components/theme';
import { ShapeType } from '@modules/common/types/shapes';

export const LANE_COLOR = theme.palette.areas.laneColor;

export const SHAPES_COLORS_MAP = {
  [ShapeType.WALL]: theme.palette.areas.wall,
  [ShapeType.INTAKE]: theme.palette.areas.intakeBg,
  [ShapeType.INTAKE_POSITION]: theme.palette.areas.intakeBg,
  [ShapeType.STORAGE]: theme.palette.areas.storage,
  [ShapeType.STORAGE_POSITION]: theme.palette.areas.storage,
  [ShapeType.DELIVERY]: theme.palette.areas.delivery,
  [ShapeType.DELIVERY_POSITION]: theme.palette.areas.delivery,
  [ShapeType.PROCESS_ONE_EP]: theme.palette.areas.processCarrier,
  [ShapeType.PROCESS_ONE_EP_POSITION]: theme.palette.areas.processCarrier,
  [ShapeType.PROCESS_TWO_EP]: theme.palette.areas.processCarrier,
  [ShapeType.CHARGING]: theme.palette.areas.charging,
  [ShapeType.CHARGING_POSITION]: theme.palette.areas.charging,
  [ShapeType.PARKING]: theme.palette.areas.parking,
  [ShapeType.PARKING_POSITION]: theme.palette.areas.parking,
  [ShapeType.MANUAL]: theme.palette.areas.manual,
  [ShapeType.MANUAL_POSITION]: theme.palette.areas.manual,
  [ShapeType.MANUAL_EXIT]: theme.palette.areas.manualExit,
  [ShapeType.MANUAL_EXIT_POSITION]: theme.palette.areas.manualExit,
  [ShapeType.MANUAL_ENTRY]: theme.palette.areas.manualEntry,
  [ShapeType.MANUAL_ENTRY_POSITION]: theme.palette.areas.manualEntry,
  [ShapeType.HIGHWAY]: theme.palette.areas.highway,
  [ShapeType.HIGHWAY_ANGLED]: theme.palette.areas.highway,
  [ShapeType.OBSTACLE]: theme.palette.areas.obstacle,
  [ShapeType.HANDOVER]: theme.palette.areas.handover,
};

export const ACTIVE_ARROW_LINE = theme.palette.status.scheduled;
export const ACTIVE_ARROW_CONNECTION_LINE = theme.palette.status.scheduled;

export const defaultColor = theme.palette.shades.dark;

export const getShapeColor = (
  shapeType: ShapeType,
  transparentFill = false,
  transparency = null,
) => {
  const outColor = SHAPES_COLORS_MAP[shapeType] ?? defaultColor;

  if (transparency) {
    return outColor + transparency;
  }

  if (transparentFill) {
    return outColor + '44';
  }

  return outColor + 'FF';
};

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16),
      }
    : null;
}
