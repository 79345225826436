import { useTemplateApi } from '@/modules/api/hooks';

import { GenerationResponse } from '../types';
import { TemplateType } from '@/modules/common/types/templating';
import { TemplateGenParams } from '@/modules/common/types/shapeGroup';

const TEMPLATE_GENERATION_PATH = {
  [TemplateType.LASSIE]: 'generateLassie',
  [TemplateType.SHUTTLE_RACK]: 'generateShuttleRacks',
} as const;

export const useTemplatingService = () => {
  const templateServiceApi = useTemplateApi();

  const generateTemplate = async <T extends TemplateType>(
    type: T,
    input: TemplateGenParams[T],
  ): Promise<GenerationResponse> => {
    try {
      const response = await templateServiceApi.post(TEMPLATE_GENERATION_PATH[type], input);
      if (response.status !== 200) {
        console.log(`Failed to generate template: ${response.statusText}`);

        return {
          success: false,
        };
      }

      const { additionalData } = response.data;

      return {
        success: true,
        data: {
          shapes: additionalData?.shapes || [],
          connections: additionalData?.connections || [],
          distcons: additionalData?.distcons || [],
          idGenerators: additionalData?.idGenerators || [],
          unit: additionalData?.unit || 'mm',
        },
      };
    } catch (e) {
      console.log('Error generating template: ', e);

      return {
        success: false,
      };
    }
  };

  return {
    generateTemplate,
  };
};
