import { ShapeType, AreaDirection } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const direction = (shapeType: ShapeType): AreaDirection => {
  switch (shapeType) {
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return getUserPreference(UserPreferenceName.INTAKE_DIRECTION);
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return getUserPreference(UserPreferenceName.STORAGE_DIRECTION);
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return getUserPreference(UserPreferenceName.DELIVERY_DIRECTION);
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return getUserPreference(UserPreferenceName.PROCESS_DIRECTION);
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
      return getUserPreference(UserPreferenceName.CHARGING_DIRECTION);
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return getUserPreference(UserPreferenceName.PARKING_DIRECTION);
    case ShapeType.MANUAL_EXIT:
    case ShapeType.MANUAL_EXIT_POSITION:
      return getUserPreference(UserPreferenceName.MANUAL_EXIT_DIRECTION);
    case ShapeType.MANUAL_ENTRY:
    case ShapeType.MANUAL_ENTRY_POSITION:
      return getUserPreference(UserPreferenceName.MANUAL_ENTRY_DIRECTION);
    default:
      return AreaDirection.UP;
  }
};
