import { FloorPlanAdjustment, Reflector } from '@/modules/commissioning';

export type GenerateFloorPlanOptions = {
  exportSettings: GenerateFloorPlanExportSettings;
  floorPlanElements?: any; // TODO remove
  abortController?: AbortController;
  progressCallback?: (value: number) => void;
};

export type CheckGenerationProgressOptions = {
  abortController?: AbortController;
  progressCallback?: (value: number) => void;
};

export type GenerateFloorPlanExportSettings = {
  splines: 'EXPORT_WITH_SPLINES' | 'EXPORT' | 'NO_EXPORT';
  unifiedLayout: 'EXPORT_WITH_SPLINES' | 'EXPORT' | 'NO_EXPORT';
  collision: boolean;
  tStackExport?: boolean;
  maestroExport?: boolean;
  includeFloorPlanArtefactsInOutput?: boolean;
  reflectors?: Reflector[];
  floorPlanAdjustment?: FloorPlanAdjustment;
};

export type FileGenerationOutput = {
  files: readonly File[];
  zipFile: any;
  outputUri: string;
  warnings: Warning[];
};

export type OutputFiles = {
  files: readonly File[];
  zipFile: any;
  outputUri: string;
};

export enum ServiceStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
}

export type Arg = {
  argument: string;
  sequence: number;
};

export type Warning = {
  code: string;
  args: Arg[];
  sequence: number;
};

export type FormattedShape = {
  name: string;
  id: string;
  sequence: number;
};

export type FormattedWarning = {
  description: string;
  shapes: FormattedShape[];
  sequence: number;
  title: string;
  readMore?: string;
  severity: string;
  timeStamp: string;
  errorCode: string;
  solution: string;
};

export type FpsAtom = {
  files: OutputFiles;
  status: string;
  warnings: Warning[];
};

export type GeneratedFloorPlanArtefacts = {
  loadPositions: GeneratedLoadPosition[];
  locations: GeneratedLocation[];
};

export type GeneratedReachableLocation = {
  locationName: string;
};

export type GeneratedReachableLoadPostion = {
  loadPositionName: string;
};

export type GeneratedLoadPosition = {
  loadPositionName: string;
  rectangle: Rectangle;
  reachableLocations: GeneratedReachableLocation[];
};

export type GenerationLocationType =
  | 'ROUTING_POINT'
  | 'CHECK_POINT'
  | 'INTAKE_END_POINT'
  | 'STORAGE_END_POINT'
  | 'DELIVERY_END_POINT'
  | 'INTAKE_AND_DELIVERY_END_POINT'
  | 'PARKING_END_POINT'
  | 'CHARGING_END_POINT'
  | 'INSERTION_END_POINT'
  | 'EXTRACTION_END_POINT';

export type GeneratedLocation = {
  areaName: string;
  locationName: string;
  orientation: number;
  type: GenerationLocationType;
  vehicleSpecName: string;
  x: number;
  y: number;
  reachableLoadPositions: GeneratedReachableLoadPostion[];

  rectangle?: Rectangle;
};

export type Rectangle = {
  centerX: number;
  centerY: number;
  width: number;
  length: number;
  angleInDegrees: number;

  minZ?: number;
  height?: number;
};

type IdIncrementer = {
  sequence: number;
  startId: number;
  increment: number;
  numIds: number;
};

export type IdGenerator = {
  name: string;
  discType: string;
  format: string;
  startFirstId: number;
  incrementFirstId: number;
  additionalIncrementers: IdIncrementer[];
};

export type IdGeneratorMap = Map<string, IdGenerator>;
