import { Divider, Stack } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { LoadPlacement } from '@/components/PropertiesPanel/layout/LoadPlacement';
import OperationTime from '@/components/PropertiesPanel/layout/process/OperationTime';
import {
  usesLoadPlacement,
  usesOperationTime,
  usesBlockDuration,
  usesLoadDurations,
} from '@/modules/common/helpers/shapes';
import { selectionContainsLoadCarrierSupport } from '@/store/recoil/shapes/selected';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { ShapeType } from '@modules/common/types/shapes';
import { StorageType as StorageTypeType } from '@modules/common/types/storage';
import { areaSectionTitle, areaStorageType, areaType, areaVehicleIds } from '../store/area';
import Align from './Align';
import AreaDirection from './AreaDirection';
import AreaMargin from './AreaMargin';
import Distribution from './Distribution';
import Gap from './Gap';
import { LoadCarrierSelect } from './LoadCarrierSelect';
import { LoadCarrierSide } from './LoadCarrierSide';
import { LoadElevation } from './LoadElevation';
import ParkingDirection from './ParkingDirection';
import RackProperties from './rack/RackProperties';
import SideLoadingProperties from './SideLoadingProperties';
import StorageType from './StorageType';
import { VehicleSelect } from './VehicleSelect';
import TwoSidedRackProperties from './rack/TwoSidedRackProperties';
import AreaPriority from './AreaPriority';
import ProcessOneEndPointStorageType from './ProcessOneEndPointStorageType';

import BlockDuration from '@/components/PropertiesPanel/layout/timers/BlockDuration';
import LoadAction from './LoadActionProperties';
import LoadDurations from '@/components/PropertiesPanel/layout/LoadDurations';

const AreaPropertyComponent = () => {
  const sectionTitle = useRecoilValue(areaSectionTitle);
  const { t } = useTranslation('interface');
  return (
    <Accordion
      title={t(sectionTitle, sectionTitle)}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
      unmountOnExit
      sx={{ px: 2 }}
    >
      <AreaPropertyContent />
    </Accordion>
  );
};

const AreaPropertyContent = () => {
  const shapeType = useRecoilValue(areaType);
  const storageType = useRecoilValue(areaStorageType);
  const containsLoadCarrierSupport = useRecoilValue(selectionContainsLoadCarrierSupport);
  return (
    <Stack direction='column' spacing={1} divider={<Divider sx={{ padding: '4px 0px' }} />}>
      <>
        {usesOperationTime(shapeType) && (
          <PropertiesLabel i18nkey='interface:properties.area.operation_time'>
            <OperationTime />
          </PropertiesLabel>
        )}

        {usesLoadPlacement(shapeType) && (
          <PropertiesLabel i18nkey='interface:properties.area.load_placement.label'>
            <LoadPlacement />
          </PropertiesLabel>
        )}

        <PropertiesLabel i18nkey='interface:properties.area.supported_vehicle.label'>
          <VehicleSelect />
        </PropertiesLabel>

        {containsLoadCarrierSupport &&
          shapeType &&
          shapeType !== ShapeType.MANUAL_ENTRY &&
          shapeType !== ShapeType.MANUAL_EXIT && (
            // for now, only show when 1 shape is selected
            <PropertiesLabel i18nkey='interface:properties.area.load_carrier_select.label'>
              <LoadCarrierSelect />
            </PropertiesLabel>
          )}

        {storageType !== StorageTypeType.RACK &&
          shapeType !== ShapeType.MANUAL &&
          shapeType !== ShapeType.MANUAL_ENTRY &&
          shapeType !== ShapeType.MANUAL_EXIT && (
            <PropertiesLabel i18nkey='interface:properties.area.direction'>
              <AreaDirection />
            </PropertiesLabel>
          )}

        {shapeType && shapeType !== ShapeType.MANUAL && (
          <PropertiesLabel i18nkey='interface:properties.area.alignment'>
            <Align />
          </PropertiesLabel>
        )}

        {shapeType && shapeType !== ShapeType.MANUAL && (
          <PropertiesLabel i18nkey='interface:properties.area.distribution'>
            <Distribution />
          </PropertiesLabel>
        )}

        {shapeType && shapeType !== ShapeType.MANUAL && (
          <PropertiesLabel i18nkey='interface:properties.area.gap'>
            <Gap />
          </PropertiesLabel>
        )}

        {shapeType && shapeType !== ShapeType.MANUAL && (
          <PropertiesLabel i18nkey='interface:properties.area.margin'>
            <AreaMargin />
          </PropertiesLabel>
        )}

        {shapeType &&
          shapeType !== ShapeType.MANUAL &&
          shapeType !== ShapeType.MANUAL_ENTRY &&
          shapeType !== ShapeType.MANUAL_EXIT &&
          containsLoadCarrierSupport && (
            <PropertiesLabel i18nkey='interface:properties.area.load_carrier_orientation.label'>
              <LoadCarrierSide />
            </PropertiesLabel>
          )}

        {shapeType &&
          shapeType !== ShapeType.CHARGING &&
          shapeType !== ShapeType.PARKING &&
          shapeType !== ShapeType.PROCESS_ONE_EP &&
          shapeType !== ShapeType.MANUAL &&
          shapeType !== ShapeType.MANUAL_ENTRY &&
          shapeType !== ShapeType.MANUAL_EXIT &&
          shapeType !== ShapeType.HANDOVER &&
          storageType !== StorageTypeType.SHUTTLERACK && (
            <PropertiesLabel i18nkey='interface:properties.area.storage_type_label'>
              <StorageType />
            </PropertiesLabel>
          )}

        {shapeType && shapeType === ShapeType.PROCESS_ONE_EP && (
          <PropertiesLabel i18nkey='interface:properties.area.storage_type_label'>
            <ProcessOneEndPointStorageType />
          </PropertiesLabel>
        )}

        {shapeType &&
          shapeType !== ShapeType.MANUAL &&
          shapeType !== ShapeType.MANUAL_ENTRY &&
          shapeType !== ShapeType.MANUAL_EXIT && (
            <PropertiesLabel i18nkey='interface:properties.area.parking_direction'>
              <ParkingDirection />
            </PropertiesLabel>
          )}

        {(shapeType === ShapeType.CHARGING || shapeType === ShapeType.PARKING) && (
          <PropertiesLabel i18nkey='interface:properties.area.priority'>
            <AreaPriority />
          </PropertiesLabel>
        )}

        {shapeType && shapeType === ShapeType.MANUAL && (
          <PropertiesLabel i18nkey='interface:properties.area.load_action'>
            <LoadAction />
          </PropertiesLabel>
        )}

        {usesBlockDuration(shapeType) && (
          <PropertiesLabel i18nkey='interface:properties.area.block_duration'>
            <BlockDuration />
          </PropertiesLabel>
        )}

        {usesLoadDurations(shapeType) && <LoadDurations />}
      </>

      {storageType === StorageTypeType.RACK && <RackProperties />}
      {storageType === StorageTypeType.TWOSIDEDRACK && <TwoSidedRackProperties />}
      {storageType === StorageTypeType.SIDELOADING && <SideLoadingProperties />}
      {containsLoadCarrierSupport && storageType === StorageTypeType.SINGLE && <LoadElevation />}
    </Stack>
  );
};

const AreaProperty = memo(AreaPropertyComponent);
export default AreaProperty;
