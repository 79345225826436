import { useRecoilCallback } from "recoil";

import { enabledVehicleIdsState, useVehicleDependencyManager } from '@/modules/vehicles';
import { enabledLoadCarrierTypesIdsState } from '@/store/recoil/loadCarrierTypes';
import {
  LOAD_CARRIERS_CARRIABLE_BY_SHUTTLES_IDS,
  SHUTTLE_LOAD_CARRIERS_IDS,
  SHUTTLE_VEHICLE_NAMES,
  VEHICLES_ABLE_TO_CARRY_SHUTTLES_NAMES,
} from '@/modules/vehicles/constants';

export const useResolveShuttleTemplateDependencies = () => {
  const { ensureVehicleAssetAvailability, ensureVehicleDetailsAvailability } =
    useVehicleDependencyManager();

  /**
   * @description
   * checks that the required vehicles are enabled in the floorplan. If not, enables them and ensures their asset availablity.
   *
   * @returns the vehicleTypeId to use for shuttleRack template generation, and the vehicles that have additionally been enabled
   */
  const resolveVehicleDeps = useRecoilCallback(
    ({ snapshot, set }) =>
      async (): Promise<{
        vehicleTypeId: string;
        resolvedDeps: {
          shuttleCarryingVehicleId: string | null;
          shuttleVehicleId: string | null;
        };
      }> => {
        const enabledVehicleIds = await snapshot.getPromise(enabledVehicleIdsState);

        const missingDeps: {
          shuttleVehicleId: string | null;
          shuttleCarryingVehicleId: string | null;
        } = {
          shuttleVehicleId: null,
          shuttleCarryingVehicleId: null,
        };

        const enabledShuttleCarryingVehicleId = enabledVehicleIds.find((id) =>
          VEHICLES_ABLE_TO_CARRY_SHUTTLES_NAMES.includes(id),
        );
        const enabledShuttleVehicle = enabledVehicleIds.find((id) =>
          SHUTTLE_VEHICLE_NAMES.includes(id),
        );

        if (!enabledShuttleCarryingVehicleId) {
          const [vehicleId] = VEHICLES_ABLE_TO_CARRY_SHUTTLES_NAMES;
          missingDeps.shuttleCarryingVehicleId = vehicleId; // default RAE truck to enable
        }
        if (!enabledShuttleVehicle) {
          const [shuttleId] = SHUTTLE_VEHICLE_NAMES;
          missingDeps.shuttleVehicleId = shuttleId; // default shuttle to enable
        }

        if (missingDeps.shuttleCarryingVehicleId || missingDeps.shuttleVehicleId) {
          const newEnabledVehicleIds = [...enabledVehicleIds];

          const { shuttleCarryingVehicleId, shuttleVehicleId } = missingDeps;

          if (shuttleCarryingVehicleId) newEnabledVehicleIds.push(shuttleCarryingVehicleId);
          if (shuttleVehicleId) newEnabledVehicleIds.push(shuttleVehicleId);

          await ensureVehicleAssetAvailability(newEnabledVehicleIds);
          await ensureVehicleDetailsAvailability(newEnabledVehicleIds);
          set(enabledVehicleIdsState, newEnabledVehicleIds);
        }

        return {
          vehicleTypeId: (enabledShuttleCarryingVehicleId ||
            missingDeps.shuttleCarryingVehicleId) as string,
          resolvedDeps: {
            shuttleCarryingVehicleId: missingDeps.shuttleCarryingVehicleId,
            shuttleVehicleId: missingDeps.shuttleVehicleId,
          },
        };
      },
    [],
  );

  /**
   * @description
   * checks that the required load carriers are enabled in the floorplan. If not, enables them
   *
   * @returns the loadCarrierId to use for shuttleRack template generation, and the load carriers that have additionally been enabled
   */
  const resolveLoadCarrierDeps = useRecoilCallback(
    ({ snapshot, set }) =>
      async (): Promise<{
        loadCarrierId: string;
        resolvedDeps: {
          shuttleLoadCarrierId: string | null;
          shuttleCompatibleLoadCarrierId: string | null;
        };
      }> => {
        const enabledLoadCarrierIds = await snapshot.getPromise(enabledLoadCarrierTypesIdsState);
        const missingDeps: {
          shuttleCompatibleLoadCarrierId: string | null;
          shuttleLoadCarrierId: string | null;
        } = {
          shuttleCompatibleLoadCarrierId: null,
          shuttleLoadCarrierId: null,
        };

        const enabledShuttleCompatibleLoadCarrier = enabledLoadCarrierIds.find((id) =>
          LOAD_CARRIERS_CARRIABLE_BY_SHUTTLES_IDS.includes(id),
        );
        const enabledShuttleLoadCarrier = enabledLoadCarrierIds.find((id) =>
          SHUTTLE_LOAD_CARRIERS_IDS.includes(id),
        );

        if (!enabledShuttleCompatibleLoadCarrier) {
          const [defaultLoadCarrierId] = LOAD_CARRIERS_CARRIABLE_BY_SHUTTLES_IDS;
          missingDeps.shuttleCompatibleLoadCarrierId = defaultLoadCarrierId; // default shuttle compatible load to enable
        }
        if (!enabledShuttleLoadCarrier) {
          const [loadCarrier] = SHUTTLE_LOAD_CARRIERS_IDS;
          missingDeps.shuttleLoadCarrierId = loadCarrier; // default shuttle load carrier to enable
        }

        if (missingDeps.shuttleLoadCarrierId || missingDeps.shuttleCompatibleLoadCarrierId) {
          const newValue = [...enabledLoadCarrierIds];
          const { shuttleLoadCarrierId, shuttleCompatibleLoadCarrierId } = missingDeps;

          if (shuttleLoadCarrierId) newValue.push(shuttleLoadCarrierId);
          if (shuttleCompatibleLoadCarrierId) newValue.push(shuttleCompatibleLoadCarrierId);

          set(enabledLoadCarrierTypesIdsState, newValue);
        }

        return {
          loadCarrierId: (enabledShuttleCompatibleLoadCarrier ||
            missingDeps.shuttleCompatibleLoadCarrierId) as string,
          resolvedDeps: {
            shuttleLoadCarrierId: missingDeps.shuttleLoadCarrierId,
            shuttleCompatibleLoadCarrierId: missingDeps.shuttleCompatibleLoadCarrierId,
          },
        };
      },
    [],
  );

  return {
    resolveVehicleDeps,
    resolveLoadCarrierDeps,
  };
};
