import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import {
  RACK_DISTRIBUTION_OVERRIDE,
  RACK_MIN_GAP_OVERRIDE,
} from '@/modules/common/constants/storage';
import { AreaDirection, AreaLoadCarrierOrientation } from '@/modules/common/types/shapes';
import { StorageType } from '@/modules/common/types/storage';
import { AreaShapeParameters } from '@/store/recoil/shape';
import { LoadHandlingType, StationaryTypeEnum, TwinConfiguration, VehiclePosition } from './types';
import {
  alignmentAdjustment,
  alignmentToFPAlignment,
  blockStorageLength,
  getRackNameFromShapeId,
  getTwoSidedRackTwinConfig,
  loadCarrierTypesToFPLoadTypeReferences,
  sideLoadingDelta,
  storageAlignment,
} from './utils';

export const addEndPointGenerationSettings = (
  vehicleSpec,
  id,
  shape,
  epIdGeneratorName: string,
  lpIdGeneratorName: string,
  secondLpIdGeneratorName: string | null,
  areaType: LoadHandlingType | StationaryTypeEnum,
  discType,
  vehicleSize,
  supportedLoadCarrierTypes,
  loadPositionWidth: number,
  loadPositionLength: number,
  loadPositionAngle,
  direction,
  endPointAngle,
  applyLoadCarrierToLoadPositionRegexes: AreaShapeParameters['applyLoadCarrierToLoadPositionRegexes'],
  loadDuration,
  unloadDuration,
  blockDuration,
) => {
  const { parameters } = shape;

  const alignment =
    parameters.storageType === StorageType.BLOCKSTACKING
      ? storageAlignment(direction)
      : parameters.alignment;

  const distributionStrategy =
    parameters.distribution !== undefined ? parameters.distribution : 'EXTRA_SPACE_OVER_MARGIN';

  let restSettings = {};

  if (loadDuration === 0) loadDuration = null;
  if (unloadDuration === 0) unloadDuration = null;

  if (
    areaType === StationaryTypeEnum.CHARGING ||
    areaType === StationaryTypeEnum.PARKING ||
    areaType === StationaryTypeEnum.INSERTION ||
    areaType === StationaryTypeEnum.EXTRACTION
  ) {
    restSettings = {
      type: areaType,
      laneLength: vehicleSize.length,
      laneWidth: vehicleSize.width,
      blockDuration,
    };
  } else if (parameters.storageType === StorageType.BLOCKSTACKING) {
    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGeneratorName,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(
        supportedLoadCarrierTypes,
        applyLoadCarrierToLoadPositionRegexes,
      ),
      forceFallBack: null,
      loadPositionWidth:
        parameters.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
          ? loadPositionWidth
          : loadPositionLength,
      loadPositionLength: blockStorageLength(
        shape.properties,
        direction,
        parameters.loadCarrierOrientation,
        vehicleSize.length,
        loadPositionWidth,
        loadPositionLength,
      ),
      loadPositionAngle: 0,
      loadDuration,
      unloadDuration,
    };
  } else if (parameters.storageType === StorageType.SIDELOADING) {
    const centerDelta = sideLoadingDelta(
      direction,
      new Vector2(
        parameters.storageProperty.sideLoadingDeltaX,
        parameters.storageProperty.sideLoadingDeltaY,
      ),
    );

    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGeneratorName,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(
        supportedLoadCarrierTypes,
        applyLoadCarrierToLoadPositionRegexes,
      ),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      centerDeltaX: centerDelta.x,
      centerDeltaY: centerDelta.y,
      loadDuration,
      unloadDuration,
    };
  } else if (
    parameters.storageType === StorageType.RACK ||
    parameters.storageType === StorageType.TWOSIDEDRACK ||
    parameters.storageType === StorageType.SHUTTLERACK
  ) {
    const otherSide = direction === AreaDirection.DOWN || direction === AreaDirection.RIGHT;
    let twinConfig =
      parameters.storageType === StorageType.TWOSIDEDRACK
        ? getTwoSidedRackTwinConfig(parameters.storageProperty.laneDirectionInAisle)
        : otherSide
        ? TwinConfiguration.ONE_WAY_KEEP_SECOND
        : TwinConfiguration.ONE_WAY_KEEP_FIRST;

    restSettings = {
      rackSpecId: getRackNameFromShapeId(shape.id),
      name: `${id}-epGenSettings`,
      aisleWidth: parameters.storageProperty.aisleWidth,
      laneLength: 500,
      vehiclePosition: VehiclePosition.PERPENDICULAR,
      offsetDriving: 0,
      otherSide,
      twinConfig,
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGeneratorName,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(
        supportedLoadCarrierTypes,
        applyLoadCarrierToLoadPositionRegexes,
      ),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      minGap: RACK_MIN_GAP_OVERRIDE,
      distributionStrategy: RACK_DISTRIBUTION_OVERRIDE,
      alignment: alignmentToFPAlignment(alignment),
      secondLoadPositionIdGeneratorBk: secondLpIdGeneratorName,
      laneDirectionInAisle: parameters.storageProperty.laneDirectionInAisle,
      loadDuration,
      unloadDuration,
    };
  } else {
    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGeneratorName,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(
        supportedLoadCarrierTypes,
        applyLoadCarrierToLoadPositionRegexes,
      ),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      loadPositionZ: parameters.loadElevation === 0 ? null : parameters.loadElevation,
      centerDeltaX: 0,
      centerDeltaY: 0,
      loadDuration,
      unloadDuration,
    };
  }

  if (areaType === LoadHandlingType.PROCESS) {
    restSettings = { ...restSettings, processingCapacity: 1, loadDuration, unloadDuration };
  }

  vehicleSpec.endPointGenerationSettings.push({
    properties: shape.properties,
    data: {
      name: uuid(),
      epIdGeneratorBk: epIdGeneratorName,
      commsIdGeneratorBk: 'PointIdGen',
      idShortenerBk: 'IdShort',
      angle: endPointAngle,
      margin: parameters.margin,
      minGap: parameters.gap,
      alignment: alignmentToFPAlignment(
        alignmentAdjustment(shape.properties.width, shape.properties.height, direction, alignment),
      ),
      distributionStrategy,
      discType,
      areaReference: {
        name: id,
      },
      epCutOutReferences: [],
      ...restSettings,
    },
  });
};
