import { BoundingBox } from '@/helpers/types';
import { WideLineSegment } from '@/modules/common/types/general';
import { wideLineSegmentToOrientedBoundingBox } from '@/modules/common/helpers/boundingBox';
import { isPosition } from '@/modules/common/helpers/shapes';
import {
  LoadCarrierType,
  ObstacleShapePersisted,
  WallShapePersisted,
} from '@/modules/common/types/floorPlan';
import { ShapeType } from '@/modules/common/types/shapes';
import { StorageType as StorageTypeType } from '@modules/common/types/storage';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { addEndPointGenerationSettings } from './addEndPointGenerationSettings';
import {
  addDefaultIdGenerator,
  addDefaultMultiIdGenerator,
  addUniqueIdGenerator,
} from './addIdGenerators';
import {
  createMultiSpecSharedLpIdGeneratorName,
  encodeIdAsShared,
  encodeIdWithVehicleId,
} from './idEncoder';
import {
  areaStackingMode,
  areaTypeToFPAreaType,
  boundingBoxToFpsRectangle,
  calcFpsLoadPositionAngle,
  endPointDiscType,
  fpsEndPointAngle,
  getLoadHandlingSettings,
  lineToFpsRectangle,
  positionTypeToFixedEndPointGenerationType,
} from './utils';
import { VehicleSpec } from '@/modules/floorplanService/helpers/mapping/types';
import { IdGeneratorMap } from '../../types';

export const createObstacle = (
  obstacles,
  cutOutReferences,
  shape: ObstacleShapePersisted,
  workspaceBoundingBox: BoundingBox,
  material,
) => {
  const rectangle = boundingBoxToFpsRectangle(
    shape.properties,
    shape.properties.r,
    workspaceBoundingBox,
  );
  obstacles.push({
    name: shape.id,
    material,
    rectangle,
  });
  cutOutReferences.push({ name: shape.id, properties: shape.properties });
};

export const createWall = (
  obstacles,
  cutOutReferences,
  shape: WallShapePersisted,
  workspaceBoundingBox: BoundingBox,
  material,
) => {
  const { properties, parameters } = shape;
  const numberOfSegments = properties.controlPoints.length - 1;
  for (let i = 0; i < numberOfSegments; i++) {
    const line: WideLineSegment = {
      points: {
        start: properties.controlPoints[i].position,
        end: properties.controlPoints[i + 1].position,
      },
      width: parameters.width,
    };
    const name = `${shape.id}.${i}`;
    const rectangle = lineToFpsRectangle(line, workspaceBoundingBox);
    obstacles.push({
      name,
      material,
      rectangle,
    });
    cutOutReferences.push({ name, properties: wideLineSegmentToOrientedBoundingBox(line) });
  }
};

export const createArea = (
  vehicleSpec: VehicleSpec,
  idGenerators,
  shape,
  vehicleSize,
  loadTypes: LoadCarrierType[],
  workspaceBoundingBox: BoundingBox,
  customIdGeneratorMap: IdGeneratorMap,
) => {
  const shapeName = encodeIdWithVehicleId(shape.name, vehicleSpec.databaseId);
  const lpIdGeneratorName = shape.parameters.loadPositionIdGeneratorName || `${shapeName}-LpIdGen`;
  const secondLpIdGeneratorName =
    shape.parameters.secondLoadPositionIdGeneratorName || `2_${shapeName}-LpIdGen`;
  const epIdGeneratorName = shape.parameters.endPointIdGeneratorName || `${shapeName}-EpIdGen`;
  const customLpIdGenerator = customIdGeneratorMap.get(lpIdGeneratorName);
  const customSecondLpIdGenerator = customIdGeneratorMap.get(secondLpIdGeneratorName);
  const customEpIdGenerator = customIdGeneratorMap.get(epIdGeneratorName);
  const shapeId = encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId);
  const vehicleSpecSharedLpIdGeneratorName =
    !customLpIdGenerator && shape.parameters.supportedVehicleIds.length > 1
      ? createMultiSpecSharedLpIdGeneratorName(shape.name)
      : undefined;

  const { supportedLoadCarriersIds, loadCarrierOrientation, storageType } = shape.parameters;
  const { direction } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const { loadDuration, unloadDuration, blockDuration, applyLoadCarrierToLoadPositionRegexes } =
    shape.parameters;
  const { width: loadPositionWidth, length: loadPositionLength } =
    calcOrientedLoadCarriersBoundingBox(supportedLoadCarrierTypes);

  const endpointDirection =
    shape.parameters.storageProperty === undefined || shape.parameters.storageProperty === null
      ? null
      : shape.parameters.storageProperty.endpointDirection;

  let { type } = shape;
  if (isPosition(shape.type)) type = positionTypeToFixedEndPointGenerationType(type).toLowerCase();

  // NOTE: if rectangle longest side is along y-axis, make rectangle reflect it by adding 90 degrees to its angle property
  const rectangle = boundingBoxToFpsRectangle(
    shape.properties,
    shape.properties.r,
    workspaceBoundingBox,
  );
  const isHorizontal = shape.properties.width >= shape.properties.height;

  const stackingMode = areaStackingMode(
    areaTypeToFPAreaType(shape.type),
    shape.properties,
    direction,
    endpointDirection,
    shape.parameters.storageType,
  );

  addVehicleArea(vehicleSpec, shapeId, shape.type, rectangle, stackingMode);

  let loadPositionAngleRelativeToEndPoint = calcFpsLoadPositionAngle(
    loadCarrierOrientation,
  );

  const endPointAngleRelativeToArea = fpsEndPointAngle(direction, isHorizontal);

  const loadHandlingSettings = getLoadHandlingSettings(
    shape.type,
    shape.parameters.loadAction || undefined,
    shape.parameters.loadPlacement || undefined,
  );

  addEndPointGenerationSettings(
    vehicleSpec,
    shapeId,
    shape,
    epIdGeneratorName,
    vehicleSpecSharedLpIdGeneratorName || lpIdGeneratorName,
    secondLpIdGeneratorName,
    loadHandlingSettings,
    endPointDiscType(shape.type, shape.parameters.storageType),
    vehicleSize,
    supportedLoadCarrierTypes,
    loadPositionWidth,
    loadPositionLength,
    loadPositionAngleRelativeToEndPoint,
    direction,
    endPointAngleRelativeToArea,
    applyLoadCarrierToLoadPositionRegexes,
    loadDuration,
    unloadDuration,
    blockDuration,
  );

  if (customEpIdGenerator) {
    idGenerators.push(customEpIdGenerator);
  } else {
    addDefaultIdGenerator(idGenerators, epIdGeneratorName, shapeName);
  }

  if (
    type !== ShapeType.CHARGING &&
    type !== ShapeType.PARKING &&
    type !== ShapeType.CHARGING_POSITION &&
    type !== ShapeType.PARKING_POSITION
  ) {
    if (storageType === StorageTypeType.RACK) {
      if (customLpIdGenerator) {
        idGenerators.push(customLpIdGenerator);
      } else if (vehicleSpecSharedLpIdGeneratorName) {
        addUniqueIdGenerator(
          idGenerators,
          vehicleSpecSharedLpIdGeneratorName,
          encodeIdAsShared(shape.name),
        );
      } else {
        addDefaultIdGenerator(idGenerators, lpIdGeneratorName, shapeName);
      }
    } else if (storageType === StorageTypeType.TWOSIDEDRACK) {
      if (customLpIdGenerator) {
        idGenerators.push(customLpIdGenerator);
      } else if (vehicleSpecSharedLpIdGeneratorName) {
        addUniqueIdGenerator(
          idGenerators,
          vehicleSpecSharedLpIdGeneratorName,
          encodeIdAsShared(shape.name),
        );
      } else {
        addDefaultMultiIdGenerator(idGenerators, lpIdGeneratorName, shapeName, 'S01');
      }
      if (customSecondLpIdGenerator) {
        idGenerators.push(customSecondLpIdGenerator);
      } else {
        addDefaultMultiIdGenerator(idGenerators, secondLpIdGeneratorName, `${shapeName}`, 'S02');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (customLpIdGenerator) {
        idGenerators.push(customLpIdGenerator);
      } else if (vehicleSpecSharedLpIdGeneratorName) {
        addUniqueIdGenerator(
          idGenerators,
          vehicleSpecSharedLpIdGeneratorName,
          encodeIdAsShared(shape.name),
        );
      } else {
        addDefaultIdGenerator(idGenerators, lpIdGeneratorName, shapeName);
      }
    }
  }

  if (
    shape.parameters.storageType === StorageTypeType.RACK ||
    shape.parameters.storageType === StorageTypeType.TWOSIDEDRACK
  ) {
    // TODO skip end point neighbor mapping when vehicle position is perpendicular
    // addEndPointNeighborsMappingWithinArea(vehicleSpec.gateMappingSettings.areaMappings, shapeId);
  }
};
