import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import Konva from 'konva';

import { toCanvasX, toCanvasY } from '@/helpers/draw';
import { theme } from '@/modules/common/components/theme';
import { numberOfColumns } from '@/modules/common/helpers/loadCarrier';
import { InvalidShape } from '@/modules/workspace/components/InvalidShape';
import {
  AUTOSCALE_STROKE_DEFAULT,
  AUTOSCALE_STROKE_MAX,
  AUTOSCALE_STROKE_MIN,
  AUTOSCALE_STROKE_NAME,
} from '@/modules/workspace/helpers/konva';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { useColors } from '@/modules/workspace/hooks';
import { useAutoScale } from '@/modules/workspace/hooks/useAutoScale';
import { SideLoadingProperty } from '@/store/recoil/shape/types/area';
import { Bubble } from '@common/components/Bubble';
import {
  AreaAlignment,
  AreaDirection,
  AreaDistribution,
  AreaEndpointDirection,
  AreaLoadCarrierOrientation,
  AreaError,
  ShapeType,
} from '@modules/common/types/shapes';
import { StorageType } from '@modules/common/types/storage';
import { orientedLoadCarriersBoundingBox } from '@recoil/loadCarrierTypes';
import { ShapeProperties, areaParameters } from '@recoil/shape';
import {
  isFlowlessAreaSelector,
  isNotOrIncorrectlyConnectedAreaSelector,
} from '../../../modules/floorplanValidation/clientSide';
import { AREA_RENDER_ERROR } from '../../../modules/workspace/types/errors';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { LOADING_ANIMATION_MIN_OPACITY, useLoadingAnimation } from './useLoadingAnimation';
import { useError } from './useError';
import HazardStripes from '@/components/Workspace/Markings/HazardStripes';

export type AreaRenderProps = {
  shapeId: string;
  type: ShapeType;
  isDrawing: boolean;
  isTransforming: boolean;
  isLoading: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  r: number;
};

type Box = {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

type WarningBubble = {
  x: number;
  y: number;
  angle: number;
  message?: string;
};

const strokeCustomAttr = {
  [AUTOSCALE_STROKE_MAX]: 10.0,
  [AUTOSCALE_STROKE_MIN]: 1.0,
  [AUTOSCALE_STROKE_DEFAULT]: 2.0,
};

const AreaRenderComponent: React.FC<AreaRenderProps> = ({
  shapeId,
  type,
  isDrawing,
  isTransforming,
  isLoading,
  x: shapeX,
  y: shapeY,
  width: shapeWidth,
  height: shapeHeight,
  r: shapeR,
}) => {
  const params = useRecoilValue(areaParameters(shapeId));
  useAutoScale();

  // Get total supported vehicles footprint
  const vehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(params.supportedVehicleIds || []),
  );
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(params.supportedVehicleIds || []),
  );
  const { laneColor, loadBoxColor, shapeColor } = useColors(shapeId);
  const orientedLoadsBoundingBox = useRecoilValue(orientedLoadCarriersBoundingBox(shapeId));
  const isFlowLessAreas = useRecoilValue(isFlowlessAreaSelector(shapeId));
  const isNotOrIncorrectlyConnected = useRecoilValue(
    isNotOrIncorrectlyConnectedAreaSelector(shapeId),
  );

  // memoized load-carriers-bounding-box to use in dependency arrays. Workaround for recoil currently not supporting memoized selectorFamily return values.
  const loadCarriersBoundingBox = useMemo(
    () => orientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientedLoadsBoundingBox.length, orientedLoadsBoundingBox.width],
  );
  const currentError = useError(shapeId);

  const renderApproximations = useMemo(
    () =>
      !isTransforming &&
      type !== ShapeType.MANUAL &&
      (isDrawing || isLoading || currentError === AreaError.PartialGenerationFailed),
    [isTransforming, isDrawing, isLoading, currentError, type],
  );

  const renderErrorShape = useMemo(
    () => currentError && currentError !== AreaError.PartialGenerationFailed,
    [currentError],
  );

  // local state
  const [vehicleBoxes, setVehicleBoxes] = useState<Box[]>([]);
  const [boxes, setBoxes] = useState<Box[]>([]);
  const lastProperties = useRef<Partial<ShapeProperties>>({});
  const [warningBubble, setWarningBubble] = useState<WarningBubble | null>(null);

  const { t } = useTranslation(['errors']);

  useEffect(() => {
    if (!renderApproximations) {
      return;
    }

    const outLoad: Box[] = [];
    const renderOnlyVehicleBox =
      type === ShapeType.CHARGING ||
      type === ShapeType.PARKING ||
      type === ShapeType.MANUAL_ENTRY ||
      type === ShapeType.MANUAL_EXIT; // Charging Area: Render vehicle size in the load area (No rendering of loadCarriersBoundingBox)

    // Direction
    let { direction } = params;
    const isSideLoading = params.storageType === StorageType.SIDELOADING;
    const storageProperty = isSideLoading ? (params.storageProperty as SideLoadingProperty) : null;
    const isEpGenHorizontal = isSideLoading
      ? storageProperty?.endpointDirection === AreaEndpointDirection.HORIZONTAL
      : false;
    const isAreaVertical = isSideLoading ? isEpGenHorizontal : isVertical(direction);

    // Gap
    const gap = params.gap / 10;

    // Margin
    const margin = params.margin / 10;
    const marginMajorAxis = margin;
    let marginMinorAxis = 0;

    // Amount
    const length = isAreaVertical ? shapeWidth : shapeHeight;
    const width = isAreaVertical ? shapeHeight : shapeWidth;
    const loadWidth =
      params.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
        ? loadCarriersBoundingBox.width
        : loadCarriersBoundingBox.length;
    const loadLength =
      params.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
        ? loadCarriersBoundingBox.length
        : loadCarriersBoundingBox.width;
    const amount = numberOfColumns(
      length,
      gap,
      margin,
      renderOnlyVehicleBox ? vehicleWidth : loadWidth,
    );

    if (amount < 1 || amount === Infinity) {
      return;
    }

    let amountX = isAreaVertical ? amount : 1;
    let amountY = isAreaVertical ? 1 : amount;
    let boxWidth = 0;
    let boxHeight = 0;

    if (isSideLoading) {
      if (isVertical(direction)) {
        boxWidth = loadLength;
        boxHeight = loadWidth;
      } else {
        boxWidth = loadWidth;
        boxHeight = loadLength;
      }
    } else if (isAreaVertical) {
      boxWidth = renderOnlyVehicleBox ? vehicleWidth : loadWidth;
      boxHeight = renderOnlyVehicleBox ? vehicleLength : loadLength;
    } else {
      boxWidth = renderOnlyVehicleBox ? vehicleLength : loadLength;
      boxHeight = renderOnlyVehicleBox ? vehicleWidth : loadWidth;
    }

    // Alignment
    let axisMargin = width - (renderOnlyVehicleBox ? vehicleLength : loadLength);
    // prettier-ignore
    switch (params.alignment) {
      case AreaAlignment.CENTER: marginMinorAxis = axisMargin / 2; break;
      case AreaAlignment.BOTTOM: marginMinorAxis = axisMargin; break;
      default: break;
    }

    // Distribution
    let offset = 0;
    let extraGap = 0;
    let extraMargin = 0;
    const overflow =
      length -
      (renderOnlyVehicleBox ? vehicleWidth : loadWidth) * amount -
      gap * (amount - 1) -
      margin * 2;
    if (params.distribution === AreaDistribution.EXTRA_SPACE_OVER_MARGIN) {
      extraMargin = overflow / 2;
    } else if (params.distribution === AreaDistribution.EXTRA_SPACE_OVER_GAP) {
      if (amount > 1) {
        extraGap = overflow / (amount - 1);
      } else {
        offset = overflow / 2;
      }
    }

    // Building loadCarriersBoundingBoxes
    for (let j = 0; j < amountY; ++j) {
      for (let i = 0; i < amountX; ++i) {
        let x = 0;
        let y = 0;
        const constants = marginMajorAxis + extraMargin + offset;
        const increment = (renderOnlyVehicleBox ? vehicleWidth : loadWidth) + gap + extraGap;

        if (isAreaVertical) {
          x = i * increment + constants;
          y = marginMinorAxis + (j * width) / amountY;
        } else {
          x = marginMinorAxis + (i * width) / amountX;
          y = j * increment + constants;
        }

        const load = {
          id: `loadBox_${i}_${j}`,
          x,
          y,
          w: boxWidth,
          h: boxHeight,
        };
        outLoad.push(load);
      }
    }
    setBoxes(outLoad);

    lastProperties.current = {
      x: shapeX,
      y: shapeY,
      width: shapeWidth,
      height: shapeHeight,
      r: shapeR,
    };
  }, [
    renderApproximations,
    isDrawing,
    isTransforming,
    loadCarriersBoundingBox.length,
    loadCarriersBoundingBox.width,
    params,
    shapeHeight,
    shapeR,
    shapeWidth,
    shapeX,
    shapeY,
    type,
    vehicleLength,
    vehicleWidth,
  ]);

  useEffect(() => {
    if (!renderApproximations) {
      setVehicleBoxes([]);
      setBoxes([]);
    }
  }, [renderApproximations]);

  useEffect(() => {
    if (
      type === ShapeType.CHARGING ||
      type === ShapeType.PARKING ||
      type === ShapeType.MANUAL_ENTRY ||
      type === ShapeType.MANUAL_EXIT
    ) {
      return;
    }

    const outVehicle: Box[] = [];

    boxes.forEach((box) => {
      const areaDirection = params.direction;
      const id = `vehicleBox-${box.id}`;
      const isSideLoading = params.storageType === StorageType.SIDELOADING;

      if (isSideLoading) {
        const storageProperty = params.storageProperty as SideLoadingProperty;

        if (areaDirection === AreaDirection.DOWN) {
          const vehicle = {
            id,
            x: toCanvasX(box.x + box.w / 2, vehicleWidth) + storageProperty.sideLoadingDeltaX / 10,
            y: box.y - (vehicleLength - box.h) / 2 + storageProperty.sideLoadingDeltaY / 10,
            w: vehicleWidth,
            h: vehicleLength,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.UP) {
          const vehicle = {
            id,
            x: toCanvasX(box.x + box.w / 2, vehicleWidth) + storageProperty.sideLoadingDeltaX / 10,
            y: box.y - (vehicleLength - box.h) / 2 + storageProperty.sideLoadingDeltaY / 10,
            w: vehicleWidth,
            h: vehicleLength,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.RIGHT) {
          const vehicle = {
            id,
            x: box.x - (vehicleLength - box.w) / 2 + storageProperty.sideLoadingDeltaX / 10,
            y: toCanvasY(box.y + box.h / 2, vehicleWidth) + storageProperty.sideLoadingDeltaY / 10,
            w: vehicleLength,
            h: vehicleWidth,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.LEFT) {
          const vehicle = {
            id,
            x: box.x - (vehicleLength - box.w) / 2 + storageProperty.sideLoadingDeltaX / 10,
            y: toCanvasY(box.y + box.h / 2, vehicleWidth) + storageProperty.sideLoadingDeltaY / 10,
            w: vehicleLength,
            h: vehicleWidth,
          };
          outVehicle.push(vehicle);
        }
      } else {
        if (areaDirection === AreaDirection.DOWN) {
          const vehicle = {
            id,
            x: toCanvasX(box.x + box.w / 2, vehicleWidth),
            y: box.y,
            w: vehicleWidth,
            h: vehicleLength,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.UP) {
          const vehicle = {
            id,
            x: toCanvasX(box.x + box.w / 2, vehicleWidth),
            y: box.y - vehicleLength + box.h,
            w: vehicleWidth,
            h: vehicleLength,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.RIGHT) {
          const vehicle = {
            id,
            x: box.x,
            y: toCanvasY(box.y + box.h / 2, vehicleWidth),
            w: vehicleLength,
            h: vehicleWidth,
          };
          outVehicle.push(vehicle);
          return;
        }

        if (areaDirection === AreaDirection.LEFT) {
          const vehicle = {
            id,
            x: box.x - vehicleLength + box.w,
            y: toCanvasY(box.y + box.h / 2, vehicleWidth),
            w: vehicleLength,
            h: vehicleWidth,
          };
          outVehicle.push(vehicle);
        }
      }
    });

    setVehicleBoxes(outVehicle);
  }, [params, boxes, vehicleWidth, vehicleLength, type, params.direction]);

  useEffect(() => {
    if (!isNotOrIncorrectlyConnected) {
      setWarningBubble(null);
      return;
    }

    const areaDirection = params.direction;

    if (areaDirection === AreaDirection.DOWN) {
      setWarningBubble({
        x: shapeWidth / 2,
        y: shapeHeight,
        angle: 0,
      });
      return;
    }
    if (areaDirection === AreaDirection.UP) {
      setWarningBubble({
        x: shapeWidth / 2,
        y: 0,
        angle: 0,
      });
      return;
    }
    if (areaDirection === AreaDirection.RIGHT) {
      setWarningBubble({
        x: shapeWidth,
        y: shapeHeight / 2,
        angle: 90,
      });
      return;
    }
    if (areaDirection === AreaDirection.LEFT) {
      setWarningBubble({
        x: 0,
        y: shapeHeight / 2,
        angle: 90,
      });
    }
  }, [
    isNotOrIncorrectlyConnected,
    params.direction,
    shapeHeight,
    shapeId,
    shapeWidth,
    shapeX,
    shapeY,
  ]);

  const { loadingRefs } = useLoadingAnimation(isLoading);

  return (
    <Group x={shapeX} y={shapeY} rotation={shapeR}>
      {!renderErrorShape ? (
        <Rect
          width={shapeWidth}
          height={shapeHeight}
          fill={shapeColor}
          listening={false}
          strokeEnabled={false}
        />
      ) : shapeWidth !== 0 || shapeHeight !== 0 ? (
        <InvalidShape
          width={shapeWidth}
          height={shapeHeight}
          message={currentError ? t(AREA_RENDER_ERROR[currentError].i18nMessageKey) : undefined}
        />
      ) : null}

      {renderApproximations &&
        vehicleBoxes &&
        vehicleBoxes.map((v, index) => (
          <Rect
            ref={(node: Konva.Rect) => node && loadingRefs.current.set(`vehicle_${index}`, node)}
            key={v.id}
            x={v.x}
            y={v.y}
            width={v.w}
            height={v.h}
            fill={laneColor}
            opacity={isLoading ? LOADING_ANIMATION_MIN_OPACITY : 1}
            listening={false}
            strokeEnabled={false}
          />
        ))}

      {/* Don't render load boxes on Charging Area */}
      {renderApproximations &&
        boxes &&
        boxes.map((v, index) => (
          <Rect
            ref={(node: Konva.Rect) => node && loadingRefs.current.set(`load_${index}`, node)}
            key={v.id}
            x={v.x}
            y={v.y}
            width={v.w}
            height={v.h}
            fill={loadBoxColor}
            opacity={isLoading ? LOADING_ANIMATION_MIN_OPACITY : 1}
            listening={false}
            strokeEnabled={false}
          />
        ))}

      {/* Show dotted stroke if 1. has no flows 2. has no connection */}
      {(isFlowLessAreas || warningBubble) && (
        <Rect
          width={shapeWidth}
          height={shapeHeight}
          stroke={theme.palette.status.scheduled}
          listening={false}
          shadowForStrokeEnabled={false}
          hitStrokeWidth={0}
          name={AUTOSCALE_STROKE_NAME}
          {...strokeCustomAttr}
        />
      )}

      {/* show warning bubble if it has no connection */}
      {warningBubble && (
        <Bubble
          x={warningBubble.x}
          y={warningBubble.y}
          rotation={warningBubble.angle}
          inChecked={false}
          outChecked={false}
        />
      )}
      {type === ShapeType.MANUAL && <HazardStripes width={shapeWidth} height={shapeHeight} />}
    </Group>
  );
};

export const AreaRender = memo(AreaRenderComponent);