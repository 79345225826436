import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { areaMargin } from '@components/PropertiesPanel/store/area';
import { CONTEXT, contextState } from '@recoil/input';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { selectedShapesState } from '@recoil/shapes/selected';
import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useUnit } from '@modules/common/hooks';
import { WorkspaceMode } from '@modules/common/types/general';
import { modeSelector } from '@modules/common/store/workspace';
import { useArtefacts } from '@/modules/artefacts';

function AreaMargin() {
  const { saveFloorPlan } = useFloorPlanState();
  const [margin, setMargin] = useRecoilState(areaMargin);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { toBaseUnit } = useUnit();
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const onMarginChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        setMargin(value);
        saveFloorPlan();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        updateArtefacts(selectedShapes.map((item) => item.id));

        switch (selectedShapes[0].type) {
          case ShapeType.INTAKE:
          case ShapeType.INTAKE_POSITION:
            updateUserPreference(UserPreferenceName.INTAKE_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.DELIVERY:
          case ShapeType.DELIVERY_POSITION:
            updateUserPreference(UserPreferenceName.DELIVERY_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.STORAGE:
          case ShapeType.STORAGE_POSITION:
            updateUserPreference(UserPreferenceName.STORAGE_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.CHARGING:
          case ShapeType.CHARGING_POSITION:
            updateUserPreference(UserPreferenceName.CHARGING_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.PARKING:
          case ShapeType.PARKING_POSITION:
            updateUserPreference(UserPreferenceName.PARKING_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.MANUAL_EXIT:
          case ShapeType.MANUAL_EXIT_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_EXIT_MARGIN, await toBaseUnit(value));
            break;
          case ShapeType.MANUAL_ENTRY:
          case ShapeType.MANUAL_ENTRY_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_ENTRY_MARGIN, await toBaseUnit(value));
            break;
          default:
        }
      },
    [setMargin, saveFloorPlan, updateUserPreference, toBaseUnit, updateArtefacts],
  );

  return (
    <NumberInput
      value={useRecoilValue(unitValueSelector(margin))}
      unit={t(`interface:settings.units.${unit}`, unit)}
      sx={{ width: '100%' }}
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onMarginChange}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
    />
  );
}

export default AreaMargin;
