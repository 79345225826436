import { useCallback, useMemo, useRef } from 'react';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { MultiSelectInput, getMultiSelectDisplayValue } from '@/modules/common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { enabledLoadCarrierTypesSelector } from '@/store/recoil/loadCarrierTypes';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { useDebugStore } from '@/modules/debug/store';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { areaLoadCarriersIds } from '../store/area';

const AMOUNT_BOUNDS = {
  MIN: 1,
  MAX: 1,
};

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};

export const LoadCarrierSelect = () => {
  const { t } = useTranslation();
  const mode = useRecoilValue(modeSelector);
  const enabledLoadCarriers = useRecoilValue(enabledLoadCarrierTypesSelector);
  const [selectedCarriersIds, setSelectedCarriersIds] = useRecoilState(areaLoadCarriersIds);
  const { saveFloorPlan } = useFloorPlanState();
  const { showSnackbar } = useSnackbarStore();
  const multiLoadCarrierSelectionEnabled = useDebugStore(
    (state) => state.multiLoadCarrierSelectionEnabled,
  );
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const selectedCarriersIdsRef = useRef(selectedCarriersIds);
  selectedCarriersIdsRef.current = selectedCarriersIds;

  const options = useMemo(
    () =>
      enabledLoadCarriers?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [enabledLoadCarriers],
  );

  const handleChange = useRecoilCallback(
    ({ snapshot }) =>
      async (newSelected: string[]) => {
        setSelectedCarriersIds(newSelected);
        saveFloorPlan();
        updateArtefacts(await snapshot.getPromise(selectedShapesIdsState));
      },
    [saveFloorPlan, setSelectedCarriersIds],
  );

  const onOptionClick = useCallback(
    (value: string) => {
      if (selectedCarriersIdsRef.current.includes(value)) {
        // Deselect
        const allowedToDeselect = selectedCarriersIdsRef.current.length > AMOUNT_BOUNDS.MIN;
        if (!allowedToDeselect) {
          showSnackbar(
            t(
              'interface:properties.area.load_carrier_select.not_allowed_to_deselect_carrier_below_amount',
              `Can not deselect load carrier. Minimum amount: ${AMOUNT_BOUNDS.MIN}`,
              { amount: AMOUNT_BOUNDS.MIN },
            ),
          );
          return;
        }

        handleChange(selectedCarriersIdsRef.current.filter((item) => item !== value));
      } else {
        // Select
        const shouldReplace = AMOUNT_BOUNDS.MAX === 1;

        if (shouldReplace && !multiLoadCarrierSelectionEnabled) {
          handleChange([value]);
          return;
        }

        const allowedToSelect =
          selectedCarriersIdsRef.current.length < AMOUNT_BOUNDS.MAX ||
          multiLoadCarrierSelectionEnabled;

        if (!allowedToSelect) {
          showSnackbar(
            t(
              'interface:properties.area.load_carrier_select.not_allowed_to_select_carrier_above_amount',
              `Can not select load carrier. Maximum amount: ${AMOUNT_BOUNDS.MAX}`,
              { amount: AMOUNT_BOUNDS.MAX },
            ),
          );
          return;
        }

        handleChange([...selectedCarriersIdsRef.current, value]);
      }
    },
    [handleChange, showSnackbar, t, multiLoadCarrierSelectionEnabled],
  );

  const displayValue = getMultiSelectDisplayValue(enabledLoadCarriers, selectedCarriersIds);

  return (
    <Stack sx={containerSx}>
      <MultiSelectInput
        selectedOptions={selectedCarriersIds}
        options={options}
        onItemClick={onOptionClick}
        displayValue={displayValue}
        disabled={mode !== WorkspaceMode.EDITABLE}
      />
    </Stack>
  );
};
