import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { areaBlockDuration } from '@/components/PropertiesPanel/store/area';
import { Input } from '@/modules/common/components/Input';
import { ShapeType } from '@/modules/common/types/shapes';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';
import { NumberInput } from '@/modules/common/components/inputs';

/* called "Wait Time"  in the FE, backend value is BlockDuration everywhere... */
function BlockDuration() {
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();
  const [blockDuration, setBlockDuration] = useRecoilState(areaBlockDuration);
  const { updateUserPreference } = useUpdateUserPreferences();

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const onChangeBlockDuration = useRecoilCallback(
    ({ snapshot }) =>
      async (newVal: number) => {
        setBlockDuration(newVal);
        save();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        switch (selectedShapes[0].type) {
          case ShapeType.MANUAL_ENTRY:
          case ShapeType.MANUAL_ENTRY_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_ENTRY_BLOCK_DURATION, newVal);
            break;
          case ShapeType.MANUAL_EXIT:
          case ShapeType.MANUAL_EXIT_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_EXIT_BLOCK_DURATION, newVal);
            break;
          default:
        }
      },
    [setBlockDuration, save],
  );

  return (
    <NumberInput
      value={blockDuration}
      unit='sec' /* called "Wait Time"  in */
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChangeBlockDuration}
      onFocus={onFocus}
      onBlur={onBlur}
      errorType='floating'
      dataType='integer'
      minValue={0}
    />
  );
}

export default BlockDuration;
