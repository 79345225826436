import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';

import { MultiSelectInput, getMultiSelectDisplayValue } from '@/modules/common/components/inputs';
import { unique } from '@modules/common/helpers/array';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import useConfirm from '@modules/common/components/useConfirm';
import { isAreaShapeParameters } from '@/modules/common/types/guards';
import { useAutoSave } from '@modules/floorplan';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useFlow } from '@modules/flows';
import { useShapeGroup } from '@modules/shapeGroups';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { enabledVehiclesSelector, SELECTABLE_VEHICLE_AMOUNT_PER_AREA } from '@/modules/vehicles';
import { areaVehicleIds } from '../store/area';
import { useArtefacts } from '@/modules/artefacts';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { useGetAllFlowNodesRelatedToFlowId } from '@/modules/flows/hooks/useGetAllVehicleIdsRelatedToFlowId';
import { AreaShapeParameters, shapeParameter } from '@/store/recoil/shape';
import { FlowNode } from '@/modules/flows/types';
import { ProcessTwoEPShapeParameters } from '@/modules/processTwoEndPoint';
import { useDebugStore } from '@/modules/debug/store';

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};

export const VehicleSelect = () => {
  const { t } = useTranslation();
  const mode = useRecoilValue(modeSelector);
  const enabledVehicles = useRecoilValue(enabledVehiclesSelector);
  const selectedVehicleIds = useRecoilValue(areaVehicleIds);
  const { getRelatedFlowIdsOfShapes } = useFlow();
  const { findShapeGroupId, getAllShapeIds } = useShapeGroup();
  const { showSnackbar } = useSnackbarStore();
  const { runPreValidation } = useRunPreValidation();
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const { save } = useAutoSave();
  const getAllFlowNodesRelatedToFlowId = useGetAllFlowNodesRelatedToFlowId();
  const { confirm, Dialog } = useConfirm();
  const multiVehiclePerAreaEnabled = useDebugStore((state) => state.multiVehiclePerAreaEnabled);
  const ref = useRef<HTMLDivElement | null>(null);
  const selectedVehicleIdsRef = useRef(selectedVehicleIds);
  selectedVehicleIdsRef.current = selectedVehicleIds;

  const options = useMemo(
    () =>
      enabledVehicles?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [enabledVehicles],
  );

  const setVehicleIds = useRecoilCallback(
    ({ set }) =>
      async (nodes: FlowNode[], vehicleIds: string[]) => {
        nodes.forEach(async (node) => {
          const { id, directionToNode } = node;
          set(
            shapeParameter(id),
            (
              current: AreaShapeParameters | ProcessTwoEPShapeParameters,
            ): AreaShapeParameters | ProcessTwoEPShapeParameters => {
              if (isAreaShapeParameters(current)) {
                return {
                  ...current,
                  supportedVehicleIds: vehicleIds,
                };
              }

              if (directionToNode === 'forward') {
                return {
                  ...current,
                  deliveryParameters: {
                    ...current.deliveryParameters,
                    supportedVehicleIds: vehicleIds,
                  },
                };
              }
              return {
                ...current,
                intakeParameters: {
                  ...current.intakeParameters,
                  supportedVehicleIds: vehicleIds,
                },
              };
            },
          );
        });
      },
    [],
  );

  const handleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newSelected: string[]) => {
        const selectedShapeIds = await snapshot.getPromise(selectedShapesIdsState);

        const groupIds = await Promise.all(selectedShapeIds.map((item) => findShapeGroupId(item)));
        const uniqueGroupIds = unique(groupIds.filter(Boolean));
        const shapeIdsInGroups = await getAllShapeIds(uniqueGroupIds);

        if (
          uniqueGroupIds.length > 1 ||
          (uniqueGroupIds.length === 1 && shapeIdsInGroups.size !== selectedShapeIds.length)
        ) {
          showSnackbar(
            t(
              'errors:grouping.change_vehicle_type',
              'Please ungroup shapes before changing vehicle types',
            ),
          );
          return;
        }

        const flows = await getRelatedFlowIdsOfShapes(selectedShapeIds);
        if (flows.length > 0) {
          const changeVehicleIds = await confirm(
            t(
              'interface:properties.area.supported_vehicle.change_vehicle_popup.label',
              'Change vehicle?',
            ),
            t(
              'interface:properties.area.supported_vehicle.change_vehicle_popup.message',
              'Do you want to change vehicle type for areas linked by flow?',
            ),
          );
          if (!changeVehicleIds) return;

          const flowNodes = await getAllFlowNodesRelatedToFlowId(selectedShapeIds[0]);
          await setVehicleIds(flowNodes, newSelected);
          await save();
          runPreValidation([
            PreValidationAspect.DISCONNECTED_AREA_IDS,
            PreValidationAspect.CONNECTION_LACKING_ROADS,
            PreValidationAspect.FLOWLESS_AREAS,
            PreValidationAspect.DISCONNECTED_FLOW_STOPS,
            PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
          ]);
          updateArtefacts(flowNodes.map((node) => node.id));
          return;
        }

        selectedShapeIds.forEach(async (id) => {
          set(
            shapeParameter(id),
            (current: AreaShapeParameters): AreaShapeParameters => ({
              ...current,
              supportedVehicleIds: newSelected,
            }),
          );
        });

        await save();
        runPreValidation([
          PreValidationAspect.DISCONNECTED_AREA_IDS,
          PreValidationAspect.CONNECTION_LACKING_ROADS,
          PreValidationAspect.FLOWLESS_AREAS,
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
        ]);
        updateArtefacts(selectedShapeIds);
      },
    [
      getAllShapeIds,
      getRelatedFlowIdsOfShapes,
      save,
      runPreValidation,
      updateArtefacts,
      findShapeGroupId,
      showSnackbar,
      t,
      confirm,
      getAllFlowNodesRelatedToFlowId,
      setVehicleIds,
    ],
  );

  const onOptionClick = useCallback(
    (value: string) => {
      if (selectedVehicleIdsRef.current.includes(value)) {
        // Deselect
        const allowedToDeselect =
          selectedVehicleIdsRef.current.length > SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MIN;
        if (!allowedToDeselect) {
          showSnackbar(
            t(
              'interface:properties.area.supported_vehicle.not_allowed_to_deselect_vehicle_below_amount',
              `Can not deselect vehicle. Minimum amount: ${SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MIN}`,
              { amount: SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MIN },
            ),
          );
          return;
        }

        handleChange(selectedVehicleIdsRef.current.filter((item) => item !== value));
      } else {
        // Select
        const shouldReplace = SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MAX === 1;

        if (shouldReplace && !multiVehiclePerAreaEnabled) {
          handleChange([value]);
          return;
        }

        const allowedToSelect =
          selectedVehicleIdsRef.current.length < SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MAX ||
          multiVehiclePerAreaEnabled;

        if (!allowedToSelect) {
          showSnackbar(
            t(
              'interface:properties.area.supported_vehicle.not_allowed_to_select_vehicle_above_amount',
              `Can not select vehicle. Maximum amount: ${SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MAX}`,
              { amount: SELECTABLE_VEHICLE_AMOUNT_PER_AREA.MAX },
            ),
          );
          return;
        }

        handleChange([...selectedVehicleIdsRef.current, value]);
      }
    },
    [handleChange, showSnackbar, t, multiVehiclePerAreaEnabled],
  );

  const displayValue = getMultiSelectDisplayValue(enabledVehicles, selectedVehicleIds);

  return (
    <>
      {/* 
            // @ts-expect-error strictNullChecks. Pls fix me */}
      <Dialog anchor={ref} offset={[0, 100]} />
      <Stack sx={containerSx} ref={ref}>
        <MultiSelectInput
          options={options}
          selectedOptions={selectedVehicleIds}
          onItemClick={onOptionClick}
          displayValue={displayValue}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </Stack>
    </>
  );
};
