import { Vector2 } from 'three';

import { GateArtifacts } from '@/modules/artefacts';
import {
  ProcessTwoEPShape,
  ProcessTwoEPShapeParameters,
} from '@/modules/processTwoEndPoint/types';
import { AngledHighwayShapeParameters } from '@modules/angledHighways';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import {
  AreaShape,
  AreaShapeParameters,
  HighwayShape,
  HighwayShapeParameters,
  ObstacleShape,
  ObstacleShapeParameters,
  PositionShape,
  PositionShapeParameters,
  WallShape,
  WallShapeParameters,
} from '@recoil/shape';

export enum AreaDirection {
  DOWN = 'down',
  UP = 'up',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum AreaAlignment {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum AreaDistribution {
  EXTRA_SPACE_OVER_MARGIN = 'EXTRA_SPACE_OVER_MARGIN',
  EXTRA_SPACE_OVER_GAP = 'EXTRA_SPACE_OVER_GAP',
  SPACE_BETWEEN = 'spacebetween',
  SPACE_AROUND = 'spacearound',
  SPACE_EVENLY = 'spaceevenly',
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export enum AreaLoadCarrierOrientation {
  SHORT_SIDE = 'short_side',
  LONG_SIDE = 'long_side',
}

export enum AreaEndpointDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum AreaLoadPlacement {
  ON = 'on_vehicle',
  OFF = 'off_vehicle',
}

export enum LaneDirection {
  LEFT_RIGHT = 'leftright',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum HighwayDirection {
  UP_DOWN = 'updown',
  LEFT_RIGHT = 'leftright',
}

export enum ShapeType {
  WALL = 'wall',
  INTAKE = 'intake',
  STORAGE = 'storage',
  DELIVERY = 'delivery',
  CHARGING = 'charging',
  PARKING = 'parking',
  MANUAL = 'manual',
  MANUAL_EXIT = 'manual_exit',
  MANUAL_ENTRY = 'manual_entry',
  PROCESS_ONE_EP = 'process',
  PROCESS_TWO_EP = 'process_two_end_point',
  // PROCESS_HANDOVER = 'process_handover',
  HANDOVER = 'handover',
  HIGHWAY = 'highway',
  HIGHWAY_ANGLED = 'highway_angled',
  NONE = 'none',
  OBSTACLE = 'obstacle',
  INTAKE_POSITION = 'intake_position',
  STORAGE_POSITION = 'storage_position',
  DELIVERY_POSITION = 'delivery_position',
  PROCESS_ONE_EP_POSITION = 'process_position',
  CHARGING_POSITION = 'charging_position',
  PARKING_POSITION = 'parking_position',
  MANUAL_POSITION = 'manual_position',
  MANUAL_EXIT_POSITION = 'manual_exit_position',
  MANUAL_ENTRY_POSITION = 'manual_entry_position',
}

export enum MaterialType {
  Brick = 'brick',
  Concrete = 'concrete',
  Stone = 'stone',
}

export enum ObstacleType {
  Pillar = 'pillar',
  Box = 'box',
  Warning_Zone = 'warning_zone',
}

export enum AreaParkingDirection {
  BACKWARD = 'backward',
  FORWARD = 'forward',
}

export enum AreaLoadAction {
  LOAD = 'load',
  UNLOAD = 'unload',
  LOAD_UNLOAD = 'load_unload',
}

export type AppSpecificShapeProperties = {
  isDrawing: boolean;
  isTransforming: boolean;
  isLoading: boolean;
  disabled: boolean;

  gates?: GateArtifacts[];
  error?: AreaError;
};

export type ShapeProperties = {
  x: number;
  y: number;
  width: number;
  height: number;
  r: number;
};

export type ShapeBase<
  TShapeType extends Exclude<ShapeType, ShapeType.NONE>,
  TParams extends DTShapeParameters,
  TProperties extends DTShapeProperties,
> = {
  id: string;
  name?: string;
  type: TShapeType;
  properties: TProperties;
  parameters: TParams;
} & AppSpecificShapeProperties;

export type DTShape =
  | AreaShape
  | PositionShape
  | ObstacleShape
  | WallShape
  | HighwayShape
  | AngledHighwayShape
  | ProcessTwoEPShape;

export type DTShapeParameters =
  | AreaShapeParameters
  | PositionShapeParameters
  | ObstacleShapeParameters
  | WallShapeParameters
  | HighwayShapeParameters
  | AngledHighwayShapeParameters
  | ProcessTwoEPShapeParameters;

export type DTShapeProperties = ShapeProperties | ControlPointsProperties;

export enum AreaError {
  AbsentLoadCarrier = 'ABSENT_LOAD_CARRIER',
  AreaToSmall = 'AREA_TOO_SMALL',
  AbsentVehicle = 'ABSENT_VEHICLE',
  PartialGenerationFailed = 'PARTIAL_GENERATION_FAILED',
}

export type ProcessAreaOneEp = Omit<AreaShape, 'type'> & { type: ShapeType.PROCESS_ONE_EP };

export type ActivePointsDrawingState = {
  id: null | string;
  type: ShapeType.HIGHWAY_ANGLED | ShapeType.WALL | ShapeType.PROCESS_TWO_EP,
  controlPoints: ControlPoint[];
  interimStartControlPoint: null | ControlPoint;
  interimEndPointPos: null | Vector2;
  width: number;
};

export type ControlPointsProperties = {
  controlPoints: ControlPoint[];
};

export type ControlPoint = {
  id: string;
  prev: string | null;
  next: string | null;
  position: Vector2;
};

export type ControlPointsObj = { [CPId: string]: ControlPoint };

export type PotentialControlPointType = {
  position: Vector2;
  prevCPId: string;
  nextCPId: string;
};
