import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Stack } from '@mui/system';

import { Accordion } from '@/modules/common/components/Accordion';
import { TrafficManagementToggle } from './TrafficManagementToggle';
import { FailOnNoRouteFoundToggle } from './FailOnNoRouteFoundToggle';
import { Divider } from '@mui/material';
import DownloadAndRunSimulationWithFMSConfig from './DownloadAndRunSimulationWithFMSConfig';
import { RunSimulationWithTransportOrders } from './RunSimulationWithTransportOrders';
import { ManualInterventionToggle } from './ManualInterventionToggle';
import { ObstructionTimeInSeconds } from './ObstructionTimeInSeconds';
import { OrderDistributionStrategy } from './OrderDistributionStrategy';

export const ExperimentalSettings = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Accordion
      title={t('interface:simulation.experimental_settings.header', 'Experimental settings')}
      expanded={open}
      onChange={(expanded: boolean) => setOpen(expanded)}
      sx={{ px: 2 }}
    >
      <Stack height='100%'>
        <Stack
          sx={{
            flex: '1 1 auto',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          gap={1}
        >
          <TrafficManagementToggle />
          <FailOnNoRouteFoundToggle />
          <ManualInterventionToggle />
          <ObstructionTimeInSeconds />
          <OrderDistributionStrategy />
          <Divider />
          <RunSimulationWithTransportOrders />
          <Divider />
          <DownloadAndRunSimulationWithFMSConfig />
        </Stack>
      </Stack>
    </Accordion>
  );
};
