import { isVertical } from '@/modules/workspace/helpers/shape';
import { isPosition } from '@modules/common/helpers/shapes';
import shapeAtom, {
  AreaShape,
  AreaShapeParameters,
  PositionShape,
  PositionShapeParameters,
  shapeParameter,
  shapeProperty,
} from '@recoil/shape';
import { AreaDirection, ShapeType } from '@modules/common/types/shapes';
import { isAreaShapeParameters } from '../../../modules/common/types/guards';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useUpdateDistantConnection } from '@modules/connections/distant';
import { areaDirection } from '../store/area';

export const useArea = () => {
  const direction = useRecoilValue(areaDirection);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { updateDistantConnections } = useUpdateDistantConnection();

  const setDirection = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newDirection: AreaDirection, shapeIds: string[]) => {
        const shapes = (await Promise.all(
          shapeIds.map((id) => snapshot.getPromise(shapeAtom(id))),
        )) as (AreaShape | PositionShape)[];

        shapes.forEach(({ id, type, properties, parameters }) => {
          const { direction } = parameters;
          const oldVertical = isVertical(direction);
          const newVertical = isVertical(newDirection);

          if (direction !== newDirection) {
            switch (type) {
              case ShapeType.INTAKE:
              case ShapeType.INTAKE_POSITION:
                updateUserPreference(UserPreferenceName.INTAKE_DIRECTION, direction);
                break;
              case ShapeType.DELIVERY:
              case ShapeType.DELIVERY_POSITION:
                updateUserPreference(UserPreferenceName.DELIVERY_DIRECTION, direction);
                break;
              case ShapeType.STORAGE:
              case ShapeType.STORAGE_POSITION:
                updateUserPreference(UserPreferenceName.STORAGE_DIRECTION, direction);
                break;
              case ShapeType.PROCESS_ONE_EP:
              case ShapeType.PROCESS_ONE_EP_POSITION:
                updateUserPreference(UserPreferenceName.PROCESS_DIRECTION, direction);
                break;
              case ShapeType.CHARGING:
              case ShapeType.CHARGING_POSITION:
                updateUserPreference(UserPreferenceName.CHARGING_DIRECTION, direction);
                break;
              case ShapeType.PARKING:
              case ShapeType.PARKING_POSITION:
                updateUserPreference(UserPreferenceName.PARKING_DIRECTION, direction);
                break;
              case ShapeType.MANUAL_EXIT:
              case ShapeType.MANUAL_EXIT_POSITION:
                updateUserPreference(UserPreferenceName.MANUAL_EXIT_DIRECTION, direction);
                break;
              case ShapeType.MANUAL_ENTRY:
              case ShapeType.MANUAL_ENTRY_POSITION:
                updateUserPreference(UserPreferenceName.MANUAL_ENTRY_DIRECTION, direction);
                break;
              default:
            }
          }

          const shouldRotatePosition = isPosition(type) && oldVertical !== newVertical;
          if (shouldRotatePosition) {
            set(shapeProperty(id), {
              ...properties,
              width: properties.height,
              height: properties.width,
            });
          }

          set(
            shapeParameter(id),
            (
              current: AreaShapeParameters | PositionShapeParameters,
            ): AreaShapeParameters | PositionShapeParameters => {
              if (isAreaShapeParameters(parameters)) {
                const gapHorizontal = oldVertical === newVertical ? parameters.gapHorizontal : 0;
                const gapVertical = oldVertical === newVertical ? parameters.gapVertical : 0;

                return {
                  ...current,
                  direction: newDirection,
                  gapHorizontal,
                  gapVertical,
                };
              }

              return {
                ...current,
                direction: newDirection,
              };
            },
          );
        });

        await updateDistantConnections(shapes.map((item) => item.id));
      },
    [updateUserPreference, updateDistantConnections],
  );

  return {
    direction,
    setDirection,
  };
};
