import { LayerNames } from '@/modules/common/types/layers';
import { layersShowSelector } from '@/modules/layers';
import { isArea, isDrawable, isPosition, isRoad } from '@modules/common/helpers/shapes';
import { ShapeType } from '@modules/common/types/shapes';
import { ToolState } from '@modules/common/types/tools';
import { TemplateType } from '@/modules/common/types/templating';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import workspaceAtom, { WorkspaceAtom } from '../workspace/atom';
import toolAtom, { ToolAtom } from './atom';
import { enabledVehicleIdsState } from '@/modules/vehicles';
import { enabledLoadCarrierTypesIdsState } from '../loadCarrierTypes';
import { TEMPLATE_GENERATION_PREREQUISITES } from '@/modules/templating/constants';
import { primitiveArraysHaveEqualContents } from '@/modules/common/helpers/array';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { t } from 'i18next';

const toolButtonState = selector<ToolAtom>({
  key: 'toolButtonState',
  get: ({ get }) => get(toolAtom),
  set: ({ set, get }, newValueIn) => {
    let newValue = newValueIn;
    const { showSnackbar } = useSnackbarStore.getState();
    // make sure the correct vehicles and load carriers are used
    if (newValue === TemplateType.LASSIE) {
      if (
        !primitiveArraysHaveEqualContents(get(enabledVehicleIdsState), [
          ...TEMPLATE_GENERATION_PREREQUISITES.VEHICLES.LASSIE,
        ]) ||
        !primitiveArraysHaveEqualContents(get(enabledLoadCarrierTypesIdsState), [
          ...TEMPLATE_GENERATION_PREREQUISITES.LOAD_CARRIERS.LASSIE,
        ])
      ) {
        showSnackbar(t('errors:template.required_vehicles_not_selected.lassie'));
        return;
      }

      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.PLACE,
          placingType: TemplateType.LASSIE,
        }),
      );
    }

    if (newValue === TemplateType.SHUTTLE_RACK) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.PLACE,
          placingType: TemplateType.SHUTTLE_RACK,
        }),
      );
    }

    // select cursor when user deselect any tool
    if (!newValue || newValue === 'cursor') {
      newValue = 'cursor';

      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.SELECT,
          placingType: null,
        }),
      );

      // pan tool
    } else if (newValue === ToolState.PAN) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.PAN,
          placingType: null,
        }),
      );

      // for drawing tool (area/intake/storage/delivery/highway/highway_angled...)
    } else if (isDrawable(newValue)) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: newValue as ShapeType,
          previousToolState: current.toolState,
          toolState: ToolState.DRAW,
          placingType: null,
        }),
      );

      if (isArea(newValue)) {
        set(layersShowSelector(LayerNames.AREAS), true);
      } else if (isRoad(newValue)) {
        set(layersShowSelector(LayerNames.HIGHWAYS), true);
      } else if (newValue === ShapeType.WALL) {
        set(layersShowSelector(LayerNames.WALLS), true);
      } else if (newValue === ShapeType.OBSTACLE) {
        set(layersShowSelector(LayerNames.OBSTACLES), true);
      }

      // for positions
    } else if (isPosition(newValue)) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: newValue as ShapeType,
          previousToolState: current.toolState,
          toolState: ToolState.PLACE,
          placingType: null,
        }),
      );

      set(layersShowSelector(LayerNames.AREAS), true);
    } else if (newValue === ToolState.FLOW) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.FLOW,
          placingType: null,
        }),
      );

      set(layersShowSelector(LayerNames.FLOWS), true);
    } else if (newValue === ToolState.DISTCON) {
      set(
        workspaceAtom,
        (current): WorkspaceAtom => ({
          ...current,
          previousShapeType: current.shapeType,
          shapeType: ShapeType.NONE,
          previousToolState: current.toolState,
          toolState: ToolState.DISTCON,
          placingType: null,
        }),
      );

      set(layersShowSelector(LayerNames.CONNECTIONS), true);
    }
    set(toolAtom, newValue);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default toolButtonState;
