import {
  AreaAlignment,
  AreaDirection,
  AreaDistribution,
  AreaEndpointDirection,
  AreaLoadCarrierOrientation,
  AreaLoadPlacement,
  AreaLoadAction,
  AreaParkingDirection,
  LaneDirection,
  ShapeBase,
  ShapeProperties,
  ShapeType,
} from '@modules/common/types/shapes';
import { StorageType } from '@modules/common/types/storage';
import { AreaOverflow } from '../../workspace';
import { VehiclePosition } from '@/modules/floorplanService/helpers/mapping/types';

export type RackProperty = {
  rackLevels: number;
  rackLoadsPerShelf: number;
  loadHeight: number;
  clearanceSide: number;
  clearanceTop: number;
  clearanceInBetween: number;
  clearanceDepth: number;
  beamHeight: number;
  framesDeep: number;
  postWidth: number;
  firstShelfHeight: number;
  aisleWidth: number;
  vehiclePositionInRack: VehiclePosition;
  offsetDrivingInRack: number;
  laneDirectionInAisle: LaneDirection;
  secondLoadPositionIdGeneratorName?: string;
  shelfHeights: number[];
  topShelfHeight: number | null;
};
export type SideLoadingProperty = {
  endpointDirection: AreaEndpointDirection;
  sideLoadingDeltaX: number;
  sideLoadingDeltaY: number;
};
export type StorageProperty = RackProperty | SideLoadingProperty;

export function isRackStorageProperty(obj: StorageProperty): obj is RackProperty {
  return (obj as RackProperty)?.rackLevels !== undefined;
}

export function isSideLoadingStorageProperty(
  obj: StorageProperty | undefined | null,
): obj is SideLoadingProperty {
  return (obj as SideLoadingProperty)?.sideLoadingDeltaX !== undefined;
}

export type AreaShapeParameters = {
  direction: AreaDirection;
  alignment: AreaAlignment;
  positionOverflow: AreaOverflow;
  distribution: AreaDistribution;
  gap: number;
  margin: number;
  gapHorizontal: number;
  gapVertical: number;
  multiDeep: boolean;
  supportedVehicleIds: string[];
  supportedLoadCarriersIds: string[];
  loadCarrierOrientation: AreaLoadCarrierOrientation | null;
  storageType: StorageType;
  storageProperty: StorageProperty | null;
  parkingDirection: AreaParkingDirection;
  loadElevation: number;
  operationTime: number | null;
  loadPlacement: AreaLoadPlacement | null;
  blockDuration: number | null;
  loadDuration: number | null;
  unloadDuration: number | null;
  endPointIdGeneratorName?: string;
  loadPositionIdGeneratorName?: string;
  secondLoadPositionIdGeneratorName?: string;
  applyLoadCarrierToLoadPositionRegexes: string[] | null;
  loadAction?: AreaLoadAction;
  priority?: number;
};

export type AreaShape = ShapeBase<
  | ShapeType.INTAKE
  | ShapeType.STORAGE
  | ShapeType.DELIVERY
  | ShapeType.CHARGING
  | ShapeType.PARKING
  | ShapeType.MANUAL
  | ShapeType.MANUAL_EXIT
  | ShapeType.MANUAL_ENTRY
  | ShapeType.PROCESS_ONE_EP
  | ShapeType.HANDOVER,
  AreaShapeParameters,
  ShapeProperties
>;
