// lowercase string container name in which the asset files will be stored by the file api.
// IMPORTANT: do not change, or the backend will break. Check PimApi's 'DownloadAssetAndUploadToFileApi' for more details
export const KM_MDB_ASSET_FILE_CONTAINER_DEFAULT = 'kmMdb';
export const UVT_ASSET_FILE_CONTAINER_DEFAULT = 'uvt';

export const FLOORPLAN_ASSET_FILE_CONTAINER = 'floorplan-asset';

export const SELECTABLE_VEHICLE_AMOUNT_PER_AREA = {
  MIN: 1,
  MAX: 1,
};

// used on floorplan level and shape level // id constructed based on vehicle name upon retrieval from pim api
export const DEFAULT_ENABLED_VEHICLE_TYPE_IDS = ['SAE160_1200_triplex_rear'];

// TODO: derive this this data from PIM core
export const LOAD_CARRIERS_CARRIABLE_BY_SHUTTLES_IDS = [
  'EPAL_1_ID',
  'EPAL_2_ID',
  'WP_ID',
  'WPASIA_ID',
  'P1208B_ID',
  'P1208A_ID',
];
export const SHUTTLE_LOAD_CARRIERS_IDS = ['RS150_800_ID', 'RS150_1000_ID', 'RS150_1200_ID'];
export const SHUTTLE_VEHICLE_NAMES = ['RS150_800', 'RS150_1000', 'RS150_1200'];
export const VEHICLES_ABLE_TO_CARRY_SHUTTLES_NAMES = [
  'RAE160_1200_triplexb_rear',
  'RAE250_1200_triplexc_rear',
];
