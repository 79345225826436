import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import { Theme } from '@modules/common/types/general';
import { FormControl, MenuItem, Select } from '@mui/material';

type Props = {
  name?: string;
  disabled?: boolean;
  options?: any[];
  value?: any;
  onChange?: (value: any) => void;
  multiple?: boolean;
};

export function SelectInput({
  disabled = false,
  name,
  options,
  value,
  onChange,
  multiple = false,
}: Props) {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Select
        name={name}
        disabled={disabled}
        disableUnderline
        variant='standard'
        multiple={multiple}
        value={value}
        // @ts-expect-error strictNullChecks. Pls fix me
        onChange={(e) => onChange(e.target.value)}
        MenuProps={{
          classes: { paper: theme === Theme.DARK ? 'dark' : '' },
        }}
      >
        {options?.map(({ label, value: optionValue, disabled: optionDisabled }) => (
          <MenuItem key={`${label}-${optionValue}`} value={optionValue} disabled={optionDisabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
