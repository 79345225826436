import { atom } from 'recoil';
import { ValidationState } from '../../types';

export const prevalidationState = atom<ValidationState>({
  key: 'prevalidation/results',
  default: {
    disconnectedAreaIds: [],
    connectionLackingRoadIds: [],
    missingRequiredElements: [],
    flowlessAreas: [],
    disconnectedFlowStopIds: [],
    incorrectlyConnectedShapeToRoadIds: [],
    invalidManualAreas: [],
  },
});
