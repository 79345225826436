import {
  createProcessRelatedFlowsMap,
  isArea,
  isRoad,
} from '@modules/common/helpers/shapes';
import { Connection, Crossing, DistantConnection } from '@modules/common/types/connections';
import { isAreaShape, isPositionShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { ShapeGroup } from '@modules/common/types/shapeGroup';
import { AreaDirection, DTShape, ProcessAreaOneEp, ShapeType } from '@modules/common/types/shapes';
import { StorageType } from '@/modules/common/types/storage';
import { FlowStop, FlowStopType, LayoutFlow } from '@modules/flows/types';
import { AreaShape, HighwayShape, PositionShape } from '@recoil/shape';
import { UnifiedVehicle } from '@/modules/vehicles/types';
import { RequiredElementType, shapeDirectionToDegreesMap } from './constants';
import {
  MissingRequiredElement,
  RoadConnectionsCrossingsCollection,
  ShapeConsDistConsCollection,
} from './types';
import { decodeShapeId } from '@/modules/floorplanService/helpers/mapping/idEncoder';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { TemplateType } from '@/modules/common/types/templating';
import { SHUTTLE_VEHICLE_NAMES } from '@/modules/vehicles/constants';

export const areaDirectionsAllPointToARoadConnection = (
  directions: AreaDirection[],
  connectionsToRoads: Connection[],
) =>
  directions.every((dir) => {
    const dirDegrees = mapShapeDirectionToDegrees(dir);
    return connectionsToRoads.some((con) => con.rot === dirDegrees);
  });

export const areaDirectionIsPerpendicularToRoadConnection = (
  direction: AreaDirection,
  connectionsToRoads: Connection[],
) => {
  const dirDegrees = mapShapeDirectionToDegrees(direction);
  return connectionsToRoads.some((con) => Math.abs(degreeDelta(con.rot, dirDegrees)) === 90);
};

export const getProcessIdsWithUnequalInboundOutboundFlows = (
  processShapes: ProcessAreaOneEp[],
  processTwoEndPointShapes: ProcessTwoEPShape[],
  flows: LayoutFlow[],
) => {
  const processRelatedFlowsMap = createProcessRelatedFlowsMap(
    processShapes,
    processTwoEndPointShapes,
    flows,
  );
  const unequalProcessAreasIds: string[] = [];
  processRelatedFlowsMap.forEach((item) => {
    if (item.inbound.length !== item.outbound.length)
      unequalProcessAreasIds.push(item.processAreaId);
  });

  return unequalProcessAreasIds;
};

export const getProcessIdsWithUnbalancedInboundOutboundFlows = (
  processShapes: ProcessAreaOneEp[],
  processTwoEndPointShapes: ProcessTwoEPShape[],
  flows: LayoutFlow[],
) => {
  const processRelatedFlowsMap = createProcessRelatedFlowsMap(
    processShapes,
    processTwoEndPointShapes,
    flows,
  );
  const unbalancedProcessAreasIds: string[] = [];
  processRelatedFlowsMap.forEach((item) => {
    const sumInbound = item.inbound
      .map((flow) => flow.totalNumLoads)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const sumOutbound = item.outbound
      .map((flow) => flow.totalNumLoads)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (sumInbound !== sumOutbound) unbalancedProcessAreasIds.push(item.processAreaId);
  });

  return unbalancedProcessAreasIds;
};

const mapShapeDirectionToDegrees = (direction: AreaDirection) =>
  shapeDirectionToDegreesMap[direction];

const degreeDelta = (degree1: number, degree2: number) => ((degree2 - degree1 + 540) % 360) - 180;

export const isDeadEndRoad = (connectionAmount: number, crossingAmount: number) =>
  connectionAmount + crossingAmount < 2;

export const getMissingRequiredElements = (
  allShapes: DTShape[],
  vehicles: UnifiedVehicle[],
  flows: LayoutFlow[],
  groups: ShapeGroup[],
): MissingRequiredElement[] => {
  const missingRequiredElements: MissingRequiredElement[] = [];
  const floorplanContainsShuttleTemplate = groups.some(item => item.type === TemplateType.SHUTTLE_RACK);

  vehicles.forEach((vehicle) => {
    const intakeIds: string[] = [];
    const deliveryIds: string[] = [];
    const storageIds: string[] = [];
    const chargingIds: string[] = [];
    const shuttleRackIds: string[] = [];
    // const parkingIds = []; TODO: parkings are not yet supported
    const roadIds: string[] = [];
    const isShuttleVehicle = SHUTTLE_VEHICLE_NAMES.includes(vehicle.name);
    const vehicleShapes = allShapes.filter(
      (shape) =>
        (isRoad(shape.type) && !isShuttleVehicle) ||
        ((isAreaShape(shape) || isPositionShape(shape)) &&
          shape.parameters.supportedVehicleIds.includes(vehicle.id)) ||
        (isProcessAreaTwoEp(shape) &&
          (shape.parameters.deliveryParameters.supportedVehicleIds.includes(vehicle.id) ||
            shape.parameters.intakeParameters.supportedVehicleIds.includes(vehicle.id))),
    );
    const vehicleGroups = groups.filter(
      (group) =>
        isArea(group.type) &&
        vehicleShapes.map((shape) => shape.id).some((shapeId) => group.children.includes(shapeId)),
    );
    const vehicleFlows = flows.filter(
      (flow) =>
        (flow.intakeFlowStop.type === FlowStopType.AREA &&
          vehicleShapes.some((shape) => shape.id === flow.intakeFlowStop.id)) ||
        (flow.intakeFlowStop.type === FlowStopType.AREA_GROUP &&
          vehicleGroups.some((group) => group.id === flow.intakeFlowStop.id)) ||
        (flow.deliveryFlowStop.type === FlowStopType.PROCESS &&
          vehicleShapes.some((shape) => shape.id === flow.deliveryFlowStop.id)) ||
        (flow.intakeFlowStop.type === FlowStopType.PROCESS &&
          vehicleShapes.some((shape) => shape.id === flow.intakeFlowStop.id)),
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const shape of vehicleShapes) {
      switch (shape.type) {
        case ShapeType.INTAKE:
        case ShapeType.INTAKE_POSITION:
          intakeIds.push(shape.id);
          break;
        case ShapeType.DELIVERY_POSITION:
        case ShapeType.DELIVERY:
          deliveryIds.push(shape.id);
          break;
        case ShapeType.STORAGE:
        case ShapeType.STORAGE_POSITION:
          if (isAreaShape(shape) && shape.parameters.storageType === StorageType.SHUTTLERACK) {
            shuttleRackIds.push(shape.id);
          }

          storageIds.push(shape.id);
          break;
        case ShapeType.CHARGING_POSITION:
        case ShapeType.CHARGING:
          chargingIds.push(shape.id);
          break;
        // TODO: parkings are not yet supported
        // case ShapeType.PARKING:
        // case ShapeType.PARKING_POSITION:
        //   chargingIds.push(shape.id);
        //   break;
        case ShapeType.HIGHWAY:
        case ShapeType.HIGHWAY_ANGLED:
          roadIds.push(shape.id);
          break;
        default:
          break;
      }
    }

    if (vehicleFlows.length === 0 && !isShuttleVehicle && !floorplanContainsShuttleTemplate)
      missingRequiredElements.push({ element: RequiredElementType.FLOW, vehicle: vehicle.name });
    if (
      intakeIds.length === 0 &&
      deliveryIds.length === 0 &&
      storageIds.length === 0 &&
      !isShuttleVehicle
    )
      missingRequiredElements.push({ element: RequiredElementType.AREA, vehicle: vehicle.name });
    if (chargingIds.length === 0 && !isShuttleVehicle)
      missingRequiredElements.push({
        element: RequiredElementType.CHARGING,
        vehicle: vehicle.name,
      });
    if (shuttleRackIds.length === 0 && isShuttleVehicle)
      missingRequiredElements.push({ element: RequiredElementType.AREA, vehicle: vehicle.name });
    // if (parkingIds.length === 0) missingRequiredElements.push(RequiredElementType.PARKING); TODO: parkings are not yet supported
    if (roadIds.length === 0 && !isShuttleVehicle)
      missingRequiredElements.push({ element: RequiredElementType.ROAD, vehicle: vehicle.name });
  });

  return missingRequiredElements;
};

export const getFlowlessAreas = (
  allShapes: DTShape[],
  shapeGroups: ShapeGroup[],
  flows: LayoutFlow[],
): (AreaShape | PositionShape)[] => {
  const intakes: (AreaShape | PositionShape)[] = [];
  const deliveries: (AreaShape | PositionShape)[] = [];
  const storages: (AreaShape | PositionShape)[] = [];

  // derive intakes, deliveries and storages
  allShapes.forEach((shape) => {
    switch (shape.type) {
      case ShapeType.INTAKE:
      case ShapeType.INTAKE_POSITION:
        intakes.push(shape as AreaShape | PositionShape);
        break;
      case ShapeType.DELIVERY:
      case ShapeType.DELIVERY_POSITION:
        deliveries.push(shape as AreaShape | PositionShape);
        break;
      case ShapeType.STORAGE:
      case ShapeType.STORAGE_POSITION:
        storages.push(shape as AreaShape | PositionShape);
        break;
      default:
        break;
    }
  });

  const flowStopIds = new Set<string>();
  flows.forEach((flow) => {
    if (flow.id != null) {
      flowStopIds.add(flow.deliveryFlowStop.id);
      flowStopIds.add(flow.intakeFlowStop.id);
    }
  });

  const shapeGroupsWithFlows = shapeGroups.filter(({ id }) => flowStopIds.has(id));
  const shapesFromGroupsWithFlows = shapeGroupsWithFlows
    .map((shapeGroup) => shapeGroup.children)
    .flat();

  return [...intakes, ...deliveries, ...storages].filter(
    ({ id }) => !flowStopIds.has(id) && !shapesFromGroupsWithFlows.includes(id),
  );
};

export const getConnectionLackingRoadIds = (
  allRoadIds: string[],
  allConnections: (Connection | DistantConnection)[],
  allCrossings: Crossing[],
): string[] => {
  // allocate a map between all roads and their connections (if any) and crossings (if any)
  const roadIdConnectionsCrossingsMap = new Map<string, RoadConnectionsCrossingsCollection>(
    allRoadIds.map((id) => [id, { connections: [], crossings: [] }]),
  );

  allConnections.forEach((con) => {
    roadIdConnectionsCrossingsMap.get(decodeShapeId(con.from))?.connections.push(con);
    roadIdConnectionsCrossingsMap.get(decodeShapeId(con.to))?.connections.push(con);
  });

  allCrossings.forEach((cros) => {
    roadIdConnectionsCrossingsMap.get(decodeShapeId(cros.from))?.crossings.push(cros);
    roadIdConnectionsCrossingsMap.get(decodeShapeId(cros.to))?.crossings.push(cros);
  });

  const connectionLackingRoadIds = allRoadIds.filter((id) => {
    const road = roadIdConnectionsCrossingsMap.get(id);
    if (road) return isDeadEndRoad(road.connections.length, road.crossings.length);
    return null;
  });

  return connectionLackingRoadIds;
};

export const getDisconnectedAreaIds = (
  allAreas: AreaShape[],
  allPositionIds: string[],
  allProcessIds: string[],
  allConnections: (Connection | DistantConnection)[],
) => {
  const allAreaIds = allAreas.map(item => item.id);
  const connectedShapeIds = new Set<string>();
  allConnections.forEach((item) => {
    connectedShapeIds.add(item.from);
    connectedShapeIds.add(item.to);
  });

  // Two endpoint process areas need to be connected on both ends
  allProcessIds
    .filter((id) => connectedShapeIds.has(`${id}.0`) && connectedShapeIds.has(`${id}.1`))
    .forEach(connectedShapeIds.add, connectedShapeIds);

  return [...allAreaIds, ...allPositionIds, ...allProcessIds].filter(
    (id) => !connectedShapeIds.has(id),
  );
};

export const getDisconnectedFlowStopIds = (
  flows: LayoutFlow[],
  allConnections: Connection[],
  allDistantConnections: DistantConnection[],
  allShapeGroups: ShapeGroup[],
): string[] => {
  const disconnectedFlowStopIds = new Set<string>();
  const flowStopIdMap = new Map<string, FlowStop>();
  const connectedShapesIds = new Set<string>();

  // get a map between each flowstop id and its entity
  flows.forEach((flow) => {
    flowStopIdMap.set(flow.intakeFlowStop.id, flow.intakeFlowStop);
    flowStopIdMap.set(flow.deliveryFlowStop.id, flow.deliveryFlowStop);
  });

  // populate flowStopIdsWithConnectionsSet
  allConnections.forEach((con) => {
    connectedShapesIds.add(con.from).add(con.to);
  });
  allDistantConnections.forEach((distCon) => {
    connectedShapesIds.add(distCon.from).add(distCon.to);
  });

  const groupMap = new Map<string, ShapeGroup>(allShapeGroups.map((group) => [group.id, group]));

  flowStopIdMap.forEach((flowStop: FlowStop) => {
    if (flowStop.type === FlowStopType.AREA) {
      if (!connectedShapesIds.has(flowStop.id)) {
        disconnectedFlowStopIds.add(flowStop.id);
      }
    } else if (flowStop.type === FlowStopType.AREA_GROUP) {
      const shapeGroup = groupMap.get(flowStop.id);
      if (!shapeGroup) {
        return;
      }
      const childIdConnectionsCrossingsMap = new Map<string, ShapeConsDistConsCollection>(
        shapeGroup.children.map((childId) => [
          childId,
          { connections: [], distantConnections: [] },
        ]),
      );

      // allocate connections to appropriate group child id
      allConnections.forEach((con) => {
        childIdConnectionsCrossingsMap.get(con.from)?.connections.push(con);
        childIdConnectionsCrossingsMap.get(con.to)?.connections.push(con);
      });

      // allocate distant connections to appropriate group child id
      allDistantConnections.forEach((distCon) => {
        childIdConnectionsCrossingsMap.get(distCon.from)?.distantConnections.push(distCon);
        childIdConnectionsCrossingsMap.get(distCon.to)?.distantConnections.push(distCon);
      });

      const disconnectedChildren: string[] = [];
      shapeGroup.children.forEach((childId: string) => {
        const connectionData = childIdConnectionsCrossingsMap.get(childId);

        if (
          connectionData &&
          !connectionData.connections.length &&
          !connectionData.distantConnections.length
        ) {
          disconnectedChildren.push(childId);
        }
      });

      if (disconnectedChildren.length === shapeGroup.children.length)
        disconnectedFlowStopIds.add(shapeGroup.id);
    }
  });

  return [...disconnectedFlowStopIds];
};

export const getIncorrectlyConnectedShapeIds = (
  areasAndPositionsIds: string[],
  allHighways: HighwayShape[],
  allConnections: Connection[],
): string[] => {
  const incorrectlyConnectedShapeIds = [];
  const highwayIdHighwayMap = new Map<string, HighwayShape>(
    allHighways.map((item) => [item.id, item]),
  );

  const shapeIdsConnectionsMap = new Map<string, Connection[]>(
    areasAndPositionsIds.map((shapeId) => [shapeId, []]),
  );

  // allocate each connection to both its "from"-shape and its "to"-shape
  allConnections.forEach((con) => {
    shapeIdsConnectionsMap.get(con.from)?.push(con);
    shapeIdsConnectionsMap.get(con.to)?.push(con);
  });

  shapeIdsConnectionsMap.forEach((connections, shapeId) => {
    // get ids of the shapes connected to the current shape
    const relatedConnectedShapeIds = connections.map((item) =>
      item.from === shapeId ? item.to : item.from,
    );

    // get roads connected to this shape
    const roadsConnectedToShape: HighwayShape[] = [];
    relatedConnectedShapeIds.forEach((id) => {
      const highway = highwayIdHighwayMap.get(id);
      if (highway) roadsConnectedToShape.push(highway);
    });
  });

  return incorrectlyConnectedShapeIds;
};
