import { memo, useState, useCallback } from 'react';
import { Typography, Stack, Badge, IconButton, Box } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '@/assets/icons';
import NotificationIcon from '@/modules/Notifications/components/NotificationIcon';
import {
  NotificationType,
  type BaseNotification as NotificationInterface,
} from '@/modules/Notifications/helpers/types';
import { useNotificationStore } from '@/modules/Notifications/store/useNotificationStore';
import { getSeverityStyles } from '@/components/NavigationBar/helpers/SeverityStyles';
import NotificationSeverityActions from '@/components/NavigationBar/helpers/NotificationSeverityActions';
import { theme } from '@/modules/common/components/theme';

interface NotificationProps {
  notification: NotificationInterface;
}

function Notification({ notification }: NotificationProps) {
  const { removeNotification } = useNotificationStore();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation(['errors']);
  const handleRemove = useCallback(
    () => removeNotification(notification.id),
    [removeNotification, notification.id],
  );

  const notificationTime = format(new Date(notification.timestamp), 'HH:mm');

  return (
    <Stack
      className='notification'
      spacing={2}
      py={2}
      sx={{
        width: '100%',
        backgroundColor: { ...getSeverityStyles(notification.severity) },
        transition: 'background-color 0.1s ease',
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack
        direction='row'
        spacing={2}
        px={2}
        sx={{
          justifyContent: 'left',
          alignItems: 'left',
        }}
      >
        <Stack sx={{ color: theme.palette.others.black, top: 14 }}>
          <NotificationIcon type={NotificationType[notification.severity]} />
        </Stack>
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              color: 'neutral.darker',
              justifyContent: 'left',
            }}
          >
            {notification.title}
          </Typography>
        </Stack>
        <Stack
          direction='row'
          sx={{
            cursor: 'pointer',
            width: '40%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {isHovered ? (
            <CloseIcon onClick={handleRemove} />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontSize={10} color='neutral.main'>
                {notificationTime}
              </Typography>
              {!notification.read && (
                <Badge color='primary' variant='dot' sx={{ pl: 1.5, ml: 1 }} />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
      <Stack px={7} spacing={2}>
        <Typography lineHeight='16px' fontSize={10} sx={{ wordBreak: 'break-word' }}>
          {notification.description}
        </Typography>
      </Stack>
      {NotificationSeverityActions({ notification })}
    </Stack>
  );
}

export default memo(Notification);
