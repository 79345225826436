import { Grid, Typography, Switch, IconButton } from '@mui/material';
import { Stack } from '@mui/system';

import InfoIcon from '@/assets/icons/InfoIcon';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { useDebugStore } from '../store';

export const MultiLoadCarrierSelectionEnabledToggle = () => {
  const { multiLoadCarrierSelectionEnabled, toggleMultiLoadCarrierSelectionEnabled } =
    useDebugStore((state) => state);

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Stack direction='row' alignItems='center' gap={1}>
          <Typography>Enable multiple load carrier usage</Typography>
          <StyledTooltip
            placement='top'
            enterDelay={0}
            title={`Enabling this will make it possible to select multiple 
              load carriers in the setup, and also select multiple 
              load carriers in the properties panel of an area.`}
          >
            <IconButton sx={{ cursor: 'default', height: '28px', width: '28px' }}>
              <InfoIcon width='28px' height='28px' />
            </IconButton>
          </StyledTooltip>
        </Stack>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Switch
          onChange={toggleMultiLoadCarrierSelectionEnabled}
          checked={multiLoadCarrierSelectionEnabled}
        />
      </Grid>
    </>
  );
};
