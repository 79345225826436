import { Group, Rect } from 'react-konva';
import { degToRad } from 'three/src/math/MathUtils';
import { theme } from '@/modules/common/components/theme';

const HazardStripes = ({ width, height }: { width: number; height: number }) => {
  const stripes: JSX.Element[] = [];

  const clippingOffset = 50;
  const stripeWidth = 80;
  const offsetBetweenStripes = 200;
  const rotation = 45;

  const diagonalSize = Math.sqrt(width * width + height * height);
  const numberOfStripes = Math.ceil(diagonalSize / offsetBetweenStripes);

  const biggestSide = Math.max(width, height);
  const xStartOffset = biggestSide / 2;
  const yStartOffset = -biggestSide / 2;
  for (let i = 0; i < numberOfStripes; i++) {
    const xPos = xStartOffset + i * offsetBetweenStripes * Math.cos(degToRad(rotation));
    const yPos = yStartOffset + i * offsetBetweenStripes * Math.sin(degToRad(rotation));

    stripes.push(
      <Rect
        key={i}
        x={xPos}
        y={yPos}
        width={stripeWidth}
        height={biggestSide * 2}
        fill={theme.palette.disabled.main}
        rotation={rotation}
        offsetX={stripeWidth / 2}
        offsetY={0}
      />,
    );
  }

  return (
    <Group
      clipFunc={(ctx) => {
        ctx.rect(
          clippingOffset,
          clippingOffset,
          width - clippingOffset * 2,
          height - clippingOffset * 2,
        );

        ctx.fillStyle = theme.palette.neutral.grey;
        ctx.strokeStyle = theme.palette.neutral.lighter;
        ctx.lineWidth = clippingOffset * 2;
        ctx.stroke();
        ctx.fill();
      }}
    >
      {stripes}
    </Group>
  );
};

export default HazardStripes;
