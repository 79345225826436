import { useRecoilCallback } from 'recoil';
import { allDistantConnectionsSelector } from '@modules/connections/distant';
import { allConnectionsSelector } from '@modules/connections/connections';
import { allAreasSelector } from '../../../../store/recoil/shapes/area';
import { ShapeType } from '@/modules/common/types/shapes';
import { AreaShape } from '@/store/recoil/shape';
import { prevalidationState } from '../store';

export const useValidateManualArea = () => {
  const validateManualArea = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        // Fetch all data in parallel
        const [allAreas, allConnectionsRaw, allDistantConnectionsRaw] = await Promise.all([
          snapshot.getPromise(allAreasSelector),
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(allDistantConnectionsSelector),
        ]);

        const allConnections = [...allConnectionsRaw, ...allDistantConnectionsRaw];
        const manualAreas = new Map<string, AreaShape>();
        const manualEntryAreas = new Map<string, AreaShape>();
        const manualExitAreas = new Map<string, AreaShape>();

        allAreas.forEach((area) => {
          if (area.type === ShapeType.MANUAL) manualAreas.set(area.id, area);
          if (area.type === ShapeType.MANUAL_ENTRY) manualEntryAreas.set(area.id, area);
          if (area.type === ShapeType.MANUAL_EXIT) manualExitAreas.set(area.id, area);
        });

        const connectionMap = new Map<string, Set<string>>();
        allConnections.forEach(({ from, to }) => {
          if (!connectionMap.has(from)) connectionMap.set(from, new Set());
          if (!connectionMap.has(to)) connectionMap.set(to, new Set());
          connectionMap.get(from)?.add(to);
          connectionMap.get(to)?.add(from);
        });

        const invalidManualAreas: AreaShape[] = [];
        const invalidManualEntryAreas: AreaShape[] = [];
        const invalidManualExitAreas: AreaShape[] = [];

        // Helper function to get connected areas
        const getConnectedAreas = (areaId: string) => connectionMap.get(areaId) ?? new Set();

        // Validate Manual Areas: Must have both a manual entry and a manual exit connection
        manualAreas.forEach((area, areaId) => {
          const connectedAreas = getConnectedAreas(areaId);
          const hasEntryConnection = [...connectedAreas].some((id) => manualEntryAreas.has(id));
          const hasExitConnection = [...connectedAreas].some((id) => manualExitAreas.has(id));

          if (!hasEntryConnection || !hasExitConnection) {
            invalidManualAreas.push(area);
          }
        });

        // Validate Manual Entry Areas: Must have 2+ connections, one of which must be a manual area
        manualEntryAreas.forEach((area, areaId) => {
          const connectedAreas = getConnectedAreas(areaId);
          const hasManualConnection = [...connectedAreas].some((id) => manualAreas.has(id));

          if (connectedAreas.size < 2 || !hasManualConnection) {
            invalidManualEntryAreas.push(area);
          }
        });

        // Validate Manual Exit Areas: Must have 2+ connections, one of which must be a manual area
        manualExitAreas.forEach((area, areaId) => {
          const connectedAreas = getConnectedAreas(areaId);
          const hasManualConnection = [...connectedAreas].some((id) => manualAreas.has(id));

          if (connectedAreas.size < 2 || !hasManualConnection) {
            invalidManualExitAreas.push(area);
          }
        });

        // Sync results with store if needed
        if (syncResultsWithStore) {
          set(prevalidationState, (current) => ({
            ...current,
            invalidManualAreas,
            invalidManualEntryAreas,
            invalidManualExitAreas,
          }));
        }

        return [...invalidManualAreas, ...invalidManualEntryAreas, ...invalidManualExitAreas];
      },
    [],
  );

  return { validateManualArea };
};
