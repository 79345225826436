export enum LayerNames {
  CONNECTIONS = 'connections',
  ROADS = 'roads',
  HIGHWAYS = 'highways',
  ANGLED_HIGHWAYS = 'angled_highways',
  AREAS = 'areas',
  INTAKE = 'intake',
  DELIVERY = 'delivery',
  STORAGE = 'storage',
  CHARGING = 'charging',
  PARKING = 'parking',
  MANUAL = 'manual',
  MANUAL_EXIT = 'manual_exit',
  MANUAL_ENTRY = 'manual_entry',
  PROCESS = 'process',
  INTERIOR = 'interior',
  WALLS = 'walls',
  OBSTACLES = 'obstacles',
  REFERENCE = 'reference',
  FLOWS = 'flows',
  WIFI = 'wifi',
  NAMES = 'names',
  AREA_NAMES = 'area_names',
  HIGHWAY_NAMES = 'highway_names',
  OBSTACLE_NAMES = 'obstacle_names',
}

export type LayerSwitches = {
  visible: boolean;
  locked: boolean;
};

export type Layers = {
  [LayerNames.CONNECTIONS]: LayerSwitches;
  [LayerNames.ROADS]: LayerSwitches;
  [LayerNames.HIGHWAYS]: LayerSwitches;
  [LayerNames.ANGLED_HIGHWAYS]: LayerSwitches;
  [LayerNames.AREAS]: LayerSwitches;
  [LayerNames.INTAKE]: LayerSwitches;
  [LayerNames.DELIVERY]: LayerSwitches;
  [LayerNames.STORAGE]: LayerSwitches;
  [LayerNames.CHARGING]: LayerSwitches;
  [LayerNames.PARKING]: LayerSwitches;
  [LayerNames.MANUAL]: LayerSwitches;
  [LayerNames.MANUAL_EXIT]: LayerSwitches;
  [LayerNames.MANUAL_ENTRY]: LayerSwitches;
  [LayerNames.PROCESS]: LayerSwitches;
  [LayerNames.INTERIOR]: LayerSwitches;
  [LayerNames.WALLS]: LayerSwitches;
  [LayerNames.REFERENCE]: LayerSwitches;
  [LayerNames.OBSTACLES]: LayerSwitches;
  [LayerNames.FLOWS]: LayerSwitches;
  [LayerNames.WIFI]: LayerSwitches;
  [LayerNames.NAMES]: LayerSwitches;
  [LayerNames.AREA_NAMES]: LayerSwitches;
  [LayerNames.HIGHWAY_NAMES]: LayerSwitches;
  [LayerNames.OBSTACLE_NAMES]: LayerSwitches;
};

export type LayerModule = {
  layers: Layers;
};
