import { SvgIcon } from '@mui/material';

export default function DetailIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 12 14'
      sx={{
        transform: 'scale(0.6)',
      }}
    >
      <path
        d='M6.00267 6.79806C6.33654 6.79806 6.62148 6.67919 6.85748 6.44144C7.09361 6.20369 7.21167 5.91787 7.21167 5.584C7.21167 5.25012 7.09273 4.96513 6.85486 4.729C6.61711 4.493 6.33129 4.375 5.99742 4.375C5.66354 4.375 5.37861 4.49388 5.14261 4.73163C4.90648 4.96938 4.78842 5.25519 4.78842 5.58906C4.78842 5.92294 4.90736 6.20794 5.14523 6.44406C5.38298 6.68006 5.66879 6.79806 6.00267 6.79806ZM6.00004 12.7578C7.40967 11.5308 8.52411 10.2829 9.34336 9.01413C10.1626 7.74538 10.5722 6.64806 10.5722 5.72219C10.5722 4.35006 10.1379 3.21756 9.26911 2.32469C8.40036 1.43194 7.31067 0.985562 6.00004 0.985562C4.68942 0.985562 3.59973 1.43194 2.73098 2.32469C1.86223 3.21756 1.42786 4.35006 1.42786 5.72219C1.42786 6.64806 1.83748 7.74538 2.65673 9.01413C3.47598 10.2829 4.59042 11.5308 6.00004 12.7578ZM6.00004 13.7644C4.23754 12.2058 2.90992 10.7522 2.01717 9.40356C1.12429 8.05506 0.677856 6.82794 0.677856 5.72219C0.677856 4.13556 1.19448 2.82688 2.22773 1.79613C3.26086 0.765375 4.51829 0.25 6.00004 0.25C7.48179 0.25 8.73923 0.765375 9.77236 1.79613C10.8056 2.82688 11.3222 4.13556 11.3222 5.72219C11.3222 6.82794 10.8758 8.05506 9.98292 9.40356C9.09017 10.7522 7.76254 12.2058 6.00004 13.7644Z'
        fill='#222222'
      />
    </SvgIcon>
  );
}
