import LoadDuration from '@/components/PropertiesPanel/layout/timers/LoadDuration';
import UnloadDuration from '@/components/PropertiesPanel/layout/timers/UnloadDuration';
import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { usesLoadDuration, usesUnloadDuration } from '@/modules/common/helpers/shapes';
import { isAreaShape } from '@/modules/common/types/guards';
import { AreaLoadAction, ShapeType } from '@/modules/common/types/shapes';

import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { useRecoilValue } from 'recoil';

export default function LoadDurations() {
  const selectedShapes = useRecoilValue(selectedShapesState);
  
  if (selectedShapes.length !== 1 || !isAreaShape(selectedShapes[0])) return null;
  const selectedShape = selectedShapes[0];
  
  const areaLoadAction = selectedShape.parameters?.loadAction;

  let showLoadDuration = false;
  if (usesLoadDuration(selectedShape.type)) {
    showLoadDuration = true;

    if (areaLoadAction && ![AreaLoadAction.LOAD, AreaLoadAction.LOAD_UNLOAD].includes(areaLoadAction)) {
      showLoadDuration = false;
    }
  }
  
  let showUnloadDuration = false;
  if (usesUnloadDuration(selectedShape.type)) {
    showUnloadDuration = true;

    if (areaLoadAction && ![AreaLoadAction.UNLOAD, AreaLoadAction.LOAD_UNLOAD].includes(areaLoadAction)) {
      showUnloadDuration = false;
    }
  }

  return (
    <>
      {showLoadDuration 
        ? (<PropertiesLabel i18nkey='interface:properties.area.load_duration'>
            <LoadDuration />
          </PropertiesLabel>) 
        : null
      }
      {showUnloadDuration 
        ? (<PropertiesLabel i18nkey='interface:properties.area.unload_duration'>
            <UnloadDuration />
          </PropertiesLabel>) 
        : null
      }
    </>
  );
}