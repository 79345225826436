import Typography from '@mui/material/Typography';

import { InfoTooltip } from '../InfoTooltip';

type Props = {
  status: string;
  color: string;
  message?: string;
};

export const StatusChip = ({ color, status, message }: Props) => {
  const badge = (
    <Typography
      fontSize={9}
      color='shades.light'
      letterSpacing='0.03em'
      textTransform='uppercase'
      sx={{
        borderRadius: '16px',
        backgroundColor: color,
        padding: '4px 8px',
        lineHeight: 'normal',
        textAlign: 'center',
      }}
      component='span'
      className='status MuiButton-startIcon'
    >
      {status}
    </Typography>
  );

  return (
    <>
      {message ? (
        <InfoTooltip placement='left' type='error' title={message}>
          {badge}
        </InfoTooltip>
      ) : (
        badge
      )}
    </>
  );
};
