import { memo } from 'react';
import { useSetRecoilState } from 'recoil';

import { NumberInput } from '@common/components/inputs';
import { CONTEXT, contextState } from '@recoil/input';

type Props = {
  value: number;
  onChange: any;
  disabled?: boolean;
};

function TemplateNumberInputComponent({ value, onChange, disabled = false }: Props) {
  const setContext = useSetRecoilState(contextState);

  return (
    <NumberInput
      value={value}
      sx={{ width: '100%' }}
      disabled={disabled}
      onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
      onChange={onChange}
    />
  );
}

export const TemplateNumberInput = memo(TemplateNumberInputComponent);
