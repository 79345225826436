// #region FloorPlan Service

export type FPBoundingBox = {
  width: number | null;
  length: number | null;
  height: number | null;
  minX: number | null;
  minY: number | null;
  minZ: number | null;
};

export enum Alignment {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum AreaType {
  STORAGE = 'STORAGE',
  STATIONARY = 'STATIONARY',
  INTAKE = 'INTAKE',
  DELIVERY = 'DELIVERY',
  PROCESS = 'MIXED',
  HIGHWAY = 'HIGHWAY',
  EMPTY = 'EMPTY',
}

export enum LoadHandlingType {
  PROCESS_ON_VEHICLE = 'ON_VEHICLE_PROCESSING',
  PROCESS = 'INTAKE_AND_DELIVERY',
  DELIVERY = 'DELIVERY',
  INTAKE = 'INTAKE',
  STORAGE = 'STORAGE',
  EXTRACTION = 'EXTRACTION',
  INSERTION = 'INSERTION',
}

export enum StationaryTypeEnum {
  PARKING = 'PARKING',
  CHARGING = 'CHARGING',
  INSERTION = 'INSERTION',
  EXTRACTION = 'EXTRACTION',
}

export enum AreaMapperTypeEnum {
  MAP_ALL_NEAREST_CHECK_POINTS = 'MAP_ALL_NEAREST_CHECK_POINTS',
  MAP_CHECK_POINTS_AT_CROSSING = 'MAP_CHECK_POINTS_AT_CROSSING',
  MAP_CHECK_POINTS_TO_END_POINTS = 'MAP_CHECK_POINTS_TO_END_POINTS',
  MAP_CHECK_POINTS_TO_END_POINTS_AT_CROSSING = 'MAP_CHECK_POINTS_TO_END_POINTS_AT_CROSSING',
  MAP_END_POINTS_MULTI_DEEP = 'MAP_END_POINTS_MULTI_DEEP',
  MAP_END_POINTS_TO_GETAWAY_CHECK_POINTS = 'MAP_END_POINTS_TO_GETAWAY_CHECK_POINTS',
  MAP_END_POINTS_TO_LOCAL_CHECK_POINTS = 'MAP_END_POINTS_TO_LOCAL_CHECK_POINTS',
  MAP_HIGHWAY_TO_SERVICE_ROAD_CHECK_POINTS = 'MAP_HIGHWAY_TO_SERVICE_ROAD_CHECK_POINTS',
  MAP_TWO_NEAREST_CHECK_POINTS = 'MAP_TWO_NEAREST_CHECK_POINTS',
  MAP_END_POINT_NEIGHBORS = 'MAP_END_POINT_NEIGHBORS',
  MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS = 'MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS',
  MAP_END_POINTS_TO_LOAD_POSITIONS = 'MAP_END_POINTS_TO_LOAD_POSITIONS',
}

export enum TwinConfiguration {
  ONE_WAY_KEEP_FIRST = 'ONE_WAY_KEEP_FIRST',
  ONE_WAY_KEEP_SECOND = 'ONE_WAY_KEEP_SECOND',
  TWO_WAY = 'TWO_WAY',
}

export enum VehiclePosition {
  ALONGSIDE = 'ALONGSIDE',
  PERPENDICULAR = 'PERPENDICULAR',
}

// #endregion

export enum ConnectionDirection {
  BothWays = 'BOTH_WAYS',
  FromSource = 'FROM_SOURCE',
  FromTarget = 'FROM_TARGET',
}

export type LoadTypeReferences = { name: string }[];

export type GateMappingSettings = {
  mapCheckPointTwinsInAllAreas: boolean;
  mapCheckPointNeighborsInAllAreas: boolean;
  areaMappings: any[];
};

type RestVehicleSpec = {
  vehicleSpecId: string;
  name: string;
  pointCommsIdGeneratorBk: 'PointIdGen';
  connectionCommsIdGeneratorBk: 'ConnIdGen';
  // uvtReference: string;
  uvtDownloadUrl: string;
  areas: any[];
  locationGroups: any[];
  endPointGenerationSettings: any[];
  fixedEndPointGenerationSettings: any[];
  checkPointGenerationSettings: CheckPointGenerationSettings[];
  gateMappingSettings: GateMappingSettings;
  locationAdjustments: any[];
};
type AppVehicleSpec = {
  databaseId: number;
  width: number;
  length: number;
};
export type VehicleSpec = RestVehicleSpec & AppVehicleSpec;

export type CheckPointGenerationSettings = {
  name: string;
  checkPointIdGenerator: any;
  commsIdGeneratorBk: 'PointIdGen';
  idShortenerBk: 'IdShort';
  gapBetweenAdjacentRps: number;
  marginOuterRp: number;
  applyHeadRoomConstraints: boolean;
  margin: number;
  minGap: number;
  skipFirst: boolean;
  skipLast: boolean;
  twinConfig: TwinConfiguration;
  areaReference: AreaReference;
  cpCutOutReferences: AreaReference[];
};

export type AreaReference = {
  name: string;
};

export type FpsReflectorBase = {
  reflectorId: string;
  externalId: string;
  type: number;
};

export type FpsReflectorFlat = {
  x: number;
  y: number;
  angle: number;
  width: number;
} & FpsReflectorBase;

export type FpsReflectorWall = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
} & FpsReflectorBase;

export type RackSpec = {
  rackSpecId: string;
  name: string;
  frameLength: number;
  frameDepth: number;
  frameHeight: number;
  beamLength: number;
  beamHeight: number;
  clearanceSide: number;
  clearanceInBetween: number;
  clearanceTop: number;
  clearanceDepth: number;
  overhangFront: number;
  overhangBack: number;
  topShelfHeight: number;
  numLoadPositionsPerShelf: number;
  shelfHeights: {
    sequence: number;
    height: number;
  }[];
  depth: number;
  loadPositions: any[]; // TODO rack: type to be determined
};

export type Mapping = {
  discType: AreaMapperTypeEnum;
  areaReferencesToMap: AreaReference[];
  epBackwardParking: Boolean;
  usePivots: Boolean,
}
