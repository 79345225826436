import { selector } from 'recoil';

import { AdditionalData } from '@/modules/common/types/floorPlan';
import {
  prepareToLoad as prepareToLoadCrossings,
  saveLoadCrossingSelector,
} from '@/modules/connections/crossings';
import {
  moduleStateSelector as layerModuleStateSelector,
  moduleStateSelector,
} from '@/modules/layers';
import { orderProfileAtom } from '@/modules/orderProfile/store/orderProfileAtom';
import {
  prepareToLoad as prepareToLoadConnections,
  prepareToSave as prepareToSaveConnections,
  saveLoadConnectionSelector,
} from '@modules/connections/connections';
import {
  prepareToLoad as prepareToLoadDistantConnections,
  prepareToSave as prepareToSaveDistantConnections,
  saveLoadDistantConnectionsSelector,
} from '@modules/connections/distant';
import { layoutFlowsSelector } from '@modules/flows/store/layout';
import {
  prepareToLoad as prepareToLoadCarriers,
  prepareToSave as prepareToSaveLoadTypes,
} from '@modules/loadCarriers';
import { referenceUrn } from '@modules/referenceImage';
import { allGroupsSelector, prepareToLoad as prepareToLoadShapeGroups } from '@modules/shapeGroups';
import {
  prepareToLoad as prepareToLoadShapes,
  prepareToSave as prepareToSaveShapes,
} from '@modules/shapes';
import {
  prepareToLoad as prepareToLoadVehicles,
  prepareToSave as prepareToSaveVehicleTypes,
  allVehiclesState,
  enabledVehiclesSelector,
  allVehicleAssetsSelector,
} from '@modules/vehicles';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { allShapesSelector } from '@recoil/shapes';
import { sizeSelector, unitSelector } from '@recoil/workspace';
import {
  availableLoadCarrierTypesState,
  enabledLoadCarrierTypesSelector,
} from '../loadCarrierTypes';
import { reconcileFlowIncompatibilities, setShapesDefaultNaming } from './helper';
import { DTShape } from '@/store/recoil/shape';
import { customIdGeneratorsState } from '@/modules/floorplanService';

/**
 * READ BEFORE MODIFYING THIS SELECTOR
 * This selector is used to prepare data to be stored or read from json files.
 *
 * 1. Do not reorder properties returned from the get selector
 * 2. If a property doesn't have a state yet (new property, nothing was yet saved) it should return undefined
 */
export const floorplanElementSelector = selector<AdditionalData>({
  key: 'floorplanElementSelector',
  get: ({ get }) => ({
    empty: false,
    connections: prepareToSaveConnections(get(saveLoadConnectionSelector)),
    crossings: [],
    distcons: prepareToSaveDistantConnections(get(saveLoadDistantConnectionsSelector)),
    flows: get(layoutFlowsSelector),
    layers: get(moduleStateSelector),
    loadTypes: prepareToSaveLoadTypes(get(enabledLoadCarrierTypesSelector)),
    shapes: prepareToSaveShapes(get(allShapesSelector)),
    groups: { shapeGroups: get(allGroupsSelector) },
    orderProfile: get(orderProfileAtom) ?? undefined,
    // @ts-expect-error strictNullChecks. Pls fix me
    thumbnail: null,
    unit: get(unitSelector),
    urn: get(referenceUrn),
    vehicleTypes: prepareToSaveVehicleTypes(get(enabledVehiclesSelector)),
    vehicleAssets: get(allVehicleAssetsSelector),
    workspaceSize: get(sizeSelector),
    customIdGenerators: get(customIdGeneratorsState),
  }),
  set: ({ set, get }, data: AdditionalData) => {
    let shapesPrepared: DTShape[] = [];
    let enabledVehiclesPrepared = prepareToLoadVehicles(data, get(allVehiclesState));

    if (data.shapes) {
      shapesPrepared = prepareToLoadShapes(
        data.shapes,
        enabledVehiclesPrepared,
        data.loadTypes,
        data.unit,
      );
      set(allShapesSelector, shapesPrepared);
    }
    if (data.groups && data.groups.shapeGroups)
      set(allGroupsSelector, prepareToLoadShapeGroups(data.groups.shapeGroups));

    if (data.loadTypes)
      set(
        enabledLoadCarrierTypesSelector,
        prepareToLoadCarriers(data, get(availableLoadCarrierTypesState)),
      );

    if (data.vehicleTypes) set(enabledVehiclesSelector, enabledVehiclesPrepared);
    if (data.vehicleAssets) set(allVehicleAssetsSelector, data.vehicleAssets);

    if (data.connections)
      set(saveLoadConnectionSelector, prepareToLoadConnections(data.connections, shapesPrepared));
    if (data.crossings) set(saveLoadCrossingSelector, prepareToLoadCrossings(data.shapes));
    if (data.distcons)
      set(
        saveLoadDistantConnectionsSelector,
        prepareToLoadDistantConnections(data.distcons, data.shapes),
      );
    if (data.flows) set(layoutFlowsSelector, reconcileFlowIncompatibilities(data.flows));
    if (data.orderProfile) set(orderProfileAtom, data.orderProfile);
    if (data.urn) set(referenceUrn, data.urn);
    if (data.workspaceSize) set(sizeSelector, data.workspaceSize);
    if (data.shapes) setShapesDefaultNaming(data.shapes);
    if (data.layers?.layers) set(layerModuleStateSelector, data.layers);
    if (data.customIdGenerators) set(customIdGeneratorsState, data.customIdGenerators);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
