import { Typography, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import { memo, MouseEventHandler, useCallback } from 'react';

import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@/assets/icons';

const MultiSelectInputOptionComponent = ({
  value,
  label,
  onClick,
  isSelected,
}: {
  value: string;
  label: string;
  onClick: (value: string) => void;
  isSelected: boolean;
}) => {
  const icon = isSelected ? (
    <CheckboxCheckedIcon className='check' />
  ) : (
    <CheckboxUncheckedIcon className='check' />
  );

  const onClickInner: MouseEventHandler<HTMLLIElement> = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <MenuItem
      onClick={onClickInner}
      key={`${label}-${value}`}
      value={value}
      tabIndex={0}
      role='menuitem'
      sx={{
        'padding-right': '8px',
        height: '32px',
      }}
    >
      <Stack
        direction='row'
        gap={1.5}
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {label}
        </Typography>
        {icon}
      </Stack>
    </MenuItem>
  );
};

export const MultiSelectInputOption = memo(MultiSelectInputOptionComponent);
