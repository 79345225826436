import { Grid, Typography, Switch } from '@mui/material';

import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { useDebugStore } from '../store';

export const MultiVehiclePerAreaEnabledToggle = () => {
  const { multiVehiclePerAreaEnabled, toggleMultiVehiclePerAreaEnabled } = useDebugStore(
    (state) => state,
  );

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Enable multiple vehicles per area</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Switch onChange={toggleMultiVehiclePerAreaEnabled} checked={multiVehiclePerAreaEnabled} />
      </Grid>
    </>
  );
};
