import { SvgIcon } from '@mui/material';

export default function ReadMore(props) {
  return (
    <SvgIcon
      {...props}
      viewBox='0 0 12 14'
      sx={{
        transform: 'scale(0.6)',
      }}
    >
      <path
        d='M3.375 11.125H8.625V10.375H3.375V11.125ZM3.375 8.125H8.625V7.375H3.375V8.125ZM1.96162 13.75C1.61637 13.75 1.32812 13.6344 1.09688 13.4031C0.865625 13.1719 0.75 12.8836 0.75 12.5384V1.46162C0.75 1.11637 0.865625 0.828125 1.09688 0.596875C1.32812 0.365625 1.61637 0.25 1.96162 0.25H7.875L11.25 3.625V12.5384C11.25 12.8836 11.1344 13.1719 10.9031 13.4031C10.6719 13.6344 10.3836 13.75 10.0384 13.75H1.96162ZM7.5 4V1H1.96162C1.84613 1 1.74031 1.04806 1.64419 1.14419C1.54806 1.24031 1.5 1.34613 1.5 1.46162V12.5384C1.5 12.6539 1.54806 12.7597 1.64419 12.8558C1.74031 12.9519 1.84613 13 1.96162 13H10.0384C10.1539 13 10.2597 12.9519 10.3558 12.8558C10.4519 12.7597 10.5 12.6539 10.5 12.5384V4H7.5Z'
        fill='#222222'
      />
    </SvgIcon>
  );
}
