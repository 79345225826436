import { AreaAlignBotIcon, AreaAlignMidIcon, AreaAlignTopIcon } from '@/assets/icons';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { AreaAlignment, AreaDirection } from '@modules/common/types/shapes';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useCallback } from 'react';
import {
  PreValidationAspect,
  useRunPreValidation,
} from '../../../modules/floorplanValidation/clientSide';
import { useArea } from '../hooks';
import { areasAlignment } from '../store/area';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';

function Align() {
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const [alignment, setAlignment] = useRecoilState(areasAlignment);
  const { direction } = useArea();
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);

  const onChange = useCallback(
    (_, value: AreaAlignment) => {
      if (value != null) {
        setAlignment(value);
        saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
        updateArtefacts(ids);
      }
    },
    [setAlignment, saveFloorPlan, runPreValidation, updateArtefacts, ids],
  );

  const iconSx = {
    transform:
      direction === AreaDirection.UP || direction === AreaDirection.DOWN
        ? 'rotate(0deg)'
        : 'rotate(-90deg)',
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={onChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton value={AreaAlignment.TOP} aria-label='Top'>
        <AreaAlignTopIcon sx={iconSx} />
      </ToggleButton>
      <ToggleButton value={AreaAlignment.CENTER} aria-label='Center'>
        <AreaAlignMidIcon sx={iconSx} />
      </ToggleButton>
      <ToggleButton value={AreaAlignment.BOTTOM} aria-label='Bottom'>
        <AreaAlignBotIcon sx={iconSx} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default Align;
