import {
  BaseNotification,
  NotificationType,
  SupportedLanguages,
} from '@/modules/Notifications/helpers/types';
import { useConfig } from '@/modules/common/hooks';
import { useAxios } from '@/modules/api/hooks/useAxios';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

export const useNotificationMessageApi = () => {
  const { t } = useTranslation('errors');
  const { api } = useConfig();
  const scopes = useMemo(() => [api.errorMessageService.scope], [api.errorMessageService.scope]);

  const axios = useAxios({
    baseURL: `${api.errorMessageService.url}`,
    scopes,
  });

  const getNotificationDetails = useCallback(
    async (code: string): Promise<BaseNotification> => {
      const id = uuid();
      try {
        if (!code) {
          return Promise.reject();
        }
        const response = await axios.get(`/retrieve_error_message/${code}`);
        
        return {
          id,
          timestamp: new Date(),
          type: response.data.severity.toLowerCase() as NotificationType,
          title: response.data.descriptions[0].title ?? t('notifications.default.title'),
          description:
            response.data.descriptions[0].description ?? t('notifications.default.description'),
          solution: response.data.descriptions[0].solution ?? '',
          readMoreUrl: response.data.readMoreUrl,
          severity: response.data.severity,
          read: false,
          code: response.data.code,
        };
      } catch (error) {
        return {
          id,
          timestamp: new Date(),
          read: false,
          title: t('notifications.default.title'),
          description: t('notifications.default.description'),
          solution: '',
          type: NotificationType.FATAL,
          readMoreUrl: '',
          code,
          severity: 'FATAL',
        };
      }
    },
    [t, axios],
  );

  return { getNotificationDetails };
};
