import { t } from 'i18next';
import { Vector2d } from 'konva/lib/types';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  DISTCON_SOURCE_SHAPE_TYPES,
  DistconTargetShapeTypes,
  getValidDistconTargetShapeTypes,
} from '@/store/recoil/workspace';
import { createCombinedConnectionId, getShapeId } from '@modules/connections/common/connectionId';
import {
  createConnectionPosition,
  getSourceAttachPoint,
} from '@modules/connections/distant/helpers/connections';
import { useShapeDisabling } from '@modules/flows/layout/hooks/useShapeDisabling';
import shapeAtom from '@recoil/shape';
import { toolButtonState } from '@recoil/tool';
import {
  activeDistantConnectionState,
  allDistantConnectionsSelector,
  allDistconIds,
  distcon,
} from '../store';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { getDefaultInOutChecked } from '@/modules/connections/connections/helpers/connections';

export const useDistantConnectionCallbacks = () => {
  const { enableAllShapes, enableOnlyShapesOfTypes } = useShapeDisabling();
  const { showSnackbar } = useSnackbarStore();
  const startDistantConnection = useRecoilCallback(
    ({ set, snapshot }) =>
      async (startShapeId: string, mousePosition: Vector2d) => {
        const fromShape = await snapshot.getPromise(shapeAtom(startShapeId));
        set(activeDistantConnectionState, {
          id: uuid(),
          from: createCombinedConnectionId(fromShape, mousePosition),
        });

        return {
          attachPoint: getSourceAttachPoint(fromShape, mousePosition),
        };
      },
    [],
  );

  const selectDistantConnectionTool = useRecoilCallback(
    ({ set }) =>
      async () =>
        set(toolButtonState, 'distcon'),
    [],
  );

  const endActiveDistantConnection = useRecoilCallback(
    ({ reset }) =>
      async () => {
        reset(activeDistantConnectionState);
        enableAllShapes();
      },
    [enableAllShapes],
  );

  const endDistantConnection = useRecoilCallback(
    ({ set, snapshot }) =>
      async (endShapeId: string, mousePosition: Vector2d) => {
        const activeDistantConnection = await snapshot.getPromise(activeDistantConnectionState);
        const from = await snapshot.getPromise(shapeAtom(getShapeId(activeDistantConnection.from)));
        const to = await snapshot.getPromise(shapeAtom(getShapeId(endShapeId)));
        const connectionPosition = createConnectionPosition(
          from,
          to,
          mousePosition,
          activeDistantConnection.from,
        );
        const newDistantConnection = {
          ...activeDistantConnection,
          ...connectionPosition,
          ...getDefaultInOutChecked(from.type, to.type),
        };

        set(allDistconIds, (state) => [...state, newDistantConnection.id]);
        set(distcon(newDistantConnection.id), newDistantConnection);
        await endActiveDistantConnection();
        return newDistantConnection;
      },
    [enableAllShapes, endActiveDistantConnection],
  );

  const isValidSource = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const shape = await snapshot.getPromise(shapeAtom(id));

        // here ok now
        return DISTCON_SOURCE_SHAPE_TYPES.includes(shape.type as string);
      },
    [],
  );

  const getActiveDistconValidTargetTypes = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const activeDistcon = await snapshot.getPromise(activeDistantConnectionState);
        const sourceShape = await snapshot.getPromise(shapeAtom(getShapeId(activeDistcon.from)));

        return getValidDistconTargetShapeTypes(sourceShape.type);
      },
    [],
  );

  const isValidTarget = useRecoilCallback(
    ({ set, snapshot }) =>
      async (sourceId: string, targetId: string) => {
        const [targetShape, validTargetTypes, existingDistcons] = await Promise.all([
          snapshot.getPromise(shapeAtom(getShapeId(targetId))),
          getActiveDistconValidTargetTypes(),
          snapshot.getPromise(allDistantConnectionsSelector),
        ]);

        // when target is a segment of the same highway
        if (getShapeId(sourceId) === getShapeId(targetId)) return false;

        const duplicateDistcon = existingDistcons.find(
          (connection) =>
            (connection.from === sourceId && connection.to === targetId) ||
            (connection.from === targetId && connection.to === sourceId),
        );

        if (duplicateDistcon) {
          showSnackbar(
            t(
              'errors:connection.duplicate_distant_connection',
              'A connection between this source and destination already exists',
            ),
          );
          return false;
        }

        if (!validTargetTypes.includes(targetShape.type as DistconTargetShapeTypes)) {
          return false;
        }

        return true;
      },
    [getActiveDistconValidTargetTypes],
  );

  const enableTargetShapesExclusively = useRecoilCallback(
    () => async () => {
      const validTargetTypes = await getActiveDistconValidTargetTypes();

      enableOnlyShapesOfTypes(validTargetTypes);
    },
    [enableOnlyShapesOfTypes, getActiveDistconValidTargetTypes],
  );

  const enableSourceShapesExclusively = useCallback(
    () => enableOnlyShapesOfTypes(DISTCON_SOURCE_SHAPE_TYPES),
    [enableOnlyShapesOfTypes],
  );

  return {
    endActiveDistantConnection,
    startDistantConnection,
    enableTargetShapesExclusively,
    enableSourceShapesExclusively,
    isValidTarget,
    isValidSource,
    selectDistantConnectionTool,
    endDistantConnection,
  };
};
