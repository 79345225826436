import { atom } from 'recoil';

import { LayerNames, Layers } from '@/modules/common/types/layers';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const layersAtom = atom<Layers>({
  key: 'layers',
  default: {
    [LayerNames.CONNECTIONS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_CONNECTIONS_HIDDEN),
      locked: false,
    },
    [LayerNames.ROADS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_ROADS_HIDDEN),
      locked: false,
    },
    [LayerNames.HIGHWAYS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_HIGHWAYS_HIDDEN),
      locked: false,
    },
    [LayerNames.ANGLED_HIGHWAYS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_ANGLED_HIGHWAYS_HIDDEN),
      locked: false,
    },
    [LayerNames.AREAS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_AREAS_HIDDEN),
      locked: false,
    },
    [LayerNames.INTAKE]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_INTAKE_HIDDEN),
      locked: false,
    },
    [LayerNames.DELIVERY]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_DELIVERY_HIDDEN),
      locked: false,
    },
    [LayerNames.STORAGE]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_STORAGE_HIDDEN),
      locked: false,
    },
    [LayerNames.CHARGING]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_CHARGING_HIDDEN),
      locked: false,
    },
    [LayerNames.PARKING]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_PARKING_HIDDEN),
      locked: false,
    },
    [LayerNames.MANUAL]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_MANUAL_HIDDEN),
      locked: false,
    },
    [LayerNames.MANUAL_EXIT]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_MANUAL_EXIT_HIDDEN),
      locked: false,
    },
    [LayerNames.MANUAL_ENTRY]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_MANUAL_ENTRY_HIDDEN),
      locked: false,
    },
    [LayerNames.PROCESS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_PROCESS_HIDDEN),
      locked: false,
    },
    [LayerNames.INTERIOR]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_INTERIOR_HIDDEN),
      locked: false,
    },
    [LayerNames.WALLS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_WALLS_HIDDEN),
      locked: false,
    },
    [LayerNames.REFERENCE]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_REFERENCES_HIDDEN),
      locked: false,
    },
    [LayerNames.OBSTACLES]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_OBSTACLES_HIDDEN),
      locked: false,
    },
    [LayerNames.FLOWS]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_FLOWS_HIDDEN),
      locked: false,
    },
    [LayerNames.WIFI]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_WIFI_HIDDEN),
      locked: false,
    },
    [LayerNames.NAMES]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_NAMES_HIDDEN),
      locked: false,
    },
    [LayerNames.AREA_NAMES]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_AREA_NAMES_HIDDEN),
      locked: false,
    },
    [LayerNames.HIGHWAY_NAMES]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_HIGHWAY_NAMES_HIDDEN),
      locked: false,
    },
    [LayerNames.OBSTACLE_NAMES]: {
      visible: !getUserPreference(UserPreferenceName.LAYER_OBSTACLE_NAMES_HIDDEN),
      locked: false,
    },
  },
});

export const invisibleElementsAtom = atom<Set<string>>({
  key: 'invisibleElements',
  default: new Set(),
});

export const lockedElementsAtom = atom<Set<string>>({
  key: 'lockedElements',
  default: new Set(),
});

export const invisibleNameLabelsAtom = atom<Set<string>>({
  key: 'invisibleNameLabels',
  default: new Set(),
});
