import { ProcessTwoEPShape, ProcessTwoEPShapeParameters } from '@/modules/processTwoEndPoint';
import {
  AreaShape,
  AreaShapeParameters,
  HighwayShape,
  ObstacleShape,
  PositionShape,
  WallShape,
  WallShapeParameters,
} from '../../../store/recoil/shape';
import { AREA_OVERFLOW } from '../../../store/recoil/workspace';
import { AngledHighwayShape } from '../../angledHighways/types';
import { isArea, isPosition } from '../helpers/shapes';
import {
  AngledHighwayShapePersisted,
  AreaShapePersisted,
  DTShapePersisted,
  HighwayShapePersisted,
  ObstacleShapePersisted,
  PositionShapePersisted,
  ProcessTwoEPShapePersisted,
  WallShapePersisted,
} from './floorPlan';
import {
  AreaAlignment,
  AreaDirection,
  AreaDistribution,
  AreaLoadCarrierOrientation,
  AreaParkingDirection,
  ControlPointsProperties,
  DTShape,
  DTShapeParameters,
  MaterialType,
  ProcessAreaOneEp,
  ShapeProperties,
  ShapeType,
} from './shapes';
import { StorageType } from './storage';
import { TemplateType } from '@/modules/common/types/templating';
import { ShapeGroup, legacyFlowGroup, SingleTypeGroup } from './shapeGroup';

type ObjWithStrKeys = Record<string, any>;

// #region local shape type guards

export const isAreaShape = (shape: DTShape): shape is AreaShape => shape && isArea(shape.type);
export const isPositionShape = (shape: DTShape): shape is PositionShape =>
  shape && isPosition(shape.type);
export const isHighwayShape = (shape: DTShape): shape is HighwayShape =>
  shape && shape.type === ShapeType.HIGHWAY;
export const isAngledHighwayShape = (shape: DTShape): shape is AngledHighwayShape =>
  shape && shape.type === ShapeType.HIGHWAY_ANGLED;
export const isObstacleShape = (shape: DTShape): shape is ObstacleShape =>
  shape && shape.type === ShapeType.OBSTACLE;
export const isWallShape = (shape: DTShape): shape is WallShape =>
  shape && shape.type === ShapeType.WALL;
export const isProcessAreaOneEp = (shape: DTShape): shape is ProcessAreaOneEp =>
  shape && shape.type === ShapeType.PROCESS_ONE_EP;
export const isProcessAreaTwoEp = (shape: DTShape): shape is ProcessTwoEPShape =>
  shape && shape.type === ShapeType.PROCESS_TWO_EP;
export const isPointsShape = (shape: DTShape): shape is WallShape | AngledHighwayShape | ProcessTwoEPShape =>
  shape && (isWallShape(shape) || isAngledHighwayShape(shape) || isProcessAreaTwoEp(shape));

// #endregion

// #region persisted shape type guards

export const isAreaShapePersisted = (shape: DTShapePersisted): shape is AreaShapePersisted =>
  shape && isArea(shape.type);
export const isPositionShapePersisted = (
  shape: DTShapePersisted,
): shape is PositionShapePersisted => shape && isPosition(shape.type);
export const isHighwayShapePersisted = (shape: DTShapePersisted): shape is HighwayShapePersisted =>
  shape && shape.type === ShapeType.HIGHWAY;
export const isAngledHighwayShapePersisted = (
  shape: DTShapePersisted,
): shape is AngledHighwayShapePersisted => shape && shape.type === ShapeType.HIGHWAY_ANGLED;
export const isObstacleShapePersisted = (
  shape: DTShapePersisted,
): shape is ObstacleShapePersisted => shape && shape.type === ShapeType.OBSTACLE;
export const isWallShapePersisted = (shape: DTShapePersisted): shape is WallShapePersisted =>
  shape && shape.type === ShapeType.WALL;
export const isProcessAreaTwoEpPersisted = (
  shape: DTShapePersisted,
): shape is ProcessTwoEPShapePersisted => shape && shape.type === ShapeType.PROCESS_TWO_EP;
export const isPointsShapePersisted = (shape: DTShapePersisted): shape is WallShapePersisted | AngledHighwayShapePersisted | ProcessTwoEPShape =>
  shape && (isWallShapePersisted(shape) || isAngledHighwayShapePersisted(shape) || isProcessAreaTwoEpPersisted(shape));

// #endregion

// #region shape property type guards

const shapePropertiesCompareValue: ShapeProperties = { x: 5, y: 5, width: 5, height: 5, r: 5 }
export const isShapeProperties = (value: ObjWithStrKeys): value is ShapeProperties => {
  if (!value) return false;

  const keys = Object.keys(shapePropertiesCompareValue);

  return keys.length === Object.keys(value).length && keys.every((key) => key in value);
};

const pointsPropertiesCompareValue: ControlPointsProperties = {
  controlPoints: [],
};

export const isPointsProperties = (
  value: ObjWithStrKeys,
): value is ControlPointsProperties => {
  if (!value) return false;

  const keys = Object.keys(pointsPropertiesCompareValue);
  
  return keys.length === Object.keys(value).length && keys.every((key) => key in value);
};

const processTwoEPPropertiesCompareValue: ControlPointsProperties = {
  controlPoints: [],
};
export const isProcessTwoEPProperties = (value: ObjWithStrKeys): value is ControlPointsProperties => {
  if (!value) return false;

  const keys = Object.keys(processTwoEPPropertiesCompareValue);

  return keys.length === Object.keys(value).length && keys.every((key) => key in value);
};

// #endregion

// #region shape parameter type guards

const areaShapeParametersCompareValue: AreaShapeParameters = {
  alignment: AreaAlignment.CENTER,
  direction: AreaDirection.DOWN,
  distribution: AreaDistribution.CENTER,
  gap: 500,
  gapHorizontal: 5,
  gapVertical: 5,
  loadCarrierOrientation: AreaLoadCarrierOrientation.SHORT_SIDE,
  margin: 5,
  multiDeep: false,
  parkingDirection: AreaParkingDirection.FORWARD,
  positionOverflow: AREA_OVERFLOW.CONTAIN,
  storageProperty: null,
  storageType: StorageType.SINGLE,
  supportedLoadCarriersIds: [],
  supportedVehicleIds: [],
  loadElevation: 0,
  operationTime: null,
  loadPlacement: null,
  applyLoadCarrierToLoadPositionRegexes: null,
  loadDuration: null,
  unloadDuration: null,
  blockDuration: null,
};
export const isAreaShapeParameters = (value: DTShapeParameters): value is AreaShapeParameters => {
  if (!value) return false;

  const keys = Object.keys(areaShapeParametersCompareValue);

  return keys.every((key) => key in value);
};

const wallShapeParametersCompareValue: WallShapeParameters = {
  direction: 90,
  height: 500,
  material: MaterialType.Concrete,
  width: 200,
};
export const isWallShapeParameters = (value: DTShapeParameters): value is WallShapeParameters => {
  if (!value) return false;

  const keys = Object.keys(wallShapeParametersCompareValue);

  return keys.length === Object.keys(value).length && keys.every((key) => key in value);
};

const processTwoEpParametersCompareValue: ProcessTwoEPShapeParameters = {
  gap: 1000,
  margin: 1000,
  supportedLoadCarriersIds: [],
  operationTime: 10,
  width: 1000,
  intakeParameters: {
    supportedVehicleIds: [],
    loadCarrierOrientation: AreaLoadCarrierOrientation.SHORT_SIDE,
    storageType: StorageType.SINGLE,
    // @ts-expect-error strictNullChecks. Pls fix me
    storageProperty: null,
    parkingDirection: AreaParkingDirection.FORWARD,
    // @ts-expect-error strictNullChecks. Pls fix me
    loadElevation: null,
    direction: AreaDirection.DOWN,
  },
  deliveryParameters: {
    supportedVehicleIds: [],
    loadCarrierOrientation: AreaLoadCarrierOrientation.SHORT_SIDE,
    storageType: StorageType.SINGLE,
    // @ts-expect-error strictNullChecks. Pls fix me
    storageProperty: null,
    parkingDirection: AreaParkingDirection.FORWARD,
    // @ts-expect-error strictNullChecks. Pls fix me
    loadElevation: null,
    direction: AreaDirection.DOWN,
  },
};
export const isProcessAreaTwoEpParameters = (
  value: DTShapeParameters,
): value is ProcessTwoEPShapeParameters => {
  if (!value) return false;

  const keys = Object.keys(processTwoEpParametersCompareValue);

  return keys.length === Object.keys(value).length && keys.every((key) => key in value);
};

// #endregion

// #region templating type guards

export const isTemplateType = (value: string): value is TemplateType =>
  Object.values(TemplateType).includes(value as TemplateType);

// #endregion

// #region legacy guards

export const isLegacyFlowGroup = (item: ShapeGroup | legacyFlowGroup): item is legacyFlowGroup => {
  if (
    (item as SingleTypeGroup).composition === undefined &&
    (item as SingleTypeGroup).interactivityMode === undefined
  )
    return true;

  return false;
};

// #endregion
