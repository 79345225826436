/**
 * The Id of shapes can be in a varity of ways
 * The shapes in the frontend has an id using only numbers and '-',
 * and all segemnts in an angled highway is regarded as a signle shape
 * The id sent to the FPS is more complicated
 * It consists firstly of the shape id from the frontend, then a segment id (if it is a angled highway),
 * and then a vehicle id
 *
 * Frontend shape id:
 * 6b2e974e-0dfd-4b69-944a-5ed1c2a52b71 (Intake area)
 * decb7d77-e6e5-4fe8-930a-e10fa2db16da (Angled Highway)
 * ShapeId
 *
 * Sent to the FPS:
 * 6b2e974e-0dfd-4b69-944a-5ed1c2a52b71_16 (Intake area)
 * decb7d77-e6e5-4fe8-930a-e10fa2db16da.0_16 (Angled Highway)
 * decb7d77-e6e5-4fe8-930a-e10fa2db16da.1_16 (Angled Highway)
 * decb7d77-e6e5-4fe8-930a-e10fa2db16da.2_16 (Angled Highway)
 * ShapeId.SegmentId_VehicleId
 *
 * This file provide functions to encode or decode these ids when mapping to the FPS
 */

export const encodeIdWithVehicleId = (
  id: string,
  vehicleDatabaseId: number,
  segmentId?: number,
) => {
  if (segmentId !== undefined) return `${id}.${segmentId}_${vehicleDatabaseId}`;

  return `${id}_${vehicleDatabaseId}`;
};

/*
  this "flag" is used when encoding creating an lpIdGenerator that serves multiple vehicles instead of one.
  by giving the lpIdGenerator for a specific vehicle the same name as for another vehicle, 
  the back-end (FPS) will know that both vehicles use the same load positions
*/
const MULTI_SPEC_VEHICLE_FLAG = 'shared';
export const encodeIdAsShared = (id: string) => `${id}_${MULTI_SPEC_VEHICLE_FLAG}`;
export const createMultiSpecSharedLpIdGeneratorName = (shapeName: string) =>
  `${shapeName}_${MULTI_SPEC_VEHICLE_FLAG}-LpIdGen`;

export const getSegmentIndex = (areaId: string): number => {
  // @ts-expect-error strictNullChecks. Pls fix me
  if (areaId.split('.').length <= 1) return null;

  return +`${areaId.split('.')[1]}`.split(`_`)[0];
};

export const encodeIdWithSegmentIndex = (shapeId: string, areaId: string) => {
  const segmentId = getSegmentIndex(areaId);
  if (segmentId == null) return shapeId;

  return `${shapeId}.${segmentId}`;
};

export const decodeShapeId = (id: string): string => id.split('.')[0];
