import {
  TextField,
  Paper,
  Typography,
  ListItemIcon,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
} from '@mui/material';
import { Stack } from '@mui/system';
import { memo, useState } from 'react';

import { ArrowSmallDown } from '@/assets/icons';
import { Theme } from '@/modules/common/types/general';
import { StyledTooltip } from '@/helpers/styles';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import { MultiSelectInputOption } from './MultiSelectInputOption';

type Option = {
  value: string;
  label: string;
};

type Props = {
  selectedOptions: string[];
  options: Option[];
  onItemClick: (value: string) => void;
  displayValue: string;
  disabled?: boolean;
};

function MultiSelectInputComponent({
  selectedOptions,
  options,
  onItemClick,
  displayValue,
  disabled = false,
}: Props) {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (value: string): void => {
    onItemClick(value);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack position='relative'>
        <TextField disabled={disabled} variant='outlined' value='' sx={{ opacity: 0 }}>
          {displayValue}
        </TextField>
        <StyledTooltip title={open ? undefined : displayValue}>
          <Paper
            onClick={disabled ? undefined : handleOpen}
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              color: 'rgba(0, 0, 0, 0.87)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              background: 'rgb(248, 248, 252)',
            }}
            elevation={0}
          >
            <Typography
              sx={{
                padding: '4px 12px',
                maxHeight: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {displayValue}
            </Typography>
            <ListItemIcon
              sx={{
                minWidth: (theme) => theme.spacing(3),
                fontSize: 24,
                transform: open ? 'rotate(180deg)' : undefined,
              }}
            >
              <ArrowSmallDown />
            </ListItemIcon>
          </Paper>
        </StyledTooltip>
      </Stack>
      <Popper
        anchorEl={anchorEl}
        placement='bottom-end'
        sx={{
          zIndex: 2000,
          width: 'fit-content',
          maxWidth: '360px',
          height: 'auto',
        }}
        className={theme === Theme.DARK ? 'dark' : ''}
        open={open}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}
          >
            <Paper
              sx={{
                'box-shadow':
                  '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',

                '&:hover': { cursor: 'default' },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map(({ label, value }) => (
                    <MultiSelectInputOption
                      key={value}
                      value={value}
                      label={label}
                      onClick={handleClick}
                      isSelected={selectedOptions.includes(value)}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export const MultiSelectInput = memo(MultiSelectInputComponent);
