import { Button } from '@mui/material';
import { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { modeSelector } from '@/modules/common/store/workspace';
import { LoadingType, WorkspaceMode } from '@/modules/common/types/general';
import { LassieGroup } from '@/modules/common/types/shapeGroup';
import { TemplateType } from '@/modules/common/types/templating';
import { useLoading } from '@/modules/common/hooks';
import { useFloorPlanState } from '@/modules/floorplan';
import { selectedGroupsSelector, selectedGroupIdsState } from '@/modules/shapeGroups';
import { useTemplateGenerationCallbacks } from '@/modules/templating';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useZoomButton } from '@/modules/workspace/components/ZoomButton/useZoomButton';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { TemplateNumberInput } from './TemplateNumberInput';

const LassiePropertiesComponent = () => {
  const { t } = useTranslation();
  const mode = useRecoilValue(modeSelector);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { saveFloorPlan } = useFloorPlanState();
  const lassieGroup = useRecoilValue(selectedGroupsSelector)[0] as LassieGroup;
  const { showLoader, hideLoader } = useLoading();
  const { zoomFit } = useZoomButton();
  const { createTemplate } = useTemplateGenerationCallbacks();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const setSelectedGroupIds = useSetRecoilState(selectedGroupIdsState);

  const [newGenerationParams, setNewGenerationParams] = useState({
    ...lassieGroup.generationParams,
  });

  const updatePreferences = useCallback(() => {
    if (lassieGroup.generationParams.numAisles !== newGenerationParams.numAisles)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_AISLES,
        newGenerationParams.numAisles,
        false,
      );
    if (lassieGroup.generationParams.numBays !== newGenerationParams.numBays)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_BAYS,
        newGenerationParams.numBays,
        false,
      );
    if (lassieGroup.generationParams.numShelves !== newGenerationParams.numShelves)
      updateUserPreference(
        UserPreferenceName.TEMPLATE_LASSIE_NUM_SHELVES,
        newGenerationParams.numShelves,
        false,
      );
  }, [
    updateUserPreference,
    lassieGroup.generationParams,
    newGenerationParams.numAisles,
    newGenerationParams.numBays,
    newGenerationParams.numShelves,
  ]);

  const regenerate = useCallback(async () => {
    showLoader(LoadingType.TRANSPARENT);
    const group = await createTemplate(TemplateType.LASSIE, newGenerationParams, lassieGroup.id);

    if (!group) {
      hideLoader();
      return;
    }

    setSelectedShapesIds(group.children);
    setSelectedGroupIds([group.id]);
    zoomFit();
    hideLoader();
    saveFloorPlan();
    updatePreferences();
  }, [
    showLoader,
    createTemplate,
    newGenerationParams,
    lassieGroup.id,
    setSelectedShapesIds,
    setSelectedGroupIds,
    zoomFit,
    hideLoader,
    updatePreferences,
    saveFloorPlan,
  ]);

  const onNumAislesChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numAisles: newValue }));
  }, []);
  const onNumBaysChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numBays: newValue }));
  }, []);
  const onNumShelvesChange = useCallback((newValue) => {
    setNewGenerationParams((current) => ({ ...current, numShelves: newValue }));
  }, []);

  const regenerationDisabled =
    newGenerationParams.numAisles === lassieGroup.generationParams.numAisles &&
    newGenerationParams.numBays === lassieGroup.generationParams.numBays &&
    newGenerationParams.numShelves === lassieGroup.generationParams.numShelves;

  return (
    <>
      <PropertiesLabel i18nkey='interface:properties.template.aisles'>
        <TemplateNumberInput
          value={newGenerationParams.numAisles}
          onChange={onNumAislesChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.bays'>
        <TemplateNumberInput
          value={newGenerationParams.numBays}
          onChange={onNumBaysChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.template.shelves'>
        <TemplateNumberInput
          value={newGenerationParams.numShelves}
          onChange={onNumShelvesChange}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </PropertiesLabel>
      <Button disabled={regenerationDisabled} onClick={regenerate}>
        {t('common:apply')}
      </Button>
    </>
  );
};

export const LassieProperties = memo(LassiePropertiesComponent);
