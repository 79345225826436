import { Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { SetupPropertiesSelectDialog } from '@/modules/setup/components/common/SetupPropertiesSelectDialog';
import { VehicleCard } from '@/modules/setup/components/VehicleTypes/VehicleTypeSelectDialog/VehicleCard';
import { UnifiedVehicle } from '@/modules/vehicles/types';
import { capitalizeFirstLetter } from '@/helpers/string';
import { queryItems } from '@/modules/common/helpers/search';
import { allVehiclesState } from '@/modules/vehicles';
import { MetaBar } from '@/modules/setup/components/VehicleTypes/VehicleTypeSelectDialog/MetaBar';

type Props = {
  open: boolean;
  selectedVehiclesIds: string[];
  onSelect: (types: string[]) => void;
  onClose: () => void;
};

export const VehicleTypeSelectDialog = ({
  open,
  selectedVehiclesIds,
  onClose,
  onSelect,
}: Props) => {
  const { t } = useTranslation(['interface']);
  const [innerSelectedVehiclesIds, setInnerSelectedVehiclesIds] = useState<string[]>([]);
  const allVehicles = useRecoilValue(allVehiclesState);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchHits, setSearchHits] = useState<UnifiedVehicle[]>([]);
  const [results, setResults] = useState<UnifiedVehicle[]>([]);

  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [selectedApplications, setSelectedApplications] = useState<string[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);

  // get distinct filter options based on vehicles data
  const { vendors, models, applications } = useMemo(() => {
    const filterSet = allVehicles.reduce(
      (acc, nextItem) => {
        acc.vendors.add(nextItem.vendor);
        acc.models.add(nextItem.model);
        if (nextItem.tags) {
          nextItem.tags
            .filter((tag) => tag.name === 'application')
            .forEach((application) => acc.applications.add(application.value));
        }

        return acc;
      },
      {
        vendors: new Set<string>(),
        models: new Set<string>(),
        applications: new Set<string>(),
      },
    );

    return {
      vendors: Array.from(filterSet.vendors),
      models: Array.from(filterSet.models),
      applications: Array.from(filterSet.applications),
    };
  }, [allVehicles]);

  // create options to populate filters with
  const { vendorOptions, applicationOptions, modelOptions } = useMemo(() => {
    const applicationOptionLabels = t(
      'setup.floorPlan.vehicle_types_select_dialog.filters.application.options',
      {
        returnObjects: true,
      },
    );

    return {
      vendorOptions: vendors.map((vendor) => ({
        label: capitalizeFirstLetter(vendor),
        value: vendor,
      })),
      applicationOptions: applications.map((application) => {
        let labelTranslation = applicationOptionLabels[application];

        if (!labelTranslation) {
          console.warn(
            `Unexpected value for vehicle application. Value "${application}" has no translations yet.`,
          );
        }

        return {
          label: labelTranslation || application,
          value: application,
        };
      }),
      modelOptions: models.map((model) => ({
        label: capitalizeFirstLetter(model),
        value: model,
      })),
    };
  }, [vendors, applications, models, t]);

  // apply property filters to search hits
  useEffect(() => {
    let tempResults = searchHits;

    if (selectedVendors.length > 0) {
      tempResults = tempResults.filter((item) => selectedVendors.includes(item.vendor));
    }

    if (selectedApplications.length > 0) {
      tempResults = tempResults.filter((item) =>
        item.tags
          .filter((tag) => tag.name === 'application')
          .some((tag) => selectedApplications.includes(tag.value)),
      );
    }

    if (selectedModels.length > 0) {
      tempResults = tempResults.filter((item) => selectedModels.includes(item.model));
    }

    setResults(tempResults);
  }, [setResults, searchHits, selectedVendors, selectedApplications, selectedModels]);

  useEffect(() => setInnerSelectedVehiclesIds(selectedVehiclesIds), [selectedVehiclesIds]);

  // apply search filter
  useEffect(() => {
    setSearchHits((currentHits) => {
      const newHits = queryItems(searchQuery, allVehicles, (item) => item.name);

      if (currentHits.length === 0 && newHits.length === 0) return currentHits;

      return newHits;
    });
  }, [allVehicles, searchQuery]);

  const onClick = useCallback((id: string) => {
    setInnerSelectedVehiclesIds((innerSelectedIds) => {
      if (innerSelectedIds.includes(id)) {
        return innerSelectedIds.filter((idA) => idA !== id);
      }
      return [...innerSelectedIds, id];
    });
  }, []);

  return (
    <SetupPropertiesSelectDialog
      title={t('setup.floorPlan.vehicle_types_select_dialog.title')}
      innerSelectedIds={innerSelectedVehiclesIds.map((i) => i.toString())}
      open={open}
      onSubmit={onSelect}
      onClose={onClose}
    >
      <Stack flexGrow={1} gap='10px'>
        <MetaBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchHitsAmount={results.length}
          vendorOptions={vendorOptions}
          selectedVendors={selectedVendors}
          setSelectedVendors={setSelectedVendors}
          applicationOptions={applicationOptions}
          selectedApplications={selectedApplications}
          setSelectedApplications={setSelectedApplications}
          modelOptions={modelOptions}
          selectedModels={selectedModels}
          setSelectedModels={setSelectedModels}
        />

        {results.length === 0 ? (
          <Stack justifyContent='center' flexGrow={1}>
            <Typography align='center' fontSize={14}>
              {t(
                'setup.floorPlan.vehicle_types_select_dialog.no_vehicles_found',
                'No vehicles found',
              )}
            </Typography>
          </Stack>
        ) : (
          <Stack
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 3 }}
              padding='0px 40px 40px 40px'
              columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            >
              {results.map((item) => (
                <Grid
                  item
                  xs={1}
                  key={item.databaseId}
                  sx={{
                    '@media (min-width: 1700px)': {
                      maxWidth: '16.6666%',
                    },
                    '@media (min-width: 2000px)': {
                      maxWidth: '14.28%',
                    },
                    '@media (min-width: 2250px)': {
                      maxWidth: '12.5%',
                    },
                    '@media (min-width: 2800px)': {
                      maxWidth: '10%',
                    },
                  }}
                >
                  <VehicleCard
                    id={item.id}
                    name={item.name}
                    selected={innerSelectedVehiclesIds.includes(item.id)}
                    imageUrl={item.image.url}
                    onClick={onClick}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
    </SetupPropertiesSelectDialog>
  );
};
