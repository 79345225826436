import { memo } from 'react';
import { Trans } from 'react-i18next';
import {
  areaMenuItems,
  buildingMenuItems,
  roadMenuItems,
  templateMenuItems,
} from '@/components/Toolbar3/constants';
import { WorkspaceMode } from '@modules/common/types/general';
import { Module } from '@modules/common/types/navigation';
import { ToolState } from '@modules/common/types/tools';
import { ShapeType } from '../../modules/common/types/shapes';
import { ToolMenuItem } from '@/store/recoil/tool';

import { ToolButton } from './ToolButton';
import ToolMenu from './ToolMenu';

type Props = {
  nav: Module;
  mode: WorkspaceMode;
};

const updateMenuItems = (items: ToolMenuItem[], disabledShapes: ShapeType[]): ToolMenuItem[] =>
  items.map((item) => {
    switch (item.type) {
      case 'tool':
        return disabledShapes.includes(item.id as ShapeType) ? { ...item, disabled: true } : item;
      case 'menu':
        return { ...item, items: updateMenuItems(item.items, disabledShapes) };
      default:
        return item;
    }
  });

export const ToolsetMain = memo(({ nav, mode }: Props) => {
  if (![Module.LAYOUT, Module.SALES_CANVAS].includes(nav)) return null;

  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <>
      <ToolMenu disabled={disabled} items={buildingMenuItems} initialBtnIconName={ShapeType.WALL} />
      <ToolMenu disabled={disabled} items={areaMenuItems} initialBtnIconName={ShapeType.INTAKE} />
      <ToolMenu disabled={disabled} items={roadMenuItems} initialBtnIconName={ShapeType.HIGHWAY} />
      <ToolMenu disabled={disabled} items={templateMenuItems} initialBtnIconName='template' />
      <ToolButton
        type={ToolState.FLOW}
        disabled={disabled}
        tooltipLabel={
          <Trans i18nKey='toolbar.flow_tool.tooltip' ns='interface' components={{ s: <span /> }} />
        }
        icon={ToolState.FLOW}
      />
      <ToolButton
        type={ToolState.DISTCON}
        disabled={disabled}
        tooltipLabel={
          <Trans
            i18nKey='toolbar.distcon_tool.tooltip'
            ns='interface'
            components={{ s: <span /> }}
          />
        }
        icon={ToolState.DISTCON}
      />
    </>
  );
});
