import { theme } from '@/modules/common/components/theme';
import { DownloadIcon } from '@/assets/icons';
import { IconButton, Box, Stack } from '@mui/material';
import getIcon from '@/components/Toolbar3/getIcons';
import { useFloorPlanFile } from '@/modules/floorplan';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { useTranslation } from 'react-i18next';
import { type BaseNotification as NotificationInterface } from '@/modules/Notifications/helpers/types';

const iconButtonStyles = {
  transition: 'background-color 0.3s ease, border-radius 0.3s ease',
  width: '18px',
  height: '18px',

  padding: 0,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '2px',
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: '#DFE5FC',
    borderRadius: '2px',
  },
};

const NotificationSeverityActions = ({
  notification,
}: {
  notification?: NotificationInterface;
}) => {
  const { downloadFloorplan } = useFloorPlanFile();

  const severityConfig = {
    fatal: {
      actions: [
        {
          tooltipKey: 'interface:notifications.downloadFloorplan',
          icon: <DownloadIcon sx={{ color: theme.palette.others.black }} />,
          onClick: () => downloadFloorplan(),
          boxScale: 'scale(0.7)',
        },
        {
          tooltipKey: 'interface:notifications.contactSupport',
          icon: getIcon('contact'),
          onClick: () => window.open('https://t-hive.freshdesk.com/support/tickets/new', '_blank'),
          sx: { mr: '16px' },
        },
      ],
    },
    error: {
      actions: [
        ...(notification && notification.readMoreUrl !== 'null'
          ? [
              {
                tooltipKey: 'interface:notifications.readMore',
                icon: getIcon('readMore'),
                onClick: () =>
                  notification.readMoreUrl && window.open(notification.readMoreUrl, '_blank'),
                sx: { mr: '16px' },
              },
            ]
          : []),
      ],
    },
  };

  if (notification) {
    const normalize = notification.severity.toLowerCase();
    const config = severityConfig[normalize];
    if (!config || !Array.isArray(config.actions)) {
      return null;
    }
    return <SeverityAction config={config} />;
  }
};

const SeverityAction = ({ config }) => {
  const { t } = useTranslation(['interface']);
  return (
    <Stack
      direction='row'
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
        gap: '8px',
        width: '100%',
      }}
    >
      {config.actions.map((action) => (
        <StyledTooltip key={action.tooltipKey} title={t(action.tooltipKey)} placement='top-end'>
          <IconButton
            size='small'
            disableRipple
            disableFocusRipple
            sx={{
              ...iconButtonStyles,
              ...action.sx,
            }}
            onClick={action.onClick}
          >
            <Box
              sx={{
                transform: action.boxScale || 'scale(1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {action.icon}
            </Box>
          </IconButton>
        </StyledTooltip>
      ))}
    </Stack>
  );
};

export default NotificationSeverityActions;
