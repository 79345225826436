import { memo, useRef } from 'react';
import { SelectInput } from '@/modules/common/components/inputs';
import { modeSelector } from '@/modules/common/store/workspace';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaLoadAction } from '@/modules/common/types/shapes';
import { areasLoadAction } from '@/components/PropertiesPanel/store/area';
import { useFloorPlanState } from '@/modules/floorplan';
import { useTranslation } from 'react-i18next';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { UserPreferenceName } from '@/modules/userPreferences';
import useConfirm from '@/modules/common/components/useConfirm';
import { Stack } from '@mui/system';
import { useFlow } from '@/modules/flows';

const LoadActionProperties = () => {
  const mode = useRecoilValue(modeSelector);
  const { saveFloorPlan } = useFloorPlanState();
  const loadAction = useRecoilValue(areasLoadAction);
  const { t } = useTranslation('interface');
  const { updateUserPreference } = useUpdateUserPreferences();
  const { confirm, Dialog } = useConfirm();
  const optionLabels = t('properties.area.load_action_options', {
    returnObjects: true,
  });
  const ref = useRef<HTMLDivElement>(null);
  const { deleteFlows, getRelatedFlowIdsOfShapes, getFlow } = useFlow();

  const loadActionTypes = [
    { label: optionLabels[AreaLoadAction.LOAD], value: AreaLoadAction.LOAD },
    { label: optionLabels[AreaLoadAction.UNLOAD], value: AreaLoadAction.UNLOAD },
    { label: optionLabels[AreaLoadAction.LOAD_UNLOAD], value: AreaLoadAction.LOAD_UNLOAD },
  ];

  const onLoadActionChange = useRecoilCallback(
    ({ snapshot, set }) =>
      async (value: AreaLoadAction) => {
        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        if (selectedShapes.length === 0) return;

        const { id } = selectedShapes[0];
        const flowIds = await getRelatedFlowIdsOfShapes([id]);
        const flows = await Promise.all(flowIds.map(getFlow));

        if (flows.length > 0 && value !== AreaLoadAction.LOAD_UNLOAD) {
          const isLoadAction = value === AreaLoadAction.LOAD;

          const confirmed = await confirm(
            t('properties.area.change_load_action_popup.label'),
            t('properties.area.change_load_action_popup.change_loadAction', {
              loadAction: isLoadAction ? 'Pickup' : 'Dropoff',
              removedFlows: isLoadAction ? 'Intake' : 'Delivery',
            }),
          );

          if (!confirmed) return;

          const flowsToDelete = flows.filter((f) =>
            isLoadAction ? f.deliveryFlowStop.id === id : f.intakeFlowStop.id === id,
          );

          if (flowsToDelete.length > 0) {
            deleteFlows(flowsToDelete.map((f) => f.id));
          }
        }

        updateUserPreference(UserPreferenceName.MANUAL_LOAD_ACTION, value);
        set(areasLoadAction, value);
        saveFloorPlan();
      },
    [saveFloorPlan],
  );

  return (
    <>
      {/* @ts-expect-error strictNullChecks. Pls fix me */}
      <Dialog anchor={ref} offset={[0, 100]} />
      <Stack ref={ref}>
        <SelectInput
          options={loadActionTypes}
          onChange={onLoadActionChange}
          value={loadAction}
          disabled={mode !== WorkspaceMode.EDITABLE}
        />
      </Stack>
    </>
  );
};
const LoadAction = memo(LoadActionProperties);
export default LoadAction;
