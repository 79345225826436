import {
  DEFAULT_SORTING_CRITERIA,
  DEFAULT_SORT_ORDER_OPTIONS,
  FloorplanViewMode,
} from '@/modules/floorplanAdmin/sortingMenu/constants';
import { Theme, Unit } from '@modules/common/types/general';
import {
  AreaDirection,
  AreaDistribution,
  AreaLoadCarrierOrientation,
  AreaLoadPlacement,
  AreaLoadAction,
  MaterialType,
  ObstacleType,
} from '@modules/common/types/shapes';
import {
  ANGLED_HIGHWAY_DEFAULT_WIDTH,
  HIGHWAY_DEFAULT_GAP,
  HIGHWAY_DEFAULT_MARGIN,
  HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT,
} from '../common/constants/shapes';
import { coverAreas, propagations } from '../wifisimulation/constants';
import { OrderDistributionStrategy } from '@/modules/simulation/helpers/types';
import { ShuttleRackEntrySide } from '@/modules/common/types/shapeGroup';

export enum UserPreferenceName {
  // Building
  BUILDING_WALL_THICKNESS = 'building.wall_thickness',
  BUILDING_WALL_HEIGHT = 'building.wall_height',
  BUILDING_WALL_MATERIAL = 'building.wall_material',
  BUILDING_OBSTACLE_TYPE = 'building.obstacle_type',
  BUILDING_OBSTACLE_HEIGHT = 'building.obstacle_height',

  // General
  GENERAL_THEME = 'general.theme',
  GENERAL_UNIT = 'general.unit',

  // Intake
  INTAKE_DIRECTION = 'intake.direction',
  INTAKE_DISTRIBUTION = 'intake.distribution',
  INTAKE_GAP = 'intake.gap',
  INTAKE_MARGIN = 'intake.margin',
  INTAKE_CARRIER_SIDE = 'intake.carrier_side',
  INTAKE_LOAD_DURATION = 'intake.load_duration',

  // Delivery
  DELIVERY_DIRECTION = 'delivery.direction',
  DELIVERY_DISTRIBUTION = 'delivery.distribution',
  DELIVERY_GAP = 'delivery.gap',
  DELIVERY_MARGIN = 'delivery.margin',
  DELIVERY_CARRIER_SIDE = 'delivery.carrier_side',
  DELIVERY_UNLOAD_DURATION = 'delivery.unload_duration',

  // Storage
  STORAGE_DIRECTION = 'storage.direction',
  STORAGE_DISTRIBUTION = 'storage.distribution',
  STORAGE_GAP = 'storage.gap',
  STORAGE_MARGIN = 'storage.margin',
  STORAGE_CARRIER_SIDE = 'storage.carrier_side',
  STORAGE_LOAD_DURATION = 'storage.load_duration',
  STORAGE_UNLOAD_DURATION = 'storage.unload_duration',

  // Process
  PROCESS_DIRECTION = 'process.direction',
  PROCESS_DISTRIBUTION = 'process.distribution',
  PROCESS_GAP = 'process.gap',
  PROCESS_MARGIN = 'process.margin',
  PROCESS_CARRIER_SIDE = 'process.carrier_side',
  PROCESS_OPERATION_TIME = 'process.operation_time',
  PROCESS_LOAD_PLACEMENT = 'process.load_placement',
  PROCESS_WIDTH = 'process.width',
  PROCESS_LOAD_DURATION = 'process.load_duration',
  PROCESS_UNLOAD_DURATION = 'process.unload_duration',

  // Handover
  HANDOVER_DIRECTION = 'handover.direction',
  HANDOVER_DISTRIBUTION = 'handover.distribution',
  HANDOVER_GAP = 'handover.gap',
  HANDOVER_MARGIN = 'handover.margin',
  HANDOVER_CARRIER_SIDE = 'handover.carrier_side',
  HANDOVER_OPERATION_TIME = 'handover.operation_time',
  HANDOVER_LOAD_PLACEMENT = 'handover.load_placement',

  // Charging
  CHARGING_DIRECTION = 'charging.direction',
  CHARGING_DISTRIBUTION = 'charging.distribution',
  CHARGING_GAP = 'charging.gap',
  CHARGING_MARGIN = 'charging.margin',
  CHARGING_PRIORITY = 'charging.priority',

  // Parking
  PARKING_DIRECTION = 'parking.direction',
  PARKING_DISTRIBUTION = 'parking.distribution',
  PARKING_GAP = 'parking.gap',
  PARKING_MARGIN = 'parking.margin',
  PARKING_PRIORITY = 'parking.priority',

  // Manual
  MANUAL_LOAD_ACTION = 'manual.load_action',
  MANUAL_LOAD_DURATION = 'manual.load_duration',
  MANUAL_UNLOAD_DURATION = 'manual.unload_duration',

  // Manual Exit
  MANUAL_EXIT_DIRECTION = 'manual_exit.direction',
  MANUAL_EXIT_DISTRIBUTION = 'manual_exit.distribution',
  MANUAL_EXIT_GAP = 'manual_exit.gap',
  MANUAL_EXIT_MARGIN = 'manual_exit.margin',
  MANUAL_EXIT_BLOCK_DURATION = 'manual_exit.block_duration',

  // Manual Entry
  MANUAL_ENTRY_DIRECTION = 'manual_entry.direction',
  MANUAL_ENTRY_DISTRIBUTION = 'manual_entry.distribution',
  MANUAL_ENTRY_GAP = 'manual_entry.gap',
  MANUAL_ENTRY_MARGIN = 'manual_entry.margin',
  MANUAL_ENTRY_BLOCK_DURATION = 'manual_entry.block_duration',

  // Road (regular & angled highway)
  HIGHWAY_GAP = 'highway.gap',
  HIGHWAY_MARGIN = 'highway.margin',
  HIGHWAY_MARGIN_TO_CROSSING = 'highway.margin_to_crossing',

  // Angled Highway
  HIGHWAY_WIDTH = 'highway.width',

  // Layers
  LAYER_CONNECTIONS_HIDDEN = 'layer.connections.hidden',
  LAYER_FLOWS_HIDDEN = 'layer.flows.hidden',
  LAYER_FLOWS_OPACITY = 'layer.flows.opacity',
  LAYER_INTERIOR_HIDDEN = 'layer.interior.hidden',
  LAYER_WALLS_HIDDEN = 'layer.walls.hidden',
  LAYER_OBSTACLES_HIDDEN = 'layer.obstacles.hidden',
  LAYER_AREAS_HIDDEN = 'layer.areas.hidden',
  LAYER_INTAKE_HIDDEN = 'layer.intake.hidden',
  LAYER_DELIVERY_HIDDEN = 'layer.delivery.hidden',
  LAYER_STORAGE_HIDDEN = 'layer.storage.hidden',
  LAYER_CHARGING_HIDDEN = 'layer.charging.hidden',
  LAYER_PARKING_HIDDEN = 'layer.parking.hidden',
  LAYER_MANUAL_HIDDEN = 'layer.manual.hidden',
  LAYER_MANUAL_EXIT_HIDDEN = 'layer.manual_exit.hidden',
  LAYER_MANUAL_ENTRY_HIDDEN = 'layer.manual_entry.hidden',
  LAYER_PROCESS_HIDDEN = 'layer.process.hidden',
  LAYER_ROADS_HIDDEN = 'layer.roads.hidden',
  LAYER_HIGHWAYS_HIDDEN = 'layer.highways.hidden',
  LAYER_ANGLED_HIGHWAYS_HIDDEN = 'layer.angled_highways.hidden',
  LAYER_REFERENCES_HIDDEN = 'layer.references.hidden',
  LAYER_WIFI_HIDDEN = 'layer.wifi.hidden',
  LAYER_NAMES_HIDDEN = 'layer.names.hidden',
  LAYER_AREA_NAMES_HIDDEN = 'layer.names.area.hidden',
  LAYER_HIGHWAY_NAMES_HIDDEN = 'layer.names.highway.hidden',
  LAYER_OBSTACLE_NAMES_HIDDEN = 'layer.names.obstacle.hidden',

  // Reference
  REFERENCE_OPACITY = 'reference.opacity',

  // Simulation
  SIMULATION_DURATION = 'simulation.duration',
  SIMULATION_LOAD_TIME = 'simulation.load_time',
  SIMULATION_UNLOAD_TIME = 'simulation.unload_time',
  SIMULATION_TRAFFIC_MANAGEMENT_DISABLED = 'simulation.traffic_management_disabled',
  SIMULATION_FAIL_ON_NO_ROUTE_FOUND = 'simulation.fail_on_no_route_found',
  SIMULATION_MANUAL_INTERVENTION = 'simulation.manual_intervention',
  SIMULATION_OBSTRUCTION_TIMEOUT = 'simulation.obstruction_timeout',
  SIMULATION_ORDER_DISTRIBUTION_STRATEGY = 'simulation.order_distribution_strategy',
  // SIMULATION_TRANSPORT_WINDOW = 'simulation.transport_window', // legacy. Not to be reused

  // Nudge amount
  NUDGE_AMOUNT = 'nudge_amount',

  // Show ruler
  SHOW_RULER = 'show_ruler',

  // Wi-Fi Simulation
  WIFI_PROPAGATION = 'wifi.propagation',
  WIFI_COVER_AREA = 'wifi.cover_area',

  // Floorplan admin
  FLOORPLAN_ADMIN_SORT_BY = 'floorplanAdmin.sort_by',
  FLOORPLAN_ADMIN_SORT_ORDER = 'floorplanAdmin.sort_order',
  FLOORPLAN_ADMIN_VIEW_MODE = 'floorplanAdmin.view_mode',

  // templates - lassie
  TEMPLATE_LASSIE_NUM_AISLES = 'template.lassie.num_aisles',
  TEMPLATE_LASSIE_NUM_BAYS = 'template.lassie.num_bays',
  TEMPLATE_LASSIE_NUM_SHELVES = 'template.lassie.num_shelves',
  // template - shuttle rack
  TEMPLATE_SHUTTLE_RACK_NUM_COLUMNS = 'template.shuttlerack.num_columns',
  TEMPLATE_SHUTTLE_RACK_NUM_LEVELS = 'template.shuttlerack.num_levels',
  TEMPLATE_SHUTTLE_RACK_NUM_DEEP = 'template.shuttlerack.num_deep',
  TEMPLATE_SHUTTLE_ENTRY_SIDE = 'template.shuttlerack.entry_side',
}

export const DEFAULT_USER_PREFERENCES = {
  // General
  [UserPreferenceName.GENERAL_THEME]: Theme.LIGHT,
  [UserPreferenceName.GENERAL_UNIT]: Unit.MILLIMETERS,

  // Reference image
  [UserPreferenceName.REFERENCE_OPACITY]: 30,

  // Building
  [UserPreferenceName.BUILDING_WALL_THICKNESS]: 50,
  [UserPreferenceName.BUILDING_WALL_HEIGHT]: 1000,
  [UserPreferenceName.BUILDING_WALL_MATERIAL]: MaterialType.Concrete,
  [UserPreferenceName.BUILDING_OBSTACLE_TYPE]: ObstacleType.Pillar,
  [UserPreferenceName.BUILDING_OBSTACLE_HEIGHT]: 1,

  // Intake
  [UserPreferenceName.INTAKE_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.INTAKE_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.INTAKE_GAP]: 1250,
  [UserPreferenceName.INTAKE_MARGIN]: 500,
  [UserPreferenceName.INTAKE_CARRIER_SIDE]: AreaLoadCarrierOrientation.SHORT_SIDE,
  [UserPreferenceName.INTAKE_LOAD_DURATION]: 0,

  // Delivery
  [UserPreferenceName.DELIVERY_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.DELIVERY_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.DELIVERY_GAP]: 1250,
  [UserPreferenceName.DELIVERY_MARGIN]: 500,
  [UserPreferenceName.DELIVERY_CARRIER_SIDE]: AreaLoadCarrierOrientation.SHORT_SIDE,
  [UserPreferenceName.DELIVERY_UNLOAD_DURATION]: 0,

  // Storage
  [UserPreferenceName.STORAGE_DIRECTION]: AreaDirection.DOWN,
  [UserPreferenceName.STORAGE_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.STORAGE_GAP]: 1250,
  [UserPreferenceName.STORAGE_MARGIN]: 500,
  [UserPreferenceName.STORAGE_CARRIER_SIDE]: AreaLoadCarrierOrientation.SHORT_SIDE,
  [UserPreferenceName.STORAGE_LOAD_DURATION]: 0,
  [UserPreferenceName.STORAGE_UNLOAD_DURATION]: 0,

  // Process
  [UserPreferenceName.PROCESS_DIRECTION]: AreaDirection.DOWN,
  [UserPreferenceName.PROCESS_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.PROCESS_GAP]: 1250,
  [UserPreferenceName.PROCESS_MARGIN]: 500,
  [UserPreferenceName.PROCESS_CARRIER_SIDE]: AreaLoadCarrierOrientation.SHORT_SIDE,
  [UserPreferenceName.PROCESS_OPERATION_TIME]: 15,
  [UserPreferenceName.PROCESS_LOAD_PLACEMENT]: AreaLoadPlacement.OFF,
  [UserPreferenceName.PROCESS_WIDTH]: 2000,
  [UserPreferenceName.PROCESS_LOAD_DURATION]: 0,
  [UserPreferenceName.PROCESS_UNLOAD_DURATION]: 0,

  // Handover
  [UserPreferenceName.HANDOVER_DIRECTION]: AreaDirection.DOWN,
  [UserPreferenceName.HANDOVER_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.HANDOVER_GAP]: 1250,
  [UserPreferenceName.HANDOVER_MARGIN]: 500,
  [UserPreferenceName.HANDOVER_CARRIER_SIDE]: AreaLoadCarrierOrientation.SHORT_SIDE,
  [UserPreferenceName.HANDOVER_OPERATION_TIME]: 15,
  [UserPreferenceName.HANDOVER_LOAD_PLACEMENT]: AreaLoadPlacement.OFF,

  // Charging
  [UserPreferenceName.CHARGING_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.CHARGING_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.CHARGING_GAP]: 1250,
  [UserPreferenceName.CHARGING_MARGIN]: 500,
  [UserPreferenceName.CHARGING_PRIORITY]: 1,

  // Parking
  [UserPreferenceName.PARKING_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.PARKING_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.PARKING_GAP]: 1250,
  [UserPreferenceName.PARKING_MARGIN]: 500,
  [UserPreferenceName.PARKING_PRIORITY]: 1,

  // Manual
  [UserPreferenceName.MANUAL_LOAD_ACTION]: AreaLoadAction.LOAD,
  [UserPreferenceName.MANUAL_LOAD_DURATION]: 0,
  [UserPreferenceName.MANUAL_UNLOAD_DURATION]: 0,

  // Manual Exit
  [UserPreferenceName.MANUAL_EXIT_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.MANUAL_EXIT_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.MANUAL_EXIT_GAP]: 1250,
  [UserPreferenceName.MANUAL_EXIT_MARGIN]: 500,
  [UserPreferenceName.MANUAL_EXIT_BLOCK_DURATION]: 0,

  // Manual Entry
  [UserPreferenceName.MANUAL_ENTRY_DIRECTION]: AreaDirection.UP,
  [UserPreferenceName.MANUAL_ENTRY_DISTRIBUTION]: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
  [UserPreferenceName.MANUAL_ENTRY_GAP]: 1250,
  [UserPreferenceName.MANUAL_ENTRY_MARGIN]: 500,
  [UserPreferenceName.MANUAL_ENTRY_BLOCK_DURATION]: 0,

  // Road (regular & angled highway)
  [UserPreferenceName.HIGHWAY_GAP]: HIGHWAY_DEFAULT_GAP,
  [UserPreferenceName.HIGHWAY_MARGIN]: HIGHWAY_DEFAULT_MARGIN,
  [UserPreferenceName.HIGHWAY_MARGIN_TO_CROSSING]: HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_DEFAULT,

  // Angled Highway
  [UserPreferenceName.HIGHWAY_WIDTH]: ANGLED_HIGHWAY_DEFAULT_WIDTH,

  // Layers
  [UserPreferenceName.LAYER_CONNECTIONS_HIDDEN]: true,
  [UserPreferenceName.LAYER_NAMES_HIDDEN]: true,
  [UserPreferenceName.LAYER_FLOWS_HIDDEN]: false,
  [UserPreferenceName.LAYER_FLOWS_OPACITY]: 30,
  [UserPreferenceName.LAYER_INTERIOR_HIDDEN]: false,
  [UserPreferenceName.LAYER_WALLS_HIDDEN]: false,
  [UserPreferenceName.LAYER_OBSTACLES_HIDDEN]: false,
  [UserPreferenceName.LAYER_AREAS_HIDDEN]: false,
  [UserPreferenceName.LAYER_INTAKE_HIDDEN]: false,
  [UserPreferenceName.LAYER_DELIVERY_HIDDEN]: false,
  [UserPreferenceName.LAYER_STORAGE_HIDDEN]: false,
  [UserPreferenceName.LAYER_CHARGING_HIDDEN]: false,
  [UserPreferenceName.LAYER_PARKING_HIDDEN]: false,
  [UserPreferenceName.LAYER_MANUAL_HIDDEN]: false,
  [UserPreferenceName.LAYER_MANUAL_EXIT_HIDDEN]: false,
  [UserPreferenceName.LAYER_MANUAL_ENTRY_HIDDEN]: false,
  [UserPreferenceName.LAYER_PROCESS_HIDDEN]: false,
  [UserPreferenceName.LAYER_ROADS_HIDDEN]: false,
  [UserPreferenceName.LAYER_HIGHWAYS_HIDDEN]: false,
  [UserPreferenceName.LAYER_ANGLED_HIGHWAYS_HIDDEN]: false,
  [UserPreferenceName.LAYER_REFERENCES_HIDDEN]: false,
  [UserPreferenceName.LAYER_WIFI_HIDDEN]: false,
  [UserPreferenceName.LAYER_AREA_NAMES_HIDDEN]: false,
  [UserPreferenceName.LAYER_HIGHWAY_NAMES_HIDDEN]: false,
  [UserPreferenceName.LAYER_OBSTACLE_NAMES_HIDDEN]: false,

  // Simulation
  [UserPreferenceName.SIMULATION_DURATION]: 8,
  [UserPreferenceName.SIMULATION_LOAD_TIME]: 15,
  [UserPreferenceName.SIMULATION_UNLOAD_TIME]: 15,
  [UserPreferenceName.SIMULATION_TRAFFIC_MANAGEMENT_DISABLED]: false,
  [UserPreferenceName.SIMULATION_FAIL_ON_NO_ROUTE_FOUND]: false,
  [UserPreferenceName.SIMULATION_MANUAL_INTERVENTION]: true,
  [UserPreferenceName.SIMULATION_OBSTRUCTION_TIMEOUT]: 45,
  [UserPreferenceName.SIMULATION_ORDER_DISTRIBUTION_STRATEGY]:
    'DISTANCE' as OrderDistributionStrategy,

  // Nudge amount
  [UserPreferenceName.NUDGE_AMOUNT]: 10,

  // Show ruler
  [UserPreferenceName.SHOW_RULER]: false,

  // Wi-Fi Simulation
  [UserPreferenceName.WIFI_PROPAGATION]: propagations[0].name,
  [UserPreferenceName.WIFI_COVER_AREA]: coverAreas[0].name,

  // floorplan admin
  [UserPreferenceName.FLOORPLAN_ADMIN_VIEW_MODE]: FloorplanViewMode.GRID,
  [UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY]: DEFAULT_SORTING_CRITERIA,
  [UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER]:
    DEFAULT_SORT_ORDER_OPTIONS[DEFAULT_SORTING_CRITERIA],

  // template - lassie
  [UserPreferenceName.TEMPLATE_LASSIE_NUM_AISLES]: 2,
  [UserPreferenceName.TEMPLATE_LASSIE_NUM_BAYS]: 3,
  [UserPreferenceName.TEMPLATE_LASSIE_NUM_SHELVES]: 12,
  // template - shuttle rack
  [UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_COLUMNS]: 12,
  [UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_LEVELS]: 8,
  [UserPreferenceName.TEMPLATE_SHUTTLE_RACK_NUM_DEEP]: 10,
  [UserPreferenceName.TEMPLATE_SHUTTLE_ENTRY_SIDE]: ShuttleRackEntrySide.BOTH,
};
