import { selector, selectorFamily } from 'recoil';

import { PROCESS_MIN_WIDTH } from '@/modules/common/constants/shapes';
import { AreaDirection, AreaEndpointDirection, AreaLoadCarrierOrientation, AreaParkingDirection } from '@/modules/common/types/shapes';
import { ProcessTwoEPEndParameters, ProcessTwoEPShapeParameters } from '@/modules/processTwoEndPoint/types';
import { unitConverterSelector } from '@/store/recoil/workspace';
import {
  findParameterValue,
  supportsVehicleTypes
} from '@modules/common/helpers/shapes';
import { isProcessAreaTwoEp } from '@modules/common/types/guards';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import {
  shapeParameter
} from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';
import { StorageType } from '@/modules/common/types/storage';
import { SideLoadingProperty, StorageProperty } from '@/store/recoil/shape/types/area';
import { DEFAULT_SIDE_LOADING } from '@/modules/common/constants/storage';

export const processWidthState = selector<number>({
  key: 'propertyPanelProcessWidthState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'width'),
  set: ({ get, set }, value: number) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;
      if (
        !valueInMillimeter ||
        Number.isNaN(valueInMillimeter) ||
        valueInMillimeter < PROCESS_MIN_WIDTH
      ) {
        const newParams = {
          ...shape.parameters,
          width: PROCESS_MIN_WIDTH,
        };
        set(shapeParameter(shape.id), newParams);
      } else {
        const newParams = {
          ...shape.parameters,
          width: valueInMillimeter,
        };
        set(shapeParameter(shape.id), newParams);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processIntakeProperty = selector<ProcessTwoEPEndParameters>({
  key: 'propertiesPanelProcessIntakeProperty',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'intakeParameters'),
  set: ({ get, set }, newProperty: ProcessTwoEPEndParameters) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      if (shape.parameters.intakeParameters !== undefined) {
        const newParameters = {
          ...shape.parameters,
          intakeParameters: newProperty,
        };
        set(shapeParameter(shape.id), newParameters);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processDeliveryProperty = selector<ProcessTwoEPEndParameters>({
  key: 'propertiesPanelProcessDeliveryProperty',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'deliveryParameters'),
  set: ({ get, set }, newProperty: ProcessTwoEPEndParameters) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      if (shape.parameters.deliveryParameters !== undefined) {
        const newParameters = {
          ...shape.parameters,
          deliveryParameters: newProperty,
        };
        set(shapeParameter(shape.id), newParameters);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processParkingDirection = selectorFamily<AreaParkingDirection, string>({
  key: 'propertiesPanelProcessParkingDirection',
  get: (type: string) => ({ get }) => { 
    const parameters = findParameterValue(get(selectedShapesState), type)
    return parameters.parkingDirection
  },
  set: (type: string) => ({ get, set }, value: AreaParkingDirection) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      const { id, parameters } = shape
      const newParameters = {
        ...parameters,
        [type]: {
          ...parameters[type],
          parkingDirection: value,
        }
      };
      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processLoadCarrierSide = selectorFamily<AreaLoadCarrierOrientation, string>({
  key: 'propertiesPanelProcessLoadCarrierSide',
  get: (type: string) => ({ get }) => { 
    const parameters = findParameterValue(get(selectedShapesState), type)
    return parameters.loadCarrierOrientation
  },
  set: (type: string) => ({ get, set }, value: AreaLoadCarrierOrientation) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      const { id, parameters } = shape
      const newParameters = {
        ...parameters,
        [type]: {
          ...parameters[type],
          loadCarrierOrientation: value,
        }
      };
      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processVehicleIds = selectorFamily<string[], string>({
  key: 'propertiesPanelVehicleIdsSelect',
  get:
    (paramType: 'intakeParameters' | 'deliveryParameters') =>
    ({ get }) => {
      // work with a safe fallback value for now
      // NOTE: since there is currently a "dead-zone" in time, when we start drawing while there is already a shape selected,
      // that shape will get deselected and no shapes will be "selected" until drawing is finished
      // this suspense between old selected shape - no selected shapes - new selected shape, gives issues with components not getting the props they expect.
      // TODO: check alternative propertyPanel/mouseEvents implementation
      const SAFE_FALLBACK_VALUE = [];

      const shape = get(selectedShapesState).at(0);
      if (!shape) {
        return SAFE_FALLBACK_VALUE;
      }

      const shapeParameters = get(shapeParameter(shape.id)) as ProcessTwoEPShapeParameters;

      if (shapeParameters[paramType].supportedVehicleIds === undefined) return SAFE_FALLBACK_VALUE;

      return shapeParameters[paramType].supportedVehicleIds;
    },
  set:
    (paramType: string) =>
    ({ get, set }, newValue: string[]) => {
      const selectedShapes = get(selectedShapesState);

      selectedShapes.forEach((shape) => {
        if (!isProcessAreaTwoEp(shape)) return;

        const { id, type, parameters } = shape;

        if (supportsVehicleTypes(type) && parameters[paramType].supportedVehicleIds !== undefined) {
          set(shapeParameter(id), {
            ...parameters,
            [paramType]: {
              ...parameters[paramType],
              supportedVehicleIds: newValue,
            },
          });
        }
      });
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processStorageType = selectorFamily({
  key: 'propertiesPanelProcessStorageType',
  get:
    (type: 'deliveryParameters' | 'intakeParameters') =>
    ({ get }): StorageType | undefined => {
      const parameters = findParameterValue(get(selectedShapesState), type);
      return parameters?.storageType;
    },
  set:
    (type: 'deliveryParameters' | 'intakeParameters') =>
    ({ get, set }, value: StorageType) => {
      get(selectedShapesState).forEach((shape) => {
        if (!isProcessAreaTwoEp(shape)) return;

        let { id, parameters } = shape;
        let newProperty: StorageProperty | null = null;
        let { direction, parkingDirection } = parameters[type];
        if (value === StorageType.SIDELOADING) {
          newProperty = {
            endpointDirection: DEFAULT_SIDE_LOADING.END_POINT_DIRECTION,
            sideLoadingDeltaX: DEFAULT_SIDE_LOADING.DELTA_X,
            sideLoadingDeltaY: DEFAULT_SIDE_LOADING.DELTA_Y,
          };
        } else {
          direction = AreaDirection.DOWN;
          parkingDirection = AreaParkingDirection.BACKWARD;
        }

        const newParameters = {
          ...parameters,
          [type]: {
            ...parameters[type],
            direction,
            parkingDirection,
            storageProperty: newProperty,
            storageType: value,
          },
        };
        set(shapeParameter(id), newParameters);
      });
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processStorageProperty = selectorFamily<StorageProperty, string>({
  key: 'propertiesPanelStorageProperty',
  get: (type: string) => ({ get }) => { 
    const parameters = findParameterValue(get(selectedShapesState), type)
    return parameters.storageProperty
  },
  set: (type: string) => ({ get, set }, value: StorageProperty) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      const { id, parameters } = shape;
      if (parameters[type].storageProperty !== undefined) {
        const newParameters = {
          ...parameters,
          [type]: {
            ...parameters[type],
            storageProperty: value,
          }
        };
        set(shapeParameter(id), newParameters);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processDirection = selectorFamily<AreaDirection, string>({
  key: 'propertiesPanelProcessDirection',
  get: (type: string) => ({ get }) => { 
    const parameters = findParameterValue(get(selectedShapesState), type)
    return parameters.direction
  },
  set: (type: string) => ({ get, set }, value: AreaDirection) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      const { id, parameters } = shape
      const newParameters = {
        ...parameters,
        [type]: {
          ...parameters[type],
          direction: value,
        }
      };
      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processLoadElevation = selectorFamily<number, string>({
  key: 'propertiesPanelProcessDirection',
  get: (type: string) => ({ get }) => { 
    const parameters = findParameterValue(get(selectedShapesState), type)
    return parameters.loadElevation
  },
  set: (type: string) => ({ get, set }, value: number) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isProcessAreaTwoEp(shape)) return;

      const { id, parameters } = shape
      const newParameters = {
        ...parameters,
        [type]: {
          ...parameters[type],
          loadElevation: value,
        }
      };
      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processEndpointDirection = selectorFamily<AreaEndpointDirection, string>({
  key: 'propertiesPanelProcessEndpointDirection',
  get: (type: string) => ({ get }) => { 
    const property = get(processStorageProperty(type)) as SideLoadingProperty;
    return property.endpointDirection;
  },
  set: (type: string) => ({ get, set }, value: AreaEndpointDirection) => {
    const property = get(processStorageProperty(type)) as SideLoadingProperty;

    set(processStorageProperty(type), {
      ...property,
      endpointDirection: value as AreaEndpointDirection,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processSideLoadingDeltaX = selectorFamily<number, string>({
  key: 'propertiesPanelProcessSideLoadingDeltaX',
  get: (type: string) => ({ get }) => { 
    const property = get(processStorageProperty(type)) as SideLoadingProperty;
    return property.sideLoadingDeltaX;
  },
  set: (type: string) => ({ get, set }, value: number) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    const property = get(processStorageProperty(type)) as SideLoadingProperty;

    set(processStorageProperty(type), {
      ...property,
      sideLoadingDeltaX: valueInMillimeter,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const processSideLoadingDeltaY = selectorFamily<number, string>({
  key: 'propertiesPanelProcessSideLoadingDeltaY',
  get: (type: string) => ({ get }) => { 
    const property = get(processStorageProperty(type)) as SideLoadingProperty;
    return property.sideLoadingDeltaY;
  },
  set: (type: string) => ({ get, set }, value: number) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    const property = get(processStorageProperty(type)) as SideLoadingProperty;

    set(processStorageProperty(type), {
      ...property,
      sideLoadingDeltaY: valueInMillimeter,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
