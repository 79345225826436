import { Button, Grid, Typography } from '@mui/material';
import { useRecoilCallback, } from 'recoil';

import { isTemplateType } from '@/modules/common/types/guards';
import { allGroupsSelector, useShapeGroup } from '@/modules/shapeGroups';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';

export const UngroupTemplates = () => {
  const { deleteGroups } = useShapeGroup();

  const ungroupTemplates = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const allGroups = await snapshot.getPromise(allGroupsSelector);
        const templateGroupIds = allGroups
          .filter((item) => isTemplateType(item.type))
          .map((item) => item.id);

        if (templateGroupIds.length === 0) return;

        deleteGroups(templateGroupIds);
      },
    [deleteGroups],
  );

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Disband all template groups</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Button onClick={ungroupTemplates}>Click</Button>
      </Grid>
    </>
  );
};
