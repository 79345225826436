import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useRecoilValue } from 'recoil';
import { orderDistributionStrategySelector } from '@/modules/simulation/store/draft';

export const OrderDistributionStrategy = () => {
  const { t } = useTranslation('interface');
  const orderDistributionStrategy = useRecoilValue(orderDistributionStrategySelector);
  const { updateDraftSimulation } = useSimulationDraftCallbacks();
  const { updateUserPreference } = useUpdateUserPreferences();

  const onChange = useCallback(
    (e) => {
      const selectedValue = e.target.value;
      updateUserPreference(
        UserPreferenceName.SIMULATION_ORDER_DISTRIBUTION_STRATEGY,
        selectedValue,
      );
      updateDraftSimulation((state) => ({
        ...state,
        orderDistributionStrategy: selectedValue,
      }));
    },
    [updateDraftSimulation, updateUserPreference],
  );

  return (
    <PropertiesLabel
      labelRatio={50}
      i18nkey='interface:simulation.experimental_settings.OrderDistributionStrategy.label'
    >
      <Select onChange={onChange} value={orderDistributionStrategy}>
        <MenuItem value='TIME' disabled>
          {t('simulation.experimental_settings.OrderDistributionStrategy.time')}
        </MenuItem>
        <MenuItem value='DISTANCE'>
          {t('simulation.experimental_settings.OrderDistributionStrategy.distance')}
        </MenuItem>
      </Select>
    </PropertiesLabel>
  );
};
