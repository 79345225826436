import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { Crossing } from '@modules/common/types/connections';
import { getRelatedAndUnrelatedConnectionIds } from '@modules/connections/common/helpers';
import { allCrossingSelector, updateCrossingsSelector } from '@modules/connections/crossings/store';
import { allHighwaysSelector } from '@/store/recoil/shapes/highway';
import { allAngledHighwaysSelector } from '@/modules/angledHighways';
import { findCrossingsPositions } from '../helpers/crossings';

export const useUpdateCrossings = () => {
  const updateCrossings = useRecoilCallback(
    ({ set, snapshot }) =>
      async (shapeIds: string[]) => {
        const [allCrossings, allHighways, allAngledHighways] = await Promise.all([
          snapshot.getPromise(allCrossingSelector),
          snapshot.getPromise(allHighwaysSelector),
          snapshot.getPromise(allAngledHighwaysSelector),
        ]);

        // Derive which roads to update crossings for
        const allRoads = [...allHighways, ...allAngledHighways];
        const allRoadIds: Set<string> = new Set(allRoads.map((item) => item.id));
        const roadIds = new Set(shapeIds.filter((id) => allRoadIds.has(id)));
        const roads = allRoads.filter((item) => roadIds.has(item.id));

        const allCrossingsToAddOrUpdate: Crossing[] = [];
        const allCrossingIdsToRemove: Set<string> = new Set();
        const crossingsHandled: Set<string> = new Set();

        const idPairToCrossingMap = new Map(
          allCrossings.map((item) => [toIdPair(item.from, item.to), item]),
        );

        const { relatedConnections: allRelatedCrossings } = getRelatedAndUnrelatedConnectionIds(
          allCrossings,
          [...roadIds],
        );

        // Prep a map from
        const roadToCrossingsMap: Map<string, Crossing[]> = new Map();
        allRelatedCrossings.forEach((crossing) => {
          const fromRoadCrossings = roadToCrossingsMap.get(crossing.from);
          if (!fromRoadCrossings) {
            roadToCrossingsMap.set(crossing.from, [crossing]);
          } else {
            fromRoadCrossings.push(crossing);
          }

          const toRoadCrossings = roadToCrossingsMap.get(crossing.to);
          if (!toRoadCrossings) {
            roadToCrossingsMap.set(crossing.to, [crossing]);
          } else {
            toRoadCrossings.push(crossing);
          }
        });

        // For each road, find crossings to update / create / delete
        roads.forEach((road) => {
          const computedCrossings = findCrossingsPositions(road, allRoads);
          console.log();
          const crossingsToAddOrUpdate: Crossing[] = [];

          computedCrossings.forEach((crosPos) => {
            const fromToIdPair = toIdPair(crosPos.from, crosPos.to);

            // Prevent unneeded handling of same crossing between 2 roads
            if (crossingsHandled.has(fromToIdPair)) return;
            crossingsHandled.add(fromToIdPair);

            const knownCrossing = idPairToCrossingMap.get(fromToIdPair);

            // Updated crossing
            if (knownCrossing) {
              const updatedCrossing = {
                ...knownCrossing,
                position: crosPos.position,
              };

              crossingsToAddOrUpdate.push(updatedCrossing);

              return;
            }

            // New crossing
            crossingsToAddOrUpdate.push({ ...crosPos, id: uuid() });
          });

          // Crossings to remove
          const deleteIds = roadToCrossingsMap
            .get(road.id)
            ?.filter(
              (existingCrossing) =>
                !(
                  crossingsToAddOrUpdate.find((item) => item.id === existingCrossing.id) ||
                  crossingsHandled.has(toIdPair(existingCrossing.from, existingCrossing.to))
                ),
            )
            .map((item) => item.id);

          deleteIds?.forEach((id) => allCrossingIdsToRemove.add(id));
          allCrossingsToAddOrUpdate.push(...crossingsToAddOrUpdate);
        });

        set(updateCrossingsSelector, {
          crossings: allCrossingsToAddOrUpdate,
          crossingIdsToRemove: allCrossingIdsToRemove,
        });
      },
    [],
  );

  return {
    updateCrossings,
  };
};

/**
 * @description returns the unique combo of 2 ids
 * @example
 * toIdPair('idA', 'idB') => 'idA_idB'
 * toIdPair('idB', 'idA') => 'idA_idB'
 */
const toIdPair = (idA: string, idB: string): string =>
  idB < idA ? `${idB}_${idA}` : `${idA}_${idB}`;
