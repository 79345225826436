import { LoadCarrierType } from '@/modules/common/types/floorPlan';

export const numberOfColumns = (
  length: number,
  gap: number,
  margin: number,
  objectWidth: number,
): number => {
  if (length <= objectWidth) return 0;
  if (length <= objectWidth * 2 + gap + margin * 2) return 1;
  return Math.floor((length - 2 * margin + gap) / (objectWidth + gap));
};

export const numberOfRows = (length: number, loadLength: number, gap: number): number =>
  Math.floor((length + gap) / (loadLength + gap));

/**
 * @description takes in a collection of load carriers,
 * converts their dimensions to conform to the mathematical rectangle representation (length always > width),
 * determines the bounding box based on these representations and returns it in the math rectangle representation
 *
 * @example
 * ([{..., length: 900, width: 1100}, {..., length: 1200, width: 800}])
 * =>
 * {length: 1200, width: 900}
 */
export const calcOrientedLoadCarriersBoundingBox = (loadCarriers: LoadCarrierType[]) => {
  const fallbackWidth = 0;
  const fallBackLength = 0;
  const lengths: number[] = [];
  const widths: number[] = [];

  loadCarriers.forEach((item) => {
    if (item.length > item.width) {
      lengths.push(item.length);
      widths.push(item.width);
    } else {
      lengths.push(item.width);
      widths.push(item.length);
    }
  });

  return {
    width: Math.max(...widths, fallbackWidth),
    length: Math.max(...lengths, fallBackLength),
  };
};
