import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  defaultShapeFactory,
  isArea,
  isPosition,
  usesControlPoints,
} from '@/modules/common/helpers/shapes';
import { DTShape, ShapeType } from '@/modules/common/types/shapes';
import { processTwoEndPointIdsState } from '@/modules/processTwoEndPoint/store';
import {
  enabledVehiclesLengthSelector,
  enabledVehiclesSelector,
  enabledVehiclesWidthSelector,
  findDefaultSupportVehiclesForShape,
} from '@/modules/vehicles';
import { drawingIdSelector } from '@/store/recoil/workspace';
import { angledHighwayIdsState } from '@modules/angledHighways';
import { enabledLoadCarrierTypesIdsState } from '@recoil/loadCarrierTypes';
import shapeAtom from '@recoil/shape/atom';
import areasAtom from '@recoil/shapes/area/atom';
import { highwayIdsAtom } from '@recoil/shapes/highway';
import { obstacleIdsAtom } from '@recoil/shapes/obstacle/obstacleIdsAtom';
import { positionsAtom } from '@recoil/shapes/positions/atom';
import { wallIdsAtom } from '@recoil/shapes/wall/wallIdsAtom';

export const useNewShape = () => {
  const shapesToIdCollectionsByType = useRecoilCallback(
    ({ set }) =>
      (shapes: DTShape[]): {
          wallIds: string[],
          areaIds: string[],
          highwayIds: string[],
          angledHighwayIds: string[],
          positionIds: string[],
          obstacleIds: string[],
          processTwoEndPointIds: string[],
       } => {
        const wallIds: string[] = [];
        const areaIds: string[] = [];
        const highwayIds: string[] = [];
        const angledHighwayIds: string[] = [];
        const positionIds: string[] = [];
        const obstacleIds: string[] = [];
        const processTwoEndPointIds: string[] = [];

        shapes.forEach((shape) => {
          set(shapeAtom(shape.id), shape);
          // add shape to ids collection
          const { type } = shape;
          if (isArea(type)) {
            areaIds.push(shape.id);
          } else if (isPosition(type)) {
            positionIds.push(shape.id);
          } else {
            switch (type) {
              case ShapeType.PROCESS_TWO_EP: {
                processTwoEndPointIds.push(shape.id);
                return;
              }
              case ShapeType.WALL: {
                wallIds.push(shape.id);
                return;
              }
              case ShapeType.HIGHWAY: {
                highwayIds.push(shape.id);
                return;
              }
              case ShapeType.HIGHWAY_ANGLED: {
                angledHighwayIds.push(shape.id);
                return;
              }
              case ShapeType.OBSTACLE: {
                obstacleIds.push(shape.id);
                return;
              }
              default: {
                // Exhaustive switch. Error if not all options are covered
                const warning: never = type;
              }
            }
          }
        });

        return {
          wallIds,
          areaIds,
          highwayIds,
          angledHighwayIds,
          positionIds,
          obstacleIds,
          processTwoEndPointIds,
        };
      },
    [],
  );

  const updateShapes = useRecoilCallback(
    ({ set }) =>
      (shapes: DTShape[]) => {
        const {
          wallIds,
          areaIds,
          highwayIds,
          angledHighwayIds,
          positionIds,
          obstacleIds,
          processTwoEndPointIds,
        } = shapesToIdCollectionsByType(shapes);

        if (wallIds.length > 0) set(wallIdsAtom, (prev) => [...new Set([...prev, ...wallIds])]);
        if (areaIds.length > 0) set(areasAtom, (prev) => [...new Set([...prev, ...areaIds])]);
        if (highwayIds.length > 0)
          set(highwayIdsAtom, (prev) => [...new Set([...prev, ...highwayIds])]);
        if (angledHighwayIds.length > 0)
          set(angledHighwayIdsState, (prev) => [...new Set([...prev, ...angledHighwayIds])]);
        if (positionIds.length > 0)
          set(positionsAtom, (prev) => [...new Set([...prev, ...positionIds])]);
        if (obstacleIds.length > 0)
          set(obstacleIdsAtom, (prev) => [...new Set([...prev, ...obstacleIds])]);
        if (processTwoEndPointIds.length > 0)
          set(processTwoEndPointIdsState, (prev) => [
            ...new Set([...prev, ...processTwoEndPointIds]),
          ]);
      },
    [shapesToIdCollectionsByType],
  );

  const replaceShapes = useRecoilCallback(
    ({ set }) =>
      async (shapes: DTShape[]) => {
        const {
          wallIds,
          areaIds,
          highwayIds,
          angledHighwayIds,
          positionIds,
          obstacleIds,
          processTwoEndPointIds,
        } = shapesToIdCollectionsByType(shapes);

        set(areasAtom, areaIds);
        set(positionsAtom, positionIds);
        set(processTwoEndPointIdsState, processTwoEndPointIds);
        set(wallIdsAtom, wallIds);
        set(highwayIdsAtom, highwayIds);
        set(angledHighwayIdsState, angledHighwayIds);
        set(obstacleIdsAtom, obstacleIds);
      },
    [shapesToIdCollectionsByType],
  );

  const newShape = useRecoilCallback(
    ({ snapshot }) =>
      async (type: ShapeType, syncToStore = true): Promise<DTShape> => {
        const vehicleLength = await snapshot.getPromise(enabledVehiclesLengthSelector);
        const vehicleWidth = await snapshot.getPromise(enabledVehiclesWidthSelector);

        // take the first enabled vehicle and load carrier type as the default one to support
        const enabledLoadCarrierIds = [
          (await snapshot.getPromise(enabledLoadCarrierTypesIdsState)).at(0),
        ];
        const supportedVehicleIds = findDefaultSupportVehiclesForShape(
          await snapshot.getPromise(enabledVehiclesSelector),
          'forklifts',
        ).map((item) => item.id);

        const id = usesControlPoints(type) ? await snapshot.getPromise(drawingIdSelector) : uuid();

        let newShape = defaultShapeFactory(
          id,
          type,
          supportedVehicleIds,
          // @ts-expect-error strictNullChecks. Pls fix me
          enabledLoadCarrierIds,
          vehicleWidth,
          vehicleLength,
        );

        if (syncToStore) updateShapes([newShape]);

        return newShape;
      },
    [updateShapes],
  );

  return { newShape, updateShapes, replaceShapes };
};
