import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { areaLoadDuration } from '@/components/PropertiesPanel/store/area';
import { NumberInput } from '@/modules/common/components/inputs';
import { AreaLoadAction, ShapeType } from '@/modules/common/types/shapes';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';

function LoadDuration() {
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);

  const { save } = useAutoSave();
  const [loadDuration, setLoadDuration] = useRecoilState(areaLoadDuration);
  const { updateUserPreference } = useUpdateUserPreferences();

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const onChangeLoadDuration = useRecoilCallback(
    ({ snapshot }) =>
      async (newVal: number) => {
        setLoadDuration(newVal);
        save();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        switch (selectedShapes[0].type) {
          case ShapeType.INTAKE:
          case ShapeType.INTAKE_POSITION:
            updateUserPreference(UserPreferenceName.INTAKE_LOAD_DURATION, newVal);
            break;
          case ShapeType.STORAGE:
          case ShapeType.STORAGE_POSITION:
            updateUserPreference(UserPreferenceName.STORAGE_LOAD_DURATION, newVal);
            break;
          case ShapeType.MANUAL:
          case ShapeType.MANUAL_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_LOAD_DURATION, newVal);
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_LOAD_DURATION, newVal);
            break;
          default:
        }
      },
    [setLoadDuration, save],
  );

  return (
    <NumberInput
      value={loadDuration}
      unit='sec'
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChangeLoadDuration}
      onFocus={onFocus}
      onBlur={onBlur}
      errorType='floating'
      dataType='integer'
      minValue={0}
    />
  );
}

export default LoadDuration;
