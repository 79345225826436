import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { preValidationMetaState } from '../state';

export const preValidationValidSelector = selector<boolean>({
  key: 'preValidationValid',
  get: ({ get }) => get(preValidationMetaState).valid,
  set: ({ set, get }, value) => {
    set(preValidationMetaState, {
      ...get(preValidationMetaState),
      valid: value,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
