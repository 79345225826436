import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { enabledVehicleIdsState } from './atom';
import { vehicleLengthSelector } from '../vehicleLengthSelector';

export const enabledVehiclesLengthSelector = selector<number>({
  key: 'vehicles/enabled/length',
  get: ({ get }) => {
    const lengths: number[] = get(enabledVehicleIdsState)
      .map((id) => get(vehicleLengthSelector(id)))
      .filter((item): item is number => typeof item === 'number');

    if (lengths.length === 0) {
      return 0;
    };

    return Math.max(...lengths);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
