import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { SelectInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { StorageType as StorageTypeType } from '@modules/common/types/storage';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { processStorageType } from '../../store/process';

export type Props = {
  type: 'deliveryParameters' | 'intakeParameters';
};

const ProcessStorageTypeComponent = ({ type }: Props) => {
  const { saveFloorPlan } = useFloorPlanState();
  const { t } = useTranslation('interface');
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts, showLoader: showArtefactsLoader } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);
  const [storageType, setStorageType] = useRecoilState(
    processStorageType(type),
  );
  const optionLabels = t('properties.area.storage_types', {
    returnObjects: true,
  });
  
  const onChange = useCallback(
    () => async (value: StorageTypeType) => {
      showArtefactsLoader(ids);
      setStorageType(value);
      await saveFloorPlan();
      updateArtefacts(ids);
    },
    [showArtefactsLoader, ids, setStorageType, saveFloorPlan, updateArtefacts],
  );

  if (!storageType) return null;

  return (
    <SelectInput
      options={[
        {
          label: optionLabels[StorageTypeType.SINGLE],
          value: StorageTypeType.SINGLE,
        },
        {
          label: optionLabels[StorageTypeType.SIDELOADING],
          value: StorageTypeType.SIDELOADING,
        },
      ]}
      onChange={onChange}
      value={
        [
          StorageTypeType.SINGLE,
          StorageTypeType.SIDELOADING,
        ].indexOf(storageType) > -1
          ? storageType
          : StorageTypeType.SINGLE
      }
      disabled={mode !== WorkspaceMode.EDITABLE}
    />
  );
};

const ProcessStorageType = memo(ProcessStorageTypeComponent);
export default ProcessStorageType;
