import { selectorFamily } from 'recoil';

import shapeAtom from '../shape/atom';
import { enabledLoadCarrierTypesSelector } from './enabledLoadCarrierTypes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { DEFAULT_LOAD_BOUNDING_BOX_DIMENSIONS } from '@modules/common/constants/storage';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';

// gets the load carriers bounding box to feed to process renderer
export const processIntakeOrientedLoadCarriersBoundingBoxSelector = selectorFamily({
  key: 'loadCarrierType/byShapeId/process/intake/boundingBox/oriented',
  get:
    (shapeId: string, ) =>
    ({ get }) => {
      const shape = get(shapeAtom(shapeId)) as ProcessTwoEPShape;
      const { supportedLoadCarriersIds } = shape.parameters;

      if (!supportedLoadCarriersIds)
        return DEFAULT_LOAD_BOUNDING_BOX_DIMENSIONS;

      const supportedCarriers = get(enabledLoadCarrierTypesSelector).filter((item) =>
        supportedLoadCarriersIds.includes(item.id),
      );

      const box = calcOrientedLoadCarriersBoundingBox(supportedCarriers);

      return {
        width: box.width * SHAPE_TO_CANVAS_SCALE,
        length: box.length * SHAPE_TO_CANVAS_SCALE,
      };
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
