import { useRecoilCallback } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { supportsVehicleTypes } from '@modules/common/helpers/shapes';
import { isAreaShape, isPositionShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { useAutoSave } from '@modules/floorplan';
import { allShapesSelector } from '@recoil/shapes';
import {
  allVehiclesState,
  enabledVehicleIdsState,
  findDefaultSupportVehiclesForShape,
} from '@/modules/vehicles';
import { StorageType } from '@/modules/common/types/storage';

export const useVehicleTypes = () => {
  const { save } = useAutoSave();
  const { update: updateArtefacts } = useArtefacts();

  const setEnabledVehicleIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newEnabledIds: string[]) => {
        const newEnabledVehicles = (await snapshot.getPromise(allVehiclesState)).filter((v) =>
          newEnabledIds.includes(v.id),
        );

        if (newEnabledVehicles.length < 1) {
          console.error(
            'Action would result in invalid state. Did not expect would-be state to be 0 enabled vehicles.',
          );
          return;
        }

        set(enabledVehicleIdsState, newEnabledIds);

        const shapes = await snapshot.getPromise(allShapesSelector);
        const shapeIdsWithNewSupportedVehicles: string[] = [];

        const newShapes = shapes.map((shape) => {
          if (!supportsVehicleTypes(shape.type)) {
            return shape;
          }

          if (isAreaShape(shape) || isPositionShape(shape)) {
            const currentSupportedVehicleIds: string[] = shape.parameters.supportedVehicleIds ?? [];
            const newSupportedVehicleIds = currentSupportedVehicleIds.filter((id) =>
              newEnabledIds.includes(id),
            );

            const retainingSameSupportedVehicleIds =
              currentSupportedVehicleIds.length === newSupportedVehicleIds.length;
            if (retainingSameSupportedVehicleIds) {
              return shape;
            }

            shapeIdsWithNewSupportedVehicles.push(shape.id);

            if (newSupportedVehicleIds.length === 0) {
              const compatibleWithShuttleVehicles = isAreaShape(shape) && shape.parameters.storageType === StorageType.SHUTTLERACK;
              const defaultSupportedVehicles = findDefaultSupportVehiclesForShape(
                newEnabledVehicles,
                compatibleWithShuttleVehicles ? 'shuttles' : 'forklifts',
              );

              if (defaultSupportedVehicles.length > 0) {
                newSupportedVehicleIds.push(...defaultSupportedVehicles.map((item) => item.id));
              }
            }

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                supportedVehicleIds: newSupportedVehicleIds,
              },
            };
          }
          if (isProcessAreaTwoEp(shape)) {
            const currentDeliveryVehicleIds: string[] =
              shape.parameters.deliveryParameters.supportedVehicleIds ?? [];
            const currentIntakeVehicleIds: string[] =
              shape.parameters.intakeParameters.supportedVehicleIds ?? [];

            const deliveryVehicleIdsNew = currentDeliveryVehicleIds.filter((id) =>
              newEnabledIds.includes(id),
            );
            const intakeVehicleIdsNew = currentIntakeVehicleIds.filter((id) =>
              newEnabledIds.includes(id),
            );

            const deliveryRetainsSameTypes =
              currentDeliveryVehicleIds.length === deliveryVehicleIdsNew.length;
            const intakeRetainsSameTypes =
              currentIntakeVehicleIds.length === intakeVehicleIdsNew.length;

            if (deliveryRetainsSameTypes && intakeRetainsSameTypes) {
              return shape;
            }

            shapeIdsWithNewSupportedVehicles.push(shape.id);

            if (deliveryVehicleIdsNew.length === 0) {
              const defaultSupportedVehicles = findDefaultSupportVehiclesForShape(
                newEnabledVehicles,
                'forklifts',
              );
              if (defaultSupportedVehicles.length > 0) {
                deliveryVehicleIdsNew.push(...defaultSupportedVehicles.map((item) => item.id));
              }
            }

            if (intakeVehicleIdsNew.length === 0) {
              const defaultSupportedVehicles = findDefaultSupportVehiclesForShape(
                newEnabledVehicles,
                'forklifts',
              );
              if (defaultSupportedVehicles.length > 0) {
                intakeVehicleIdsNew.push(...defaultSupportedVehicles.map((item) => item.id));
              }
            }

            return {
              ...shape,
              parameters: {
                ...shape.parameters,
                deliveryParameters: {
                  ...shape.parameters.deliveryParameters,
                  supportedVehicleIds: deliveryVehicleIdsNew,
                },
                intakeParameters: {
                  ...shape.parameters.intakeParameters,
                  supportedVehicleIds: intakeVehicleIdsNew,
                },
              },
            };
          }

          return shape;
        });

        set(allShapesSelector, newShapes);
        updateArtefacts(shapeIdsWithNewSupportedVehicles);
        await save();
      },
    [save, updateArtefacts],
  );

  return {
    setEnabledVehicleIds,
  };
};
