import { Vector2 } from 'three';

import { AreaAlignment, AreaDistribution, AreaLoadPlacement } from '@/modules/common/types/shapes';
import { WideLineSegment } from '@/modules/common/types/general';
import { StorageType } from '@/modules/common/types/storage';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { BoundingBox, Rectangle } from '@/helpers/types';
import { addEndPointGenerationSettings } from '@/modules/floorplanService/helpers/mapping/addEndPointGenerationSettings';
import {
  addDefaultIdGenerator,
  addUniqueIdGenerator,
} from '@/modules/floorplanService/helpers/mapping/addIdGenerators';
import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { LoadHandlingType } from '@/modules/floorplanService/helpers/mapping/types';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { pointAlongVector } from '@/modules/workspace/helpers/shape';
import { AreaShape } from '@/store/recoil/shape';
import { AREA_OVERFLOW } from '@/store/recoil/workspace';
import {
  createMultiSpecSharedLpIdGeneratorName,
  encodeIdAsShared,
  encodeIdWithVehicleId,
} from './idEncoder';
import {
  areaStackingMode,
  calcFpsLoadPositionAngle,
  endPointDiscType,
  fpsEndPointAngle,
  lineToFpsRectangle,
} from './utils';
import { ProcessTwoEPEndParameters } from '@/modules/processTwoEndPoint/types';

export const createProcessShape = (
  vehicleSpec,
  idGenerators,
  shape: ProcessTwoEPShape,
  vehicleSize,
  loadTypes,
  workspaceBoundingBox,
) => {
  const { controlPoints } = shape.properties;
  const { width, supportedLoadCarriersIds, operationTime, deliveryParameters, intakeParameters } =
    shape.parameters;
  const height = vehicleSize.length + 500;
  const horizontal = width > height;

  if (deliveryParameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId)) {
    createProcessEnd(
      `${shape.id}.0`,
      `delivery`,
      `${shape.name}-delivery`,
      controlPoints[0].position,
      controlPoints[1].position,
      height,
      width,
      horizontal,
      workspaceBoundingBox,
      supportedLoadCarriersIds,
      // @ts-expect-error strictNullChecks. Pls fix me
      operationTime,
      deliveryParameters,
      LoadHandlingType.DELIVERY,
      vehicleSpec,
      idGenerators,
      vehicleSize,
      loadTypes,
    );
  }

  if (intakeParameters.supportedVehicleIds.includes(vehicleSpec.vehicleSpecId)) {
    createProcessEnd(
      `${shape.id}.1`,
      `intake`,
      `${shape.name}-intake`,
      controlPoints[controlPoints.length - 1].position,
      controlPoints[controlPoints.length - 2].position,
      height,
      width,
      horizontal,
      workspaceBoundingBox,
      supportedLoadCarriersIds,
      // @ts-expect-error strictNullChecks. Pls fix me
      operationTime,
      intakeParameters,
      LoadHandlingType.INTAKE,
      vehicleSpec,
      idGenerators,
      vehicleSize,
      loadTypes,
    );
  }
};

export const createProcessEnd = (
  id: string,
  type: 'delivery' | 'intake',
  name: string,
  pointA: Vector2,
  pointB: Vector2,
  height: number,
  width: number,
  horizontal: boolean,
  workspaceBoundingBox: BoundingBox,
  supportedLoadCarriersIds: string[],
  operationTime: number,
  endParameters: ProcessTwoEPEndParameters,
  loadHandlingType: LoadHandlingType,
  vehicleSpec,
  idGenerators,
  vehicleSize,
  loadTypes,
) => {
  const rectangle = calculateEndRectangle(
    pointA,
    pointB,
    height,
    width,
    horizontal,
    workspaceBoundingBox,
  );
  const shape = createShape(
    id,
    type,
    name,
    width,
    height,
    supportedLoadCarriersIds,
    operationTime,
    endParameters,
  );
  createFpsElemets(
    vehicleSpec,
    idGenerators,
    shape,
    vehicleSize,
    loadTypes,
    loadHandlingType,
    rectangle,
    horizontal,
    endParameters.storageType,
  );
};

const calculateEndRectangle = (
  startPoint: Vector2,
  referencePoint: Vector2,
  height: number,
  width: number,
  horizontal: boolean,
  workspaceBoundingBox: BoundingBox,
) => {
  const endPoint = pointAlongVector(startPoint, referencePoint, height);
  const line: WideLineSegment = {
    points: {
      start: startPoint,
      end: endPoint,
    },
    width,
  };
  return lineToFpsRectangle(line, workspaceBoundingBox, horizontal ? -90 : 0);
};

const createShape = (
  id: string,
  type: 'delivery' | 'intake',
  name: string,
  width: number,
  height: number,
  supportedLoadCarriersIds: string[],
  operationTime: number,
  endParameters: ProcessTwoEPEndParameters,
) =>
  ({
    id,
    type,
    name,
    parameters: {
      alignment: AreaAlignment.CENTER,
      positionOverflow: AREA_OVERFLOW.CONTAIN,
      distribution: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
      gap: 0,
      margin: width,
      gapHorizontal: 0,
      gapVertical: 0,
      multiDeep: false,
      supportedLoadCarriersIds,
      operationTime,
      loadPlacement: AreaLoadPlacement.OFF,
      ...endParameters,
    },

    properties: {
      width,
      height,
      r: 0,
    },
  } as AreaShape);

export const createFpsElemets = (
  vehicleSpec,
  idGenerators,
  shape,
  vehicleSize,
  loadTypes,
  loadHandlingType: LoadHandlingType,
  rectangle: Rectangle,
  horizontal: boolean,
  storageType: StorageType,
) => {
  const shapeName = encodeIdWithVehicleId(shape.name, vehicleSpec.databaseId);
  const lpIdGeneratorName =
    shape.parameters.supportedVehicleIds.length > 1
      ? createMultiSpecSharedLpIdGeneratorName(shape.name)
      : `${shapeName}-LpIdGen`;
  const epIdGenerator = `${shapeName}-EpIdGen`;
  const shapeId = encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId);
  const {
    supportedLoadCarriersIds,
    loadCarrierOrientation,
    applyLoadCarrierToLoadPositionRegexes,
  } = shape.parameters;
  const { direction } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const { width: loadPositionWidth, length: loadPositionLength } =
    calcOrientedLoadCarriersBoundingBox(supportedLoadCarrierTypes);

  const endpointDirection =
    shape.parameters.storageProperty === undefined || shape.parameters.storageProperty === null
      ? null
      : shape.parameters.storageProperty.endpointDirection;

  const stackingMode = areaStackingMode(
    shape.type,
    shape.properties,
    direction,
    endpointDirection,
    shape.parameters.storageType,
  );

  addVehicleArea(vehicleSpec, shapeId, shape.type, rectangle, stackingMode);

  let loadPositionAngleRelativeToEndPoint = calcFpsLoadPositionAngle(loadCarrierOrientation);

  const endPointAngleRelativeToArea = fpsEndPointAngle(direction, horizontal);

  const { unloadDuration, loadDuration } = shape.parameters;

  addEndPointGenerationSettings(
    vehicleSpec,
    shapeId,
    shape,
    epIdGenerator,
    lpIdGeneratorName,
    null,
    loadHandlingType,
    endPointDiscType(shape.type, storageType),
    vehicleSize,
    supportedLoadCarrierTypes,
    loadPositionWidth,
    loadPositionLength,
    loadPositionAngleRelativeToEndPoint,
    direction,
    endPointAngleRelativeToArea,
    applyLoadCarrierToLoadPositionRegexes,
    loadDuration,
    unloadDuration,
    null,
  );

  addDefaultIdGenerator(idGenerators, epIdGenerator, shapeName);
  if (shape.parameters.supportedVehicleIds.length > 1) {
    addUniqueIdGenerator(
      idGenerators,
      createMultiSpecSharedLpIdGeneratorName(shape.name),
      encodeIdAsShared(shape.name),
    );
  } else {
    addDefaultIdGenerator(idGenerators, lpIdGeneratorName, shapeName);
  }
};
