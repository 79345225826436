import { useCallback, useMemo } from 'react';

import { useAxios } from '@/modules/api/hooks/useAxios';
import { useConfig } from '@/modules/common/hooks';

export const FILE_API_GET_FILE_ENDPOINT_VERSION = 'v1';

export const useFileApi = () => {
  const { api } = useConfig();
  const scopes = useMemo(() => [api.fileService.scope], [api.fileService.scope]);
  const baseUrl = `${api.fileService.url}`;
  const axios = useAxios({
    baseURL: baseUrl,
    scopes,
  });

  const addFile = useCallback(
    async (
      fileName: string,
      fileBlob: Blob,
      fileExtension: 'json' | 'mdb' | 'csv',
      category: string,
      abortSignal?: AbortSignal,
      apiVersion?: typeof FILE_API_GET_FILE_ENDPOINT_VERSION,
    ) => {
      const formData = new FormData();
      formData.append('file', fileBlob, `${fileName}.${fileExtension}`);

      return axios.put(
        `${apiVersion ? `${apiVersion}/` : ''}${category}?filename=${fileName}`,
        formData,
        {
          signal: abortSignal,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
    [axios],
  );

  const getFile = useCallback(
    async (
      filename: string,
      category: string,
      responseType: 'arraybuffer' | 'json',
      abortSignal?: AbortSignal,
    ) =>
      axios.get(`${FILE_API_GET_FILE_ENDPOINT_VERSION}/${category}?filename=${filename}`, {
        signal: abortSignal,
        responseType,
      }),
    [axios],
  );

  const copyFile = useCallback(
    async (sourceFile: string, targetFile: string, category: string, abortSignal?: AbortSignal) =>
      axios.post(`v1/${category}?sourceFile=${sourceFile}&targetFile=${targetFile}`, {
        signal: abortSignal,
      }),
    [axios],
  );

  const deleteFile = useCallback(
    async (filename: string, category: string, abortSignal?: AbortSignal) =>
      axios.delete(`v1/${category}?filename=${filename}`, {
        signal: abortSignal,
      }),
    [axios],
  );

  return {
    getFile,
    deleteFile,
    addFile,
    copyFile,
    baseUrl,
  };
};
