import { isAreaShape, isAreaShapePersisted } from '@/modules/common/types/guards';
import { StorageType } from '@/modules/common/types/storage';
import { FILE_API_GET_FILE_ENDPOINT_VERSION } from '@/modules/api/hooks/useFileApi';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { SHUTTLE_VEHICLE_NAMES } from '@/modules/vehicles/constants';
import { UnifiedVehicle } from '@/modules/vehicles/types';
import { AreaShape, DTShape, PositionShape } from '@/store/recoil/shape';
import {
  AreaShapePersisted,
  DTShapePersisted,
  PositionShapePersisted,
} from '@/modules/common/types/floorPlan';

export const buildAssetFileDownloadUrl = (baseUrl: string, category: string, fileName: string) =>
  `${baseUrl}${FILE_API_GET_FILE_ENDPOINT_VERSION}/${category}?filename=${fileName}`;

/**
 * @description provides a default collection of vehicles that can be used
 * to populate an area its supportedVehicleIds parameter.
 * - limited to the provided vehicles
 * - taking into account what kind of area
 */
export const findDefaultSupportVehiclesForShape = (
  enabledVehicles: UnifiedVehicle[],
  type: 'shuttles' | 'forklifts',
): UnifiedVehicle[] => {
  // TODO: note: consider actively involving the user in this through a popup with some options. It might not always be desirable from a user perspective that this is done automatically without the user knowing ...

  if (type === 'shuttles') {
    const vehicles = enabledVehicles.filter((item) => SHUTTLE_VEHICLE_NAMES.includes(item.name));
    return vehicles.length > 0 ? [vehicles[0]] : [];
  }

  const vehicles = enabledVehicles.filter((item) => !SHUTTLE_VEHICLE_NAMES.includes(item.name));
  return vehicles.length > 0 ? [vehicles[0]] : [];
};
