import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector, selectorFamily } from 'recoil';

import { LayerNames, Layers } from '@/modules/common/types/layers';
import { DTShape, ShapeType } from '@/modules/common/types/shapes';
import { selectedShapesState } from '../../../store/recoil/shapes/selected';
import { getLayerGroupOfShapeType, getLayerOfShapeType } from '../../workspace/helpers/shape';
import { invisibleElementsAtom, invisibleNameLabelsAtom, layersAtom, lockedElementsAtom } from './atom';
import { getParentLayerGroupFromShapeName, getRelativeLayers, getSubLayerNameFromLayer } from '../layerHierarchy';
import { allShapesSelector } from '@/store/recoil/shapes';
import { flowSelector, layoutFlowsSelector } from '@/modules/flows/store/layout/selector';

export type Input = {
  id: string;
  add: boolean;
};

const layerVisabilityToggleSelector = selectorFamily<LayerNames | undefined, LayerNames>({
  key: 'layerVisabilityToggleSelector',
  get:
    (layerName: LayerNames) =>
      ({ get }) => {
        const layers = get(layersAtom);
        const layer = layers[layerName];
        if (!layer) {
          return undefined;
        }
      },
  set:
    (layerName: LayerNames) =>
      ({ set, get }) => {
        const layers = get(layersAtom);
        const layoutFlows = get(layoutFlowsSelector);
        const shapeState = get(allShapesSelector)
        const shapesToAddInvisible: string[] = []
        const shapesToRemoveInvisible: string[] = []
        const layersToUpdate: Record<string, boolean> = {};
        const layerRelatives = getRelativeLayers(layerName)
        const isLayerVisable = get(layersShowSelector(layerName));

        if (layerName === LayerNames.FLOWS) {
          layoutFlows.forEach((flow) => {
            if (isLayerVisable) {
              shapesToRemoveInvisible.push(flow.id);
            } else {
              shapesToAddInvisible.push(flow.id);
            }
          });
        }

        else if (layerRelatives.length > 1) {
          layerRelatives.forEach((layer) => {
            layersToUpdate[layer] = isLayerVisable

            shapeState.forEach((shape) => {
              const layerFromType = getLayerOfShapeType(shape.type);
              if (layerFromType === layer) {
                if (isLayerVisable) {
                  shapesToRemoveInvisible.push(shape.id);
                } else {
                  shapesToAddInvisible.push(shape.id);
                }
              }
            });
          })
        }
        if (isLayerVisable) {
          layersToUpdate[layerName] = true;
          layersToUpdate[layerRelatives[0]] = true;

          shapeState.forEach((item: DTShape) => {
            const LayerNameFromShapeType = getLayerOfShapeType(item.type)
            if (layerName === LayerNameFromShapeType) {
              shapesToRemoveInvisible.push(item.id);
            }
          })
        } else {
          shapeState.forEach((item: DTShape) => {
            const LayerNameFromShapeType = getLayerOfShapeType(item.type)
            if (layerName === LayerNameFromShapeType) {
              shapesToAddInvisible.push(item.id);
            }
          })
          layersToUpdate[layerName] = false
        }

        if (shapesToAddInvisible.length > 0) {
          set(addInvisibleElementsSelector, shapesToAddInvisible);
        } else if (shapesToRemoveInvisible.length > 0) {
          set(removeInvisibleElementsSelector, shapesToRemoveInvisible)
        }

        set(layersAtom, {
          ...layers,
          ...Object.keys(layersToUpdate).reduce(
            (acc, key) => ({
              ...acc,
              [key]: { ...layers[key], visible: layersToUpdate[key] },
            }),
            {},
          ),
        });
      },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const layersShowSelector = selectorFamily<boolean, LayerNames>({
  key: 'layersShowSelector',
  get:
    (layerName: LayerNames) =>
      ({ get }) => {
        const layers = get<Layers>(layersAtom);
        return layers[layerName].visible;
      },
  set:
    (layerName: LayerNames) =>
      ({ set, get }, visible) => {
        const current = get<Layers>(layersAtom);
        set(layersAtom, {
          ...current,
          [layerName]: { ...current[layerName], visible },
        });
      },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const shapeTypeShowSelector = selectorFamily<boolean, ShapeType>({
  key: 'layersShowSelector/byShapeType',
  get:
    (type: ShapeType | undefined) =>
      ({ get }) => {
        if (!type || type === ShapeType.NONE) return false;

        const shapeLayerName = getLayerOfShapeType(type);
        // @ts-expect-error strictNullChecks. Pls fix me
        if (!get(layersShowSelector(shapeLayerName))) return false;

        const layerGroupName = getLayerGroupOfShapeType(type);
        if (layerGroupName && !get(layersShowSelector(layerGroupName))) return false;

        return true;
      },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const layersLockSelector = selectorFamily<boolean, LayerNames>({
  key: 'layersLockSelector',
  get:
    (layerName: LayerNames) =>
      ({ get }) => {
        const layers = get<Layers>(layersAtom);
        return layers[layerName].locked;
      },
  set:
    (layerName: LayerNames) =>
      ({ set, get }, locked) => {
        const current = get<Layers>(layersAtom);
        set(layersAtom, {
          ...current,
          [layerName]: { ...current[layerName], locked },
        });
      },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleElementsSelector = selector({
  key: 'invisibleElementsSelector',
  get: ({ get }) => get(invisibleElementsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleElementSelector = selectorFamily<boolean, string>({
  key: 'invisibleElementSelector',
  get:
    (id: string) =>
      ({ get }) =>
        !get(invisibleElementsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const addInvisibleElementsSelector = selector<string[]>({
  key: 'addInvisibleElementsSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, get }, idsToHide: string[]) => {
    let current = get(invisibleElementsAtom);
    set(invisibleElementsAtom, new Set([...current, ...idsToHide]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeInvisibleElementsSelector = selector<string[]>({
  key: 'removeInvisibleElementsSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, get }, idsToShow: string[]) => {
    const layersToUpdate: LayerNames[] = []
    const checkIfFlow = get(flowSelector(idsToShow[0]))

    if (checkIfFlow.id != null) {
      layersToUpdate.push(LayerNames.FLOWS);
    } else {
      const shapeStates = get(allShapesSelector)
      const matchingShapes = shapeStates.filter((shape) =>
        idsToShow.includes(shape.id));

      matchingShapes.forEach((shape) => {
        const layer = getLayerOfShapeType(shape.type);
        if (layer) {
          const isLayerVisable = get(layersShowSelector(layer));
          if (!isLayerVisable) {
            layersToUpdate.push(layer);
            const relativLayer = getRelativeLayers(layer);
            if (relativLayer) {
              layersToUpdate.push(relativLayer[0]);
            }
          }
        }
      })
    }

    set(layersAtom, (prevLayers) => ({
      ...prevLayers,
      ...Object.fromEntries(layersToUpdate.map(layer => [
        layer, { ...prevLayers[layer], visible: true }
      ])),
    }));

    const current = get(invisibleElementsAtom);
    const newInvisibleElements = new Set([...current].filter((id) => !idsToShow.includes(id)));
    set(invisibleElementsAtom, newInvisibleElements);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const lockedElementsSelector = selector({
  key: 'lockedElementsSelector',
  get: ({ get }) => get(lockedElementsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const lockedElementSelector = selectorFamily<boolean, string>({
  key: 'lockedElementSelector',
  get:
    (id: string) =>
      ({ get }) =>
        get(lockedElementsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const addLockedElementSelector = selector({
  key: 'addLockedElementSelector',
  get: () => null,
  set: ({ set, get }, id: string) => {
    let current = get(lockedElementsAtom);
    set(lockedElementsAtom, new Set([...current, id]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeLockedElementSelector = selector({
  key: 'removeLockedElementSelector',
  get: () => null,
  set: ({ set, get }, id) => {
    const current = get(lockedElementsAtom);
    // @ts-expect-error strictNullChecks. Pls fix me
    const newLockedElements = new Set([...current].filter((elementId) => elementId !== id));
    set(lockedElementsAtom, newLockedElements);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const layerContainsSelectedShapes = selectorFamily<boolean, LayerNames>({
  key: 'layer/containsSelectedShapes',
  get:
    (layerName) =>
      ({ get }) => {
        const containsSelectedShapes = get(selectedShapesState)?.some(
          (shape) => layerName === getLayerOfShapeType(shape.type),
        ) || get(selectedShapesState)?.some(
          (shape) => layerName === getLayerGroupOfShapeType(shape.type),
        )

        return !!containsSelectedShapes;
      },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleNameLabelsSelector = selector({
  key: 'invisibleNameLabelsSelector',
  get: ({ get }) => get(invisibleNameLabelsAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const invisibleNameLabelSelector = selectorFamily<boolean, string>({
  key: 'invisibleNameLabelSelector',
  get:
    (id: string) =>
      ({ get }) =>
        !get(invisibleNameLabelsAtom).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const toggleShapeNameSelector = selector<string[]>({
  key: 'toggleShapeNameSelector',
  get: ({ get }) => {
    const current = get(invisibleNameLabelsAtom);
    return Array.isArray(current) ? current : [];
  },
  set: ({ set, get }, idsToToggle: string[]) => {
    if (!Array.isArray(idsToToggle) || idsToToggle.length === 0) {
      return;
    }
    const layersToUpdate: LayerNames[] = []
    const shapeStates = get(allShapesSelector)
    const matchingShapes = shapeStates.filter((shape) =>
      idsToToggle.includes(shape.id));

    matchingShapes.forEach((shape: DTShape) => {
      const parentLayer = getParentLayerGroupFromShapeName(shape.type);

      if (parentLayer) {
        const isLayerVisable = get(layersShowSelector(parentLayer));
        if (!isLayerVisable) {
          layersToUpdate.push(parentLayer);
          const relativLayer = getRelativeLayers(parentLayer);
          if (relativLayer) {
            layersToUpdate.push(relativLayer[0]);
          }
        }
      }
    })

    set(layersAtom, (prevLayers) => ({
      ...prevLayers,
      ...Object.fromEntries(layersToUpdate.map(layer => [
        layer, { ...prevLayers[layer], visible: true }
      ])),
    }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const toggleAllGroupShapesNamesSelector = selector<{
  name: LayerNames;
  elements: { id: string }[];
}[]>({
  key: 'toggleAllGroupShapesNamesSelector',
  get: ({ get }) => {
    const current = get(invisibleNameLabelsAtom);
    return Array.isArray(current) ? current : [];
  },
  set: ({ set, get }, idsToHide) => {
    if (!Array.isArray(idsToHide) || idsToHide.length === 0) {
      return;
    }
    const formattedData = idsToHide.map(item => ({
      name: item.name,
      elements: item.elements.map(el => ({ id: el.id }))
    }));

    const subLayersToHide = getSubLayerNameFromLayer(idsToHide[0].name);
    const isLayerVisable = get(layersShowSelector(idsToHide[0].name));

    const allElements = formattedData.flatMap(group => group.elements.map(el => el.id));
    if (isLayerVisable) {
      set(addInvisibleNameLabelsSelector, allElements)

    } else {
      set(removeInvisibleNameLabelsSelector, allElements)
    }

    set(layersAtom, (prevLayers) => ({
      ...prevLayers,
      ...Object.fromEntries(subLayersToHide.map(layer => [
        layer, { ...prevLayers[layer], visible: true }
      ])),
    }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const toggleAllNamesSelector = selector<{
  name: LayerNames;
  elements: { id: string }[];
}[]>({
  key: 'toggleAllNamesSelector',
  get: ({ get }) => {
    const current = get(invisibleNameLabelsAtom);
    return Array.isArray(current) ? current : [];
  },
  set: ({ set, get }, idsToHide) => {
    let setVisable = true;
    if (!Array.isArray(idsToHide) || idsToHide.length === 0) {
      return;
    }
    const formattedData = idsToHide.map(item => ({
      name: item.name,
      elements: item.elements.map(el => ({ id: el.id }))
    }));

    const allElements = formattedData.flatMap(group => group.elements.map(el => el.id));
    const isLayerVisable = get(layersShowSelector(idsToHide[0].name));
    const subLayersToHide = getSubLayerNameFromLayer(idsToHide[0].name);

    if (isLayerVisable) {
      setVisable = false;
      set(addInvisibleNameLabelsSelector, allElements)
    } else {
      setVisable = true;
      set(removeInvisibleNameLabelsSelector, allElements)
    }

    set(layersAtom, (prevLayers) => ({
      ...prevLayers,
      ...Object.fromEntries(subLayersToHide.map(layer => [
        layer, { ...prevLayers[layer], visible: setVisable }
      ])),
    }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});


const addInvisibleNameLabelsSelector = selector<string[]>({
  key: 'addInvisibleNameLabelsSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, get }, idsToHide: string[]) => {
    const current = get(invisibleNameLabelsAtom);
    set(invisibleNameLabelsAtom, new Set([...current, ...idsToHide]));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const removeInvisibleNameLabelsSelector = selector<string[]>({
  key: 'removeInvisibleNameLabelsSelector',
  // @ts-expect-error strictNullChecks. Pls fix me
  get: () => null,
  set: ({ set, get }, idsToShow: string[]) => {
    const current = get(invisibleNameLabelsAtom);
    const newInvisibleNameLabels = new Set([...current].filter((id) => !idsToShow.includes(id)));
    set(invisibleNameLabelsAtom, newInvisibleNameLabels);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

const everythingIsVisible = selector({
  key: 'everythingIsVisible',
  get: ({ get }) => {
    if (get(invisibleElementsAtom).size > 0) return false;
    if (get(invisibleNameLabelsAtom).size > 0) return false;
    if (Object.values(LayerNames).some(item => !get(layersShowSelector(item)))) return false;
    return true;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export {
  layerVisabilityToggleSelector,
  addInvisibleElementsSelector,
  addInvisibleNameLabelsSelector,
  addLockedElementSelector,
  everythingIsVisible,
  invisibleElementSelector,
  invisibleElementsSelector,
  invisibleNameLabelSelector,
  invisibleNameLabelsSelector,
  layerContainsSelectedShapes,
  layersLockSelector,
  layersShowSelector,
  lockedElementSelector,
  lockedElementsSelector,
  removeInvisibleElementsSelector,
  removeInvisibleNameLabelsSelector,
  removeLockedElementSelector,
  toggleAllNamesSelector,
  toggleAllGroupShapesNamesSelector,
  toggleShapeNameSelector,
};

