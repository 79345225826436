import { TemplateType } from '@/modules/common/types/templating';
import { Position, SizeShort, StageProps } from '@helpers/types';
import { ShapeType } from '@modules/common/types/shapes';
import { ToolState } from '@modules/common/types/tools';
import { Group as GroupElement } from 'konva/lib/Group';
import { Layer as LayerElement } from 'konva/lib/Layer';
import { Rect as RectangleShape } from 'konva/lib/shapes/Rect';
import { Transformer as TransformerShape } from 'konva/lib/shapes/Transformer';
import { Stage as StageElement } from 'konva/lib/Stage';
import { atom } from 'recoil';

export type WorkspaceDomReferences = {
  stage: StageElement | null;
  container: HTMLDivElement | null;
  utilLayer: LayerElement | null;
  intakeLayer: GroupElement | null;
  deliveryLayer: GroupElement | null;
  storageLayer: GroupElement | null;
  chargingLayer: GroupElement | null;
  parkingLayer: GroupElement | null;
  manualLayer: GroupElement | null;
  manualExitLayer: GroupElement | null;
  manualEntryLayer: GroupElement | null;
  processLayer: GroupElement | null;
  highwaysLayer: GroupElement | null;
  angledHighwaysLayer: GroupElement | null;
  positionLayer: GroupElement | null;
  obstaclesLayer: GroupElement | null;
  wallsLayer: GroupElement | null;
  transformerShape: TransformerShape | null;
  selectionRectangle: RectangleShape | null;
};

export const domReferencesAtom = atom<WorkspaceDomReferences>({
  key: 'workspaceDomReferences',
  default: {
    stage: null,
    container: null,
    utilLayer: null,
    intakeLayer: null,
    deliveryLayer: null,
    storageLayer: null,
    chargingLayer: null,
    parkingLayer: null,
    manualLayer: null,
    manualExitLayer: null,
    manualEntryLayer: null,
    processLayer: null,
    highwaysLayer: null,
    angledHighwaysLayer: null,
    positionLayer: null,
    obstaclesLayer: null,
    wallsLayer: null,
    transformerShape: null,
    selectionRectangle: null,
  },
  dangerouslyAllowMutability: true,
});

export type WorkspaceAtom = {
  toolState: ToolState;
  previousToolState: ToolState;
  shapeType: ShapeType;
  previousShapeType: ShapeType;
  placingType: TemplateType | null;
  stageProps: StageProps;
  viewport: SizeShort;
  unit: string;
  drawingId: string | null;
  placingId: string | null;
  pannable: boolean;
  initialMousePosition: Position;
};

export const workspaceAtomDefaultState: WorkspaceAtom = {
  toolState: ToolState.SELECT,
  previousToolState: ToolState.SELECT,
  shapeType: ShapeType.NONE,
  previousShapeType: ShapeType.NONE,
  placingType: null,
  stageProps: {
    scale: 1,
    x: 0,
    y: 0,
  },
  viewport: {
    w: 0,
    h: 0,
  },
  unit: 'mm',
  drawingId: null,
  placingId: null,
  pannable: true,
  initialMousePosition: {
    x: 0,
    y: 0,
  },
};

const workspaceAtom = atom<WorkspaceAtom>({
  key: 'workspaceAtom',
  default: workspaceAtomDefaultState,
});

export default workspaceAtom;
