import { memo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { SelectInput } from '@common/components/inputs';
import { AreaDistribution, ShapeType } from '@modules/common/types/shapes';
import { selectedShapesState } from '@recoil/shapes/selected';
import { useTranslation } from 'react-i18next';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useArtefacts } from '@/modules/artefacts';

import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { areasDistribution } from '../store/area';
import { isAreaShape } from '@/modules/common/types/guards';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';

const DistributionComponent = () => {
  const { saveFloorPlan } = useFloorPlanState();
  const { t } = useTranslation('interface');
  const [distribution, setDistribution] = useRecoilState(areasDistribution);
  const { updateUserPreference } = useUpdateUserPreferences();
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const selectedShapes = useRecoilValue(selectedShapesState);
  const containsRacking = selectedShapes.some(
    (item) => isAreaShape(item) && item.parameters.storageType === 'rack',
  );

  const optionLabels = t('properties.area.distribution_options', {
    returnObjects: true,
  });

  const onDistributionChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: AreaDistribution) => {
        setDistribution(value);
        saveFloorPlan();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        updateArtefacts(selectedShapes.map((item) => item.id));

        switch (selectedShapes[0].type) {
          case ShapeType.INTAKE:
          case ShapeType.INTAKE_POSITION:
            updateUserPreference(UserPreferenceName.INTAKE_DISTRIBUTION, value);
            break;
          case ShapeType.DELIVERY:
          case ShapeType.DELIVERY_POSITION:
            updateUserPreference(UserPreferenceName.DELIVERY_DISTRIBUTION, value);
            break;
          case ShapeType.STORAGE:
          case ShapeType.STORAGE_POSITION:
            updateUserPreference(UserPreferenceName.STORAGE_DISTRIBUTION, value);
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_DISTRIBUTION, value);
            break;
          case ShapeType.CHARGING:
          case ShapeType.CHARGING_POSITION:
            updateUserPreference(UserPreferenceName.CHARGING_DISTRIBUTION, value);
            break;
          case ShapeType.PARKING:
          case ShapeType.PARKING_POSITION:
            updateUserPreference(UserPreferenceName.PARKING_DISTRIBUTION, value);
            break;
          case ShapeType.MANUAL_EXIT:
          case ShapeType.MANUAL_EXIT_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_EXIT_DISTRIBUTION, value);
            break;
          case ShapeType.MANUAL_ENTRY:
          case ShapeType.MANUAL_ENTRY_POSITION:
            updateUserPreference(UserPreferenceName.MANUAL_ENTRY_DISTRIBUTION, value);
            break;
          default:
        }
      },
    [saveFloorPlan, setDistribution, updateUserPreference, updateArtefacts],
  );

  return (
    <SelectInput
      options={[
        {
          label: optionLabels[AreaDistribution.EXTRA_SPACE_OVER_MARGIN],
          value: AreaDistribution.EXTRA_SPACE_OVER_MARGIN,
        },
        {
          label: optionLabels[AreaDistribution.EXTRA_SPACE_OVER_GAP],
          value: AreaDistribution.EXTRA_SPACE_OVER_GAP,
        },
      ]}
      onChange={onDistributionChange}
      value={
        [AreaDistribution.EXTRA_SPACE_OVER_MARGIN, AreaDistribution.EXTRA_SPACE_OVER_GAP].indexOf(
          distribution as AreaDistribution,
        ) > -1
          ? distribution
          : AreaDistribution.EXTRA_SPACE_OVER_MARGIN
      }
      disabled={containsRacking || mode !== WorkspaceMode.EDITABLE}
    />
  );
};

const Distribution = memo(DistributionComponent);
export default Distribution;
