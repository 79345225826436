import { v4 as uuid } from 'uuid';

import {
  DEFAULT_RACK,
} from '@/modules/common/constants/storage';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { calcRackShelfHeights } from '@/modules/common/helpers/rack';
import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { RackSpec } from '@/modules/floorplanService/helpers/mapping/types';
import {
  getRackNameFromShapeId,
} from '@/modules/floorplanService/helpers/mapping/utils';
import { AreaShape } from '@/store/recoil/shape';
import { RackProperty } from '@/store/recoil/shape/types/area';
import { AreaLoadCarrierOrientation } from '@/modules/common/types/shapes';

export const createRackSpec = (
  rackSpecs: RackSpec[],
  shape: AreaShape,
  loadTypes: LoadCarrierType[],
) => {
  const overhangFront = DEFAULT_RACK.OVERHANG_FRONT;
  const overhangBack = DEFAULT_RACK.OVERHANG_BACK;

  const { supportedLoadCarriersIds, loadCarrierOrientation } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const loadCarriersBoundingBox = calcOrientedLoadCarriersBoundingBox(supportedLoadCarrierTypes);

  const {
    framesDeep,
    rackLevels,
    rackLoadsPerShelf,
    loadHeight,
    clearanceSide,
    clearanceTop,
    clearanceInBetween,
    clearanceDepth,
    beamHeight,
    postWidth,
    firstShelfHeight,
    shelfHeights: providedShelfHeights,
    topShelfHeight: providedTopShelfHeight,
  } = shape.parameters.storageProperty as RackProperty;

  const beamLength =
    (loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
      ? loadCarriersBoundingBox.width
      : loadCarriersBoundingBox.length) *
      rackLoadsPerShelf +
    clearanceInBetween * (rackLoadsPerShelf - 1) +
    clearanceSide * 2;

  const shelfHeights = (
    providedShelfHeights.length > 0
      ? providedShelfHeights
      : calcRackShelfHeights(firstShelfHeight, loadHeight + clearanceTop, rackLevels, beamHeight)
  ).map((shelfHeight, i) => ({
    sequence: i,
    height: shelfHeight,
  }));

  rackSpecs.push({
    rackSpecId: uuid(),
    name: getRackNameFromShapeId(shape.id),
    frameLength: beamLength + 2 * postWidth, // postWidth is interpreted by FPS as post size. This will add automatic margin between shelves in x and y direction.
    frameDepth:
      (loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
        ? loadCarriersBoundingBox.length
        : loadCarriersBoundingBox.width) *
        framesDeep +
      clearanceDepth * (framesDeep - 1) -
      overhangFront -
      overhangBack,
    frameHeight: shelfHeights[shelfHeights.length - 1].height + 0.5 * loadHeight,
    beamLength,
    beamHeight,
    clearanceSide,
    clearanceInBetween,
    clearanceTop,
    clearanceDepth,
    overhangFront,
    overhangBack,
    topShelfHeight: providedTopShelfHeight || loadHeight,
    numLoadPositionsPerShelf: rackLoadsPerShelf,
    shelfHeights,
    depth: framesDeep,
    loadPositions: [],
  });
};
