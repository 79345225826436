import { ElementName } from '@modules/workspace/helpers/konva';
import { memo } from 'react';
import { Group } from 'react-konva';
import HalfCircleButton from './HalfCircleButton';

type Props = {
  x: number;
  y: number;
  rotation: number;
  inChecked: boolean;
  outChecked: boolean;
  onInClick?: () => void;
  onOutClick?: () => void;
  id?: string;
  listening?: boolean;
};

export function BubbleComponent({
  x,
  y,
  rotation,
  inChecked,
  outChecked,
  onInClick,
  onOutClick,
  id,
  listening = true,
}: Props) {
  return (
    <Group x={x} y={y} rotation={rotation} id={id} name={ElementName.CONTEXT_NODE_NAME}>
      <HalfCircleButton
        direction='up'
        onClick={onInClick}
        x={0}
        y={0}
        checked={inChecked}
        listening={listening}
      />
      <HalfCircleButton
        direction='down'
        onClick={onOutClick}
        x={0}
        y={0}
        checked={outChecked}
        listening={listening}
      />
    </Group>
  );
}

export const Bubble = memo(BubbleComponent);
