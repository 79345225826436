import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { StorageType } from '@/modules/common/types/storage';
import { TemplateType } from '@/modules/common/types/templating';
import { UnifiedVehicle } from '@/modules/vehicles';
import { SHUTTLE_VEHICLE_NAMES } from '@/modules/vehicles/constants';
import { AreaShape } from '@/store/recoil/shape';

export const buildTransportOrdersDownloadPath = (category: string, fileName: string) =>
  `${category}?filename=${fileName}`;

/**
 * @description provides the capacity per shuttle
 * based on how many channels are in the shuttle racks supported by a particular shuttle
 */
export const calcShuttleCapacities = (
  enabledVehicles: UnifiedVehicle[],
  allAreas: AreaShape[],
  allGroups: ShapeGroup[],
): Record<string, number> => {
  const shuttleIdCapacityMap: Record<string, number> = {};
  const enabledShuttleVehicles = enabledVehicles.filter((item) =>
    SHUTTLE_VEHICLE_NAMES.includes(item.name),
  );

  if (enabledShuttleVehicles.length > 0) {
    enabledShuttleVehicles.forEach((item) => {
      shuttleIdCapacityMap[item.id] = 0;
    });

    const shuttleRackAreas = allAreas.filter(
      (item) => item.parameters.storageType === StorageType.SHUTTLERACK,
    );

    shuttleRackAreas.forEach((shuttleArea) => {
      const group = allGroups.find((group) => group.children.includes(shuttleArea.id));

      if (!group || group.type !== TemplateType.SHUTTLE_RACK) return;

      const rackChannelsAmount =
        group.generationParams.numColumns * group.generationParams.numLevels;
      const shuttlesIdsSupportingThisArea = shuttleArea.parameters.supportedVehicleIds.filter(
        (supportedVehicleId) =>
          enabledVehicles.find(
            (item) => item.id === supportedVehicleId && SHUTTLE_VEHICLE_NAMES.includes(item.name),
          ),
      );

      shuttlesIdsSupportingThisArea.forEach((shuttleId) => {
        shuttleIdCapacityMap[shuttleId] += rackChannelsAmount;
      });
    });
  }

  return shuttleIdCapacityMap;
};
