import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { durationToHours } from '@modules/common/helpers/date';
import { simulationAtom } from './simulationAtom';
import { simulationFlowsSelector } from '@/modules/flows/store/simulation/selector';

/**
 * Whether the simulation can be run
 */
export const isValidToRunSelector = selector<boolean>({
  key: 'simulations/draft/isValidToRun',
  get: ({ get }) => {
    const simulation = get(simulationAtom);
    const { duration, transportWindow, name, vehicleTypes } = simulation ?? {};
    const flows = get(simulationFlowsSelector);

    return (
      durationToHours(duration) !== 0 &&
      durationToHours(transportWindow) !== 0 &&
      durationToHours(duration) === durationToHours(transportWindow) &&
      flows.length !== 0 &&
      !!name &&
      vehicleTypes.every((item) => item.range[0] > 0)
    );
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
