export const getMultiSelectDisplayValue = (
  enabledOptions: { name: string; id: string }[],
  selectedIds: string[],
) => {
  const value =
    selectedIds.length > 0
      ? enabledOptions
          ?.filter((item) => selectedIds.includes(item.id))
          .map((item) => item.name)
          .join(', ')
      : '';

  return value;
};
